import {
  CheckCircleOutline as SuccessIcon,
  HelpOutline as ConfirmIcon,
  ErrorOutline as ErrorIcon,
  InfoOutlined as InfoIcon,
  Warning as WarningIcon
} from '@mui/icons-material'
import MUIDialog, { DialogProps } from '@mui/material/Dialog'
import omit from 'lodash-es/omit'
import React, { useState, Component } from 'react'
import { createRoot } from 'react-dom/client'
import SvgClose from '#/assets/images/icons/close.svg'
import Button from '#/components/Button'
import cn from '#/utils/classnames'
import styles from './styles.module.scss'

// CONSIDER: remove when it is unnecessary completely
export class Dialog extends Component<DialogProps & { disableLog?: boolean }> {
  static modals: any[] = []

  componentDidMount() {
    if (this.props.open && !this.props.disableLog) {
      Dialog.modals = [this, ...Dialog.modals.filter(e => e !== this)]
    }
  }

  componentDidUpdate(prevProps: DialogProps) {
    if (this.props.open !== prevProps.open) {
      if (this.props.open && !this.props.disableLog) {
        Dialog.modals = [this, ...Dialog.modals.filter(e => e !== this)]
      } else {
        Dialog.modals = Dialog.modals.filter(e => e !== this)
      }
    }
  }

  componentWillUnmount() {
    if (!this.props.disableLog) {
      Dialog.modals = Dialog.modals.filter(e => e !== this)
    }
  }

  render() {
    const props = omit(this.props, ['disableLog'])
    return <MUIDialog {...props} />
  }
}

export interface ModalProps {
  visible: boolean
  scroll?: 'paper' | 'body'
  className?: string
  closeButton?: boolean
  closeButtonClassName?: string
  disableBackdropClick?: boolean
  disableEscapeKeyDown?: boolean
  BackdropComponent?: DialogProps['BackdropComponent']
  TransitionComponent?: DialogProps['TransitionComponent']
  children: React.ReactNode
  disableLog?: boolean
  onClose?: () => void
}

export default function Modal({
  visible,
  children,
  className,
  closeButton = true,
  closeButtonClassName,
  disableBackdropClick,
  onClose,
  ...props
}: ModalProps) {
  const classes = { paper: cn('!rounded-xl p-6 relative !m-2', className) }

  const handleClose: DialogProps['onClose'] = (ev, reason) => {
    if (reason !== 'backdropClick' || !disableBackdropClick) {
      if (onClose) onClose()
    }
  }

  return (
    <Dialog classes={classes} open={visible} onClose={handleClose} {...props}>
      <Button
        className={cn(
          'absolute text-gray-500 top-5 right-5 rounded-full z-10',
          { invisible: !closeButton },
          closeButtonClassName
        )}
        onClick={onClose}
      >
        <SvgClose />
      </Button>
      {children}
    </Dialog>
  )
}

export interface AlertDialogProps {
  type?: 'alert' | 'success' | 'info' | 'error' | 'warning' | 'confirm'
  title?: React.ReactNode
  content?: React.ReactNode
  actions?: React.ReactNode
  reverseActionsOrder?: boolean
  disableBackdropClick?: boolean
  BackdropComponent?: DialogProps['BackdropComponent']
  icon?: boolean
  okText?: string
  cancelText?: string
  onOk?(): void
  onCancel?(): void
}

function AlertDialog({
  type = 'alert',
  icon = true,
  title,
  content,
  actions,
  disableBackdropClick = true,
  reverseActionsOrder,
  okText = 'OK',
  cancelText = 'Cancel',
  onOk,
  onCancel,
  ...props
}: AlertDialogProps) {
  const [open, setOpen] = useState(true)

  function handleOk() {
    setOpen(false)
    if (onOk) onOk()
  }

  function handleCancel() {
    setOpen(false)
    if (onCancel) onCancel()
  }

  return (
    <Modal
      className={styles['th-alert-modal']}
      closeButton={false}
      disableBackdropClick={disableBackdropClick}
      visible={open}
      onClose={handleCancel}
      {...props}
    >
      <div>
        <div className={styles['th-modal-body']}>
          {icon && type === 'confirm' && (
            <ConfirmIcon className={cn(styles['th-icon'], styles['th-confirm-icon'])} />
          )}
          {icon && type === 'success' && (
            <SuccessIcon className={cn(styles['th-icon'], styles['th-success-icon'])} />
          )}
          {icon && type === 'warning' && (
            <WarningIcon className={cn(styles['th-icon'], styles['th-warning-icon'])} />
          )}
          {icon && type === 'info' && (
            <InfoIcon className={cn(styles['th-icon'], styles['th-info-icon'])} />
          )}
          {icon && type === 'error' && (
            <ErrorIcon className={cn(styles['th-icon'], styles['th-error-icon'])} />
          )}
          <div>
            {title && <div className={styles['th-modal-title']}>{title}</div>}
            <div className={styles['th-modal-content']}>{content}</div>
          </div>
        </div>
        <div
          className={cn(styles['th-modal-actions'], { [styles['th-reverse-order']]: reverseActionsOrder })}
        >
          {actions}
          {type === 'confirm' && (
            <Button
              className={cn(styles['th-action-button'], styles['th-cancel-action'])}
              onClick={handleCancel}
            >
              {cancelText}
            </Button>
          )}
          <Button className={cn(styles['th-action-button'], styles['th-ok-action'])} onClick={handleOk}>
            {okText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export function showAlert({ onCancel, onOk, ...props }: AlertDialogProps) {
  const rootElement = document.getElementById('__next') as HTMLElement
  const el = document.createElement('div')
  rootElement?.appendChild(el)

  const root = createRoot(el)

  function handleOk() {
    root.unmount()
    el.parentNode?.removeChild(el)
    if (onOk) onOk()
  }

  function handleCancel() {
    root.unmount()
    el.parentNode?.removeChild(el)
    if (onCancel) onCancel()
  }

  root.render(<AlertDialog onCancel={handleCancel} onOk={handleOk} {...props} />)
}

export function confirm(options: AlertDialogProps) {
  showAlert({
    type: 'confirm',
    ...options
  })
}

export function success(options: AlertDialogProps) {
  showAlert({
    type: 'success',
    ...options
  })
}

export function warning(options: AlertDialogProps) {
  showAlert({
    type: 'warning',
    ...options
  })
}

export function info(options: AlertDialogProps) {
  showAlert({
    type: 'info',
    ...options
  })
}

export function error(options: AlertDialogProps) {
  showAlert({
    type: 'error',
    ...options
  })
}
