import { connect } from 'react-redux'
import { ShowFeedback, UpdateStates as UpdateUI, UpdateViewport } from '#/store/actions/ui'
import { GetCustomer, UpdateStates, RouteChanged, GetGeoInfo } from '#/store/actions/user'
import Main from './Main'

const mapStateToProps = (state: TopHap.StoreState) => ({
  ctaPopup: state.ui.ctaPopup,
  feedback: state.ui.feedback,
  userInput: state.ui.userInput,
  share: state.ui.share,
  paymentInfo: state.user.paymentInfo,
  user: state.user.info,
  viewport: state.ui.viewport
})

const mapDispatchToProps = {
  showFeedback: ShowFeedback,
  showCTAPopup: (visible: boolean) =>
    UpdateUI({ ctaPopup: { visible } }),
  showUserInput: (visible: boolean) =>
    UpdateUI({ userInput: visible }),
  showShare: (visible: boolean) => UpdateUI({ share: visible }),
  updateViewport: UpdateViewport,
  getCustomer: GetCustomer,
  updateUser: UpdateStates,
  routeChanged: RouteChanged,
  getGeoInfo: GetGeoInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
