import { createReducer } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import * as Actions from '#/store/actions/user'
import { success } from '#/utils/action'

export const initialState: TopHap.UserState = {
  uid: undefined,
  providerId: '',
  info: { isAnonymous: undefined },
  geoInfo: {},
  paymentInfo: undefined,
  history: [],
  recent: {
    searches: [],
    views: []
  },
  customer: undefined,
  invoices: {
    items: [],
    hasMore: true
  }
}

export function getInitState(initToken?: string) {
  return {
    ...initialState,
    initToken
  }
}

export const commonSuccess = (state: TopHap.UserState, action: AnyAction) => ({
  ...state,
  ...action.payload
})

export const signInSuccess = (state: TopHap.UserState, action: AnyAction) => {
  const { isFirstSignIn, user } = action.payload

  state.uid = user.uid
  state.info = user
  state.initToken = undefined

  if (isFirstSignIn) {
    state.paymentInfo = { role: 'free' }
  }
}

export const signUpSuccess = (state: TopHap.UserState, action: AnyAction) => {
  const { user } = action.payload
  return {
    ...state,
    uid: user.uid,
    info: user,
    initToken: undefined,
    // FIXME: extract providerId from user
    // use const value 'password' to reduce bundle size by excluding firebase package
    // providerId: EmailAuthProvider.PROVIDER_ID,
    providerId: 'password',
    paymentInfo: { role: 'free' }
  } as TopHap.UserState
}

export const signOutSuccess = () => initialState

export const updateProfileSuccess = (
  state: TopHap.UserState,
  action: AnyAction
) => {
  state.info = {
    ...state.info,
    ...action.payload
  }
}

export const addRecentSearchSuccess = (
  state: TopHap.UserState,
  action: AnyAction
) => {
  state.recent = {
    ...state.recent,
    ...action.payload
  }
}

export const deleteSourceSuccess = (state: TopHap.UserState) => {
  if (state.customer) {
    state.customer.sources = null
  }
}

export const getUpcomingInvoiceSuccess = (
  state: TopHap.UserState,
  action: AnyAction
) => {
  state.invoices.upcoming = action.payload
}

export const getInvoicesSuccess = (
  state: TopHap.UserState,
  action: AnyAction
) => {
  state.invoices.items = [...state.invoices.items, ...(action.payload.items || [])]
  state.invoices.hasMore = action.payload.has_more
}

export const routeChanged = (
  state: TopHap.UserState,
  action: ReturnType<typeof Actions.RouteChanged>
) => {
  const baseUrl =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '')

  state.history = [
    new Date().toLocaleString() + ' - ' + baseUrl + action.payload,
    ...state.history
  ].slice(0, 15)
}

export default createReducer(initialState, {
  [Actions.UpdateStates.type]: commonSuccess,
  [Actions.RouteChanged.type]: routeChanged,
  [success(Actions.SignIn)]: signInSuccess,
  [success(Actions.SignInWithGoogle)]: signInSuccess,
  [success(Actions.SignInWithFacebook)]: signInSuccess,
  [success(Actions.SignInWithCustomToken)]: signInSuccess,
  [success(Actions.SignUp)]: signUpSuccess,
  [success(Actions.UpdateProfile)]: updateProfileSuccess,
  [success(Actions.GetCustomer)]: commonSuccess,
  [success(Actions.CreateCustomer)]: commonSuccess,
  [success(Actions.UpdateCustomer)]: commonSuccess,
  [success(Actions.DeleteSource)]: deleteSourceSuccess,
  [success(Actions.GetInvoices)]: getInvoicesSuccess,
  [success(Actions.GetUpcomingInvoice)]: getUpcomingInvoiceSuccess,
  [success(Actions.CreateSubscription)]: commonSuccess,
  [success(Actions.UpdateSubscription)]: commonSuccess,
  [success(Actions.DeleteSubscription)]: commonSuccess,
  [success(Actions.AddPromotion)]: commonSuccess,
  [success(Actions.SignOut)]: signOutSuccess,
  [success(Actions.GetGeoInfo)]: commonSuccess,
  [success(Actions.GetRecentSearches)]: commonSuccess,
  [success(Actions.AddRecentSearch)]: addRecentSearchSuccess,
  [success(Actions.ClearRecentSearches)]: commonSuccess,
  [success(Actions.AddRecentView)]: addRecentSearchSuccess,
  [success(Actions.GetShareLink)]: commonSuccess,
  [success(Actions.GetUserStats)]: commonSuccess
})
