import { capitalize } from 'lodash-es'
import get from 'lodash-es/get'

export type Plan = {
  id: string
  color: string
  monthlyPrice: number
  name: string
  recommend?: boolean
  period: 'monthly' | 'annual'
  role: TopHap.UserRole
}

const freePlan = {
  color: '#FB275D',
  monthlyPrice: 0,
  name: 'Free',
  role: 'free'
}

const basicPlan = {
  color: '#6651f5',
  name: 'Basic',
  role: 'basic'
}

const proPlan = {
  color: '#40c870',
  name: 'Pro',
  recommend: true,
  role: 'pro'
}

const expertPlan = {
  color: '#3585f7',
  name: 'Expert',
  role: 'expert'
}

const enterprisePlan = {
  color: '#2a2d5a',
  name: 'Enterprise',
  role: 'enterprise'
}

const plans = {
  monthly: {
    free: {
      ...freePlan,
      id: 'free_monthly',
      period: 'monthly'
    },
    basic: {
      ...basicPlan,
      id: process.env.NEXT_PUBLIC_PLAN_MONTH_BASIC_ID,
      monthlyPrice: 15,
      period: 'monthly'
    },
    pro: {
      ...proPlan,
      id: process.env.NEXT_PUBLIC_PLAN_MONTH_PRO_ID,
      monthlyPrice: 55,
      period: 'monthly'
    },
    expert: {
      ...expertPlan,
      id: process.env.NEXT_PUBLIC_PLAN_MONTH_EXPERT_ID,
      monthlyPrice: 155,
      period: 'monthly'
    },
    enterprise: {
      ...enterprisePlan,
      id: 'enterprise_monthly',
      period: 'monthly'
    }
  },
  annual: {
    free: {
      ...freePlan,
      id: '',
      period: 'annual'
    },
    basic: {
      ...basicPlan,
      id: process.env.NEXT_PUBLIC_PLAN_ANNUAL_BASIC_ID,
      monthlyPrice: 11,
      period: 'annual'
    },
    pro: {
      ...proPlan,
      id: process.env.NEXT_PUBLIC_PLAN_ANNUAL_PRO_ID,
      monthlyPrice: 45,
      period: 'annual'
    },
    expert: {
      ...expertPlan,
      id: process.env.NEXT_PUBLIC_PLAN_ANNUAL_EXPERT_ID,
      monthlyPrice: 126,
      period: 'annual'
    },
    enterprise: {
      ...enterprisePlan,
      id: 'enterprise_annual',
      period: 'annual'
    }
  }
} as {
  [key in 'monthly' | 'annual']: {
    [key in TopHap.UserRole]: Plan
  }
}

export const features = [
  {
    id: 'search',
    title: 'Search',
    items: [
      {
        id: 'PROPERTIES',
        title: 'Properties and Listings',
        requireRole: 'free',
        tooltip:
          'Search for properties by address, neighborhood, school system -- including MLS listings, off-market properties, and rentals)'
      },
      {
        id: 'NEIGHBORHOODS',
        title: 'Neighborhoods',
        requireRole: 'free',
        tooltip: 'Search for properties by neighborhood names'
      },
      {
        id: 'SCHOOLS',
        title: 'Schools',
        requireRole: 'free',
        tooltip: 'Search for properties by school attendance zones'
      }
    ]
  },
  {
    id: 'market_analytics',
    title: 'Market Analytics',
    items: [
      {
        id: 'TopHap_Estimate_Map',
        title: 'TopHap Estimate Map',
        requireRole: 'basic',
        tooltip:
          "View a heatmap of property value using TopHap's Proprietary valuation engine"
      },
      {
        id: 'Property_Value_Analytics',
        title: 'Property Value Analytics',
        requireRole: 'pro',
        tooltip:
          'View a heatmap of property prices and price per square foot, TopHap Estimate value, rental value estimate and more. Find sub neighborhoods, evaluate locations, and discover hidden value.'
      },
      {
        id: 'Market_Conditions',
        title: 'Market Conditions ',
        requireRole: 'pro',
        tooltip:
          'View heatmaps of local market conditions (DOM, List vs Sold, Turnover and more) down to the property level'
      },
      {
        id: 'Property_Attributes',
        title: 'Property Attributes',
        requireRole: 'pro',
        tooltip:
          'View maps by area, beds, bath, size, age, property use, HOA Fees and much more'
      },
      {
        id: 'Regional_Charactistics',
        title: 'Regional Charactistics',
        requireRole: 'pro',
        tooltip:
          'Visualize and analyze regional attributes such as walkability, noise, population trends, density, elevation, commute times, crime, and land use'
      },
      {
        id: 'Community_Insights ',
        title: 'Community Insights ',
        requireRole: 'pro',
        tooltip:
          'Understand local demographics at the regional, local, and property level'
      },
      {
        id: 'Hazards',
        title: 'Hazards',
        requireRole: 'pro',
        tooltip:
          'Evaluate environmental hazards that impact value and livability'
      },
      {
        id: 'School_Insights',
        title: 'School Insights',
        requireRole: 'pro',
        tooltip:
          'Map and evaluate locations by school characteristics such as zones, attendance, student:teacher ratio, graduation rates, ratings, test scores and more'
      },
      {
        id: 'Investment_Analytics',
        title: 'Investment Analytics',
        requireRole: 'expert',
        tooltip:
          'Advanced investment analytics enable experts to evaluate profitability, taxes, value growth history, rental yields, and more'
      },
      {
        id: 'Building_Permit_Analytics',
        title: 'Building Permit Analytics',
        requireRole: 'expert',
        tooltip:
          'Advanced building permit analytics enable experts to evaluate permit values, counts, and more'
      }
    ]
  },
  {
    id: 'property_analytics',
    title: 'Property Analytics',
    items: [
      {
        id: 'Property_Details',
        title: 'Property Details',
        requireRole: 'free',
        tooltip: ''
      },
      {
        id: 'TopHap_Estimate',
        title: 'TopHap Estimate (AVM)',
        requireRole: 'free',
        tooltip:
          "TopHap's propriatary Automated Valuation Model (AVM) responsible for calculating accurate values for current, historical and future sale and rent estimates"
      },
      {
        id: 'Value_History',
        title: 'Value History',
        requireRole: 'pro',
        tooltip:
          "A continuous estimate of property value using TopHap's propriatary Automated Valuation Model (AVM)"
      },
      {
        id: 'ARV_Calculator',
        title: 'ARV Calculator',
        requireRole: 'pro',
        tooltip:
          'After Renovation Value (ARV) - Forecasted property value based on modifications and improvements to property conditions'
      },
      {
        id: 'TRANSACTION_HISTORY',
        title: 'Transaction History',
        requireRole: 'pro',
        tooltip: ''
      },
      {
        id: 'TAX_HISTORY',
        title: 'Tax History',
        requireRole: 'pro',
        tooltip: ''
      },
      {
        id: 'PERMIT_HISTORY',
        title: 'Permit History',
        requireRole: 'pro',
        tooltip: ''
      },
      {
        id: 'LOT_TOPOGRAPHY_3D',
        title: '3D Lot Topography',
        requireRole: 'pro',
        tooltip:
          "TopHap's proprietary 3D lot topography visualization gives users insight into usable lot area and other characteristics such as views, earthquake risk, and improvement potential"
      },
      {
        id: 'LOT_USABILITY_ANALYSIS',
        title: 'Lot Usability Analysis',
        requireRole: 'pro',
        tooltip:
          'Examine lot usability and elevation without the need to visit the site'
      },
      {
        id: 'Value_Forecast',
        title: 'Value Forecast',
        requireRole: 'expert',
        tooltip:
          "TopHap's proprietary valuation engine powers future value forecasts based on over a quadrillion data inputs for every property "
      },
      {
        id: 'Owner_Information',
        title: 'Owner Information',
        requireRole: 'expert',
        tooltip: 'Owner Information'
      }
    ]
  },
  {
    id: 'branded_portal',
    title: 'Branded Portal',
    items: [
      {
        id: 'GUEST_INVITES',
        title: 'Guest Invites',
        requireRole: 'pro',
        tooltip:
          'Invite your clients to your branded portal and they will get access to all* your paid features for free'
      },
      {
        id: 'BRANDED_CARD',
        title: 'Branded Card',
        requireRole: 'pro',
        tooltip: "Display your contact information in your clients' portal"
      },
      {
        id: 'CLIENT_ANALYTICS',
        title: 'Client Analytics',
        requireRole: 'pro',
        tooltip:
          'See how engaged your clients are by measuring their basic engagement with views, searches, and referrals'
      },
      {
        id: 'CUSTOM_LOGO',
        title: 'Custom Logo',
        requireRole: 'expert',
        tooltip: 'Upload your company logo and white-label TopHap'
      }
    ]
  },
  {
    id: 'tophap_cma_plus',
    title: 'TopHap CMA+',
    items: [
      {
        id: 'COMPARE_PROPERTIES',
        title: 'Compare Properties',
        requireRole: 'pro',
        tooltip:
          'Compare any property including estimated valuation history and property characteristics'
      },
      {
        id: 'COMPARE_REGIONS',
        title: 'Compare Regions',
        requireRole: 'pro',
        tooltip:
          'Compare any region including estimated valuation history and region characteristics'
      },
      {
        id: 'COMPARE_VALUE_HISTORY',
        title: 'Compare Property Value History',
        requireRole: 'expert',
        tooltip:
          'Compare historical (2000-present) Estimate, YoY Chnage, Gross Rental Yield, etc...'
      },
      {
        id: 'COMPARE_VALUE_FORECAST',
        title: 'Compare Property Value Forecasts',
        requireRole: 'expert',
        tooltip:
          "Forecasted value for properties, neighborhoods, and regions based on TopHap's proprietary machine learning valuation engine"
      },
      {
        id: 'COMPARE_MARKET_CONDITIONS',
        title: 'Compare Market Conditions',
        requireRole: 'expert',
        tooltip:
          'Evaluate market conditions and trends across properties and regions to understand how market conditions impact value differently in different locations'
      }
    ]
  },
  {
    id: 'reports',
    title: 'Reports & Tools',
    items: [
      {
        id: 'PROPERTY',
        title: 'Property Reports',
        requireRole: 'pro',
        tooltip: 'Export a one page PDF report of any* property'
      },
      {
        id: 'CMA',
        title: 'CMA Reports',
        requireRole: 'pro',
        tooltip:
          'Generate a PDF CMA report with any* number of comparable properties'
      },
      {
        id: 'EXPORT_CSV',
        title: 'Export CSV',
        requireRole: 'expert',
        tooltip: 'Export Search results as CSV'
      }
    ]
  }
]

export const roleOrders: Record<TopHap.UserRole, number> = {
  free: 0,
  basic: 1,
  pro: 2,
  expert: 3,
  enterprise: 4
}

export const hasRole = (
  currentRole: TopHap.UserRole | undefined,
  basicRole: TopHap.UserRole | undefined
) => (
  (currentRole ? roleOrders[currentRole] : -1) >=
    (basicRole ? roleOrders[basicRole] : -1)
)

export function id2Plan(id: string): Plan | undefined {
  const mapping = {
    [plans.monthly.basic.id]: 'monthly.basic',
    [plans.monthly.pro.id]: 'monthly.pro',
    [plans.monthly.expert.id]: 'monthly.expert',
    [plans.annual.basic.id]: 'annual.basic',
    [plans.annual.pro.id]: 'annual.pro',
    [plans.annual.expert.id]: 'annual.expert'
  }

  const field = get(mapping, id)
  return field ? get(plans, field) : undefined
}

export function role2Name(role: TopHap.UserRole) {
  return capitalize(role)
}

export function comparePlan(a: Plan, b: Plan) {
  if (roleOrders[a.role] === roleOrders[b.role]) {
    if (a.period === b.period) return 0
    else return a.period === 'annual' ? 1 : -1
  }
  return roleOrders[a.role] - roleOrders[b.role]
}

export default plans
