export const stage = process.env.NEXT_PUBLIC_STAGE as 'DEVELOPMENT' | 'STAGING' | 'PRODUCTION'
export const host = process.env.NEXT_PUBLIC_HOST as string
export const isDev = process.env.NODE_ENV === 'development'

export const googleClientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string
export const googleAnalyticsId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID as string
export const googleOptimizeId = process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZED_ID as string
export const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID as string
export const gtmServerContainer = process.env.NEXT_PUBLIC_GTM_SERVER_CONATINER as string
export const liPartnerId = process.env.NEXT_PUBLIC_LI_PARTNER_ID as string
export const liConversationId = process.env.NEXT_PUBLIC_LI_CONVERSATION_ID as string
export const facebookAppId = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID as string
export const facebookPixelId = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID as string
export const googleMapKey = process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY as string
export const intercomId = process.env.NEXT_PUBLIC_INTERCOM_ID as string
export const stripeKey = process.env.NEXT_PUBLIC_STRIPE_KEY as string
export const stapeGTMId = process.env.NEXT_PUBLIC_STAPE_GTM_ID as string

export const trialDays = 7
export const mapTourId = 248104

export const postgrestAuth = process.env.NEXT_PRIVATE_POSTGREST_AUTH as string
