import rgba from 'color-rgba'
import { useState } from 'react'
import cn from '#/utils/classnames'

type Props = {
  className: string
}

const TouchRipple: React.FC<Props> = ({ className }) => {
  const [style, setStyle] = useState<React.CSSProperties | undefined>()

  const createRipple = (ev: React.MouseEvent<HTMLSpanElement>) => {
    setStyle(undefined)

    const container = ev.currentTarget
    const diameter = Math.max(container.clientWidth, container.clientHeight)
    const radius = diameter / 2

    const style: React.CSSProperties = {}

    if (typeof window !== 'undefined') {
      const [r, g, b, a] = rgba(
        window.getComputedStyle(container).color
      ) as number[]
      style.backgroundColor = `rgba(${r}, ${g}, ${b}, ${a * 0.3})`
    }
    style.width = style.height = `${diameter}px`
    style.left = `${ev.nativeEvent.offsetX - radius}px`
    style.top = `${ev.nativeEvent.offsetY - radius}px`

    setTimeout(() => {
      setStyle(style)
    })
  }

  return (
    <span
      className={cn('overflow-hidden', className)}
      onMouseDown={createRipple}
    >
      <span className={style ? 'ripple' : ''} style={style} />
    </span>
  )
}

export default TouchRipple
