import debounce from 'lodash-es/debounce'
import omit from 'lodash-es/omit'
import dynamic from 'next/dynamic'
import Router from 'next/router'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import AppHeader from '#/components/AppHeader'
import Footer from '#/components/Footer'
import Header from '#/components/Header'
import IntercomProvider from '#/components/IntercomProvider'
import { Dialog } from '#/components/Modal'
import SideMenu from '#/components/SideMenu'
import { BREAK_SM } from '#/consts'
import {
  APP_DETAIL_PAGE,
  APP_FAVORITES_PAGE,
  MAP_PAGE,
  COMPARE_PAGE,
  PRICING_PAGE,
  SSO_PAGE,
  PDF_PAGES,
  CMA_PAGE,
  WORKSPACE_PAGES,
  ZMA_PAGE
} from '#/consts/url'
import Authentication from '#/containers/Authentication'
import {
  logEvent,
  logEventToES,
  trackPageView
} from '#/services/analytics'
import cn from '#/utils/classnames'
import intercomApi from '#/utils/intercomApi'
import { hashString2Hash } from '#/utils/url'
import type { MainAppProps, MainAppState } from './types'
import 'react-toastify/dist/ReactToastify.min.css'

const appPages = [MAP_PAGE, COMPARE_PAGE, WORKSPACE_PAGES, CMA_PAGE, ZMA_PAGE]
const noHeaderPages = [APP_DETAIL_PAGE, APP_FAVORITES_PAGE, ZMA_PAGE]
const noFooterPages = [
  MAP_PAGE,
  COMPARE_PAGE,
  APP_DETAIL_PAGE,
  CMA_PAGE,
  SSO_PAGE,
  APP_FAVORITES_PAGE,
  ZMA_PAGE
]

const CTAPopup = dynamic(() => import('#/components/CTAPopup'))
const UserInput = dynamic(() => import('#/components/UserInput'))
const Feedback = dynamic(() => import('#/containers/Feedback'))
const Share = dynamic(() => import('#/containers/Share'))
const CheckoutProcess = dynamic(import('#/components/CheckoutProcess'), { ssr: false })

export default class Main extends React.Component<MainAppProps, MainAppState> {
  private currentPathname?: string
  private mapReadyChecked?: boolean

  constructor(props: MainAppProps) {
    super(props)

    this.state = {
      checkoutProcess: false,
      selectPlan: plan =>
        console.error('Select Plan handler is not registerd', plan)
    }
  }

  componentDidMount() {
    if (!this.props.router.pathname.startsWith(PDF_PAGES)) {
      this.getGeoInfo()

      this.onResize()
      document.addEventListener('scroll', this.onScroll)
      window.addEventListener('resize', this.onResize)

      this.onRouteChange(this.props.router.asPath)
      Router.events.on('routeChangeComplete', this.onRouteChange)
      Router.events.on('hashChangeComplete', this.onRouteChange)
    }
  }

  componentDidUpdate(prevProps: MainAppProps) {
    const { paymentInfo, user, ctaPopup, router } = this.props
    if (!this.props.router.pathname.startsWith(PDF_PAGES)) {
      if (user !== prevProps.user) {
        if (window.dataLayer) {
          window.dataLayer.push({
            user_properties: {
              id: user.uid,
              job_role: user.job,
              invite_pending: user.invitations ?
                Object.keys(user.invitations).length :
                0,
              invite_accepted: user.guests ? Object.keys(user.guests).length : 0,
              email: user.email,
              displayName: user.displayName,
              phoneNumber: user.phoneNumber
            }
          })
        }

        if (window.profitwell) window.profitwell('start', { user_email: user.email })
      }

      if (paymentInfo !== prevProps.paymentInfo) {
        if (window.dataLayer) {
          window.dataLayer.push({ user_properties: { owner_id: paymentInfo?.ownerId } })
        }

        if (
          user &&
          user.isAnonymous === false &&
          (!user.job || (user.job === 'Other' && !user.jobDescription))
        ) {
          this.props.showUserInput(true)
        } else {
          this.props.showUserInput(false)
        }

        if (paymentInfo) {
          if (router.query.show_share) {
            this.props.showShare(true)

            router.replace({ query: omit(router.query, 'show_share') })
          }
        }
      }

      if (ctaPopup && ctaPopup !== prevProps.ctaPopup) {
        this.setState({ checkoutProcess: true })
      }
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Error', error, errorInfo)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)

    Router.events.off('routeChangeComplete', this.onRouteChange)
    Router.events.off('hashChangeComplete', this.onRouteChange)
  }

  getGeoInfo = () => {
    const { router } = this.props
    if (router.pathname === MAP_PAGE) {
      const hashString = router.query.params as string[]
      if (hashString && hashString[hashString.length - 1]) {
        const hash = hashString2Hash(hashString[hashString.length - 1])
        if (hash.m) return
      }
    }

    this.props.getGeoInfo()
  }

  checkVisit = () => {
    const { router } = this.props
    if (router.pathname !== this.currentPathname) {
      this.currentPathname = router.pathname
      trackPageView(router.pathname, router.asPath)

      this.mapReadyChecked = false
    }

    if (!this.mapReadyChecked && router.pathname === MAP_PAGE) {
      if (
        !Dialog.modals.length &&
        this.props.user.isAnonymous !== undefined &&
        !router.query.auth
      ) {
        intercomApi('trackEvent', 'map_ready')
        this.mapReadyChecked = true
      }
    }
  }

  onResize = () => {
    this.props.updateViewport({
      width: window.innerWidth,
      height: window.innerHeight
    })

    document.documentElement.style.setProperty(
      '--vh',
      `${window.innerHeight / 100}px`
    )
  }

  onRouteChange = (url: string) => {
    this.props.routeChanged(url)
    logEventToES('location_change', { url })

    this.checkVisit()
  }

  onScroll = () => {
    this.onScrollEnd()
  }

  onScrollEnd = debounce(() => {
    if (this.props.router.pathname === '/') {
      const percent =
        window.pageYOffset / (document.body.scrollHeight - window.innerHeight)
      if (percent > 0.99) {
        logEvent('select_content', {
          content_type: 'landing',
          item_id: 'scroll/bottom'
        })
      } else if (percent > 0.5) {
        logEvent('select_content', {
          content_type: 'landing',
          item_id: 'scroll/middle'
        })
      } else if (percent > 0.05) {
        logEvent('select_content', {
          content_type: 'landing',
          item_id: 'scroll/top'
        })
      }
    } else if (this.props.router.pathname === '/car') {
      const percent =
        window.pageYOffset / (document.body.scrollHeight - window.innerHeight)
      if (percent > 0.99) {
        logEvent('select_content', {
          content_type: 'car_landing',
          item_id: 'scroll/bottom'
        })
      } else if (percent > 0.5) {
        logEvent('select_content', {
          content_type: 'car_landing',
          item_id: 'scroll/middle'
        })
      } else if (percent > 0.05) {
        logEvent('select_content', {
          content_type: 'car_landing',
          item_id: 'scroll/top'
        })
      }
    }
  }, 300)

  hideCTAPopup = () => {
    this.props.showCTAPopup(false)
  }

  registerSelectHandler = (handler: MainAppState['selectPlan']) => {
    this.setState({ selectPlan: handler })
  }

  render() {
    const {
      Component,
      pageProps,
      router,
      ctaPopup,
      feedback,
      paymentInfo,
      share,
      userInput,
      viewport,
      showFeedback,
      showShare,
      showUserInput
    } = this.props
    const { checkoutProcess, selectPlan } = this.state

    const toastContainer = (
      <ToastContainer
        autoClose={5000}
        className='w-full p-1 2xs:w-[375px]'
        icon={false}
        position='top-right'
      />
    )

    const isAppPage = appPages.find(e => router.pathname.startsWith(e))
    if (router.pathname.startsWith(PDF_PAGES) || router.pathname.startsWith(ZMA_PAGE)) {
      return (
        <div className='th-app'>
          <div className='th-app-content'>
            <Component {...pageProps} selectPlan={selectPlan} />
            {toastContainer}
          </div>
        </div>
      )
    }

    return (
      <IntercomProvider>
        <div className='th-app'>
          {isAppPage ? (
            <>
              {viewport.width <= BREAK_SM && <AppHeader />}
              <SideMenu />
            </>
          ) : (
            !noHeaderPages.includes(router.pathname) && (
              <Header transparentable={router.route === '/'} />
            )
          )}

          <div
            className={cn(
              'th-app-content',
              { 'th-app-mode': isAppPage }
            )}
          >
            <Component {...pageProps} selectPlan={selectPlan} />
            {!noFooterPages.includes(router.pathname) && <Footer showFeedback={showFeedback} />}
          </div>

          <Authentication />
          {/* <IntercomClient /> */}
          {feedback.visible && (
            <Feedback
              product={feedback.product}
              visible={feedback.visible}
              onCancel={() => showFeedback(false)}
            />
          )}
          {ctaPopup.visible && (
            <CTAPopup
              currentRole={paymentInfo ? paymentInfo.role : undefined}
              requireRole={ctaPopup.requireRole}
              selectPlan={plan => selectPlan(plan, true)}
              visible={ctaPopup.visible}
              onClose={this.hideCTAPopup}
            />
          )}
          {userInput && (
            <UserInput
              visible={userInput}
              onCancel={() => showUserInput(false)}
            />
          )}
          {share && <Share visible={share} onClose={() => showShare(false)} />}
          {(checkoutProcess || router.pathname === PRICING_PAGE) && (
            <CheckoutProcess
              registerSelectHandler={this.registerSelectHandler}
              router={router}
            />
          )}

          {toastContainer}
        </div>
      </IntercomProvider>
    )
  }
}
