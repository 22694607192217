import { createReducer } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import * as Actions from '#/store/actions/cma'
import { success } from '#/utils/action'
import { setIn } from '#/utils/object'

export const initialState: TopHap.CMAState = {
  preferences: {
    data: [],
    excludes: {},
    subjectUpdates: {}
  },
  comparables: []
}

export const commonSuccess = (
  state: TopHap.CMAState,
  action: AnyAction
) => ({
  ...state,
  ...action.payload
})

export const setState = (state: TopHap.CMAState, action: ReturnType<typeof Actions.SetState>) => {
  const { option, value, update } = action.payload
  if (option) {
    return setIn(state, option, value, update)
  } else {
    return value
  }
}

export const addComparablesSuccess = (
  state: TopHap.CMAState,
  action: AnyAction
) => {
  const comparables: TopHap.Comparable[] = [...state.comparables, ...action.payload]
  state.comparables = state.preferences.data.map(e =>
    comparables.find(comparable => comparable.place.id === e.id) as TopHap.Comparable)
}

export const removeComparable = (
  state: TopHap.CMAState,
  action: ReturnType<typeof Actions.RemoveComparable>
) => {
  state.preferences.data = state.preferences.data.filter(e => e.id !== action.payload.place.id)
  state.comparables = state.comparables.filter(
    e => e.place.id !== action.payload.place.id
  )
}

export const addSubjectPropertySuccess = (
  state: TopHap.CMAState,
  action: AnyAction
) => {
  const { comparables, keep } = action.payload

  state.comparables = comparables
  const data = comparables.map((e: TopHap.BaseComparable) => ({ id: e.place.id, fromQuery: true }))
  if (keep) {
    state.preferences.data.splice(0, 1, ...data)
  } else {
    state.preferences.data = data
  }
}

export const updateSubjectPropertySuccess = (
  state: TopHap.CMAState,
  action: AnyAction
) => {
  const { comparables, updates } = action.payload
  state.preferences.subjectUpdates = updates

  // update comparables, preferences.data:
  //  - keep manual comparables and update query comparables only
  const queryComparables = [state.comparables[0], ...comparables]
  const manualComparables = state.comparables
    .filter((e, index) => !state.preferences.data[index].fromQuery)
  state.comparables = [...queryComparables, ...manualComparables]
  state.preferences.data = [
    ...queryComparables.map(e => ({ id: e.place.id, fromQuery: true })),
    ...manualComparables.map(e => ({ id: e.place.id }))
  ]
}

export default createReducer(initialState, {
  [Actions.SetState.type]: setState,
  [success(Actions.AddComparables)]: addComparablesSuccess,
  [success(Actions.AddSubjectProperty)]: addSubjectPropertySuccess,
  [success(Actions.UpdateSubjectProperty)]: updateSubjectPropertySuccess,
  [Actions.RemoveComparable.type]: removeComparable
})
