import { createAction } from '@reduxjs/toolkit'

export const AddDrawings = createAction<GeoJSON.Feature<GeoJSON.Polygon>[]>('preferences/add_drawings')
export const UpdateDrawing = createAction<GeoJSON.Feature<GeoJSON.Polygon>>('preferences/update_drawing')
export const RemoveDrawing = createAction<GeoJSON.Feature<GeoJSON.Polygon>>('preferences/remove_drawing')
export const SetFilterOption = createAction<{
  option: string
  value: any
  update?: boolean
}>('preferences/set_filter_option')
export const SetMapOption = createAction<{
  option: string
  value: any
  update?: boolean
}>('preferences/set_map_option')
export const SetOption = createAction<{
  option: string
  value: any
  update?: boolean
}>('preferences/set_option')
export const UpdatePlaces = createAction<TopHap.PreferencesState['places']>('preferences/update_places')
export const UpdateStates = createAction<Partial<TopHap.PreferencesState>>('preferences/update_states')
export const AddFilterGroup = createAction<TopHap.FilterGroup>('preferences/add_filter_group')
export const ClearFilterGroups = createAction('preferences/clear_filter_groups')
export const UpdateFilterGroup = createAction<{
  groupIndex: number,
  and: boolean
}>('preferences/update_filter_group')
export const AddFilterOption = createAction<{
  groupIndex?: number
  filter: TopHap.FilterOption
  asNewGroup?: boolean
}>('preferences/add_filter_option')
export const UpdateFilterOption = createAction<{
  groupIndex: number
  index: number
  operator?: string
  value?: Partial<TopHap.FilterOption['value']>
  fields?: string
}>('preferences/update_filter_option')
export const RemoveFilterOption = createAction<{
  groupIndex: number
  index: number
}>('preferences/remove_filter_option')
export const SetFilterMode = createAction<TopHap.FilterMode>('preferences/set_filter_mode')
