import dayjs from 'dayjs'
import get from 'lodash-es/get'
import { isAgent as _isAgent } from '#/consts/partners'
import { roleOrders } from '#/consts/plan'

export const isTrial = (state: TopHap.StoreState) => {
  const trialEnd = state.user.paymentInfo?.trial_end_at
  if (!trialEnd) return false

  return trialEnd > dayjs().unix()
}

export const currentRole = (state: TopHap.StoreState, withAgentRole = true) => {
  const role = get(state.user, 'paymentInfo.role') as
    | TopHap.UserRole
    | undefined

  if ((!role || role === 'free') && withAgentRole) {
    const sRole = state.user.agentRole
    if (sRole && ['pro', 'expert'].includes(sRole)) {
      return sRole
    }
  }

  return role
}

export const currentRoleWithShared = (state: TopHap.StoreState, withAgentRole = true) => {
  const current = currentRole(state, withAgentRole)
  const shared = state.user.sharedRole

  if (current && shared) {
    return roleOrders[current] > roleOrders[shared] ? current : shared
  } else {
    return current || shared
  }
}

export const isAnalyticsEnabled = (state: TopHap.StoreState) =>
  // const role = currentRole(state)
  (
    // role !== undefined &&
    // role !== 'free' &&
    state.preferences.map.descriptive.enabled
  )

export const isAgent = (state: TopHap.StoreState) => {
  // wfgAgent is a guest of wfg and allowed to act as top level agent
  if (state.user.paymentInfo?.ownerId && state.user.permissions?.partner !== 'wfgAgent') return false
  return _isAgent(state.user.info.job, state.user.permissions?.partner)
}

export const agentInfo = (state: TopHap.StoreState) => {
  if (state.user.paymentInfo?.ownerId) {
    return state.user.agentInfo
  } else if (isAgent(state) && !state.user.info.portal?.hideOwnPortal) {
    return state.user.info
  }
}

export const hasAgentPortal = (state: TopHap.StoreState) => (
  !!state.user.paymentInfo?.ownerId ||
    (isAgent(state) && !state.user.info.portal?.hideOwnPortal)
)
