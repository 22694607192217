import { connect } from 'react-redux'
import { UpdateStates, UpdateSider } from '#/store/actions/ui'
import AppHeader from './AppHeader'

const mapStateToProps = (state: TopHap.StoreState) => ({
  isSideMenuExpanded: state.ui.isSideMenuExpanded,
  user: state.user.info
})

const mapDispatchToProps = {
  updateUI: UpdateStates,
  updateSider: UpdateSider
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader)
