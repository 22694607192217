const JOB_ROLES = [
  'Agent',
  'Broker',
  'Investor',
  'Appraiser',
  'Lender',
  'Builder',
  'Buyer',
  'Seller',
  'Other'
]
const AGENT_JOBS = ['Agent', 'Broker', 'Lender', 'Other']
const SHARE_MESSAGE = 'Please join me on TopHap and take your Real Estate research to the next level with data and analytics!'

const partnerDefault = {
  jobRoles: JOB_ROLES,
  shareMessage: SHARE_MESSAGE
}

type PartnerInfo = {
  company?: string
  jobRoles?: string[]
  shareMessage?: string
}

export const partners: Record<TopHap.Partner, PartnerInfo> = {
  wfg: {
    company: 'WFG National Title',
    jobRoles: [
      'Sales Representative',
      'Sales Manager',
      'Escrow Officer',
      'Other'
    ],
    shareMessage: 'Please join me on TopHap! In collaboration with WFG, TopHap brings you the best in class data and analytics to help you serve your clients better.'
  },
  wfgAgent: { ...partnerDefault },
  frb: {
    ...partnerDefault,
    company: 'First Republic Bank'
  },
  ggHomes: {
    ...partnerDefault,
    company: 'GG Homes'
  }
}

export const jobRoles = (partner?: TopHap.Partner): string[] => {
  if (!partner) return JOB_ROLES
  else return partners[partner].jobRoles || JOB_ROLES
}

export const isAgent = (jobRole?: string, partner?: TopHap.Partner) => {
  if (partner?.startsWith('wfg')) return true
  if (!jobRole) return false

  return AGENT_JOBS.includes(jobRole)
}

export const shareMessage = (partner?: TopHap.Partner) => {
  if (!partner) return SHARE_MESSAGE
  return partners[partner].shareMessage || SHARE_MESSAGE
}

// check if email is from WFG allowed domains (wfgtitle.com, myhome.com)
export const isWFGEmail = (email: string) => {
  const allowedDomains = ['wfgtitle.com', 'myhome.com']
  const domain = email.split('@')[1]
  return allowedDomains.includes(domain)
}
