import { takeLatest } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'
import { getGeoInfo } from '#/services/user'
import { GetGeoInfo } from '#/store/actions/user'
import { actionBuilder } from '#/utils/saga/actionBuilder'

function* getGeoInfoSaga(action: ReturnType<typeof GetGeoInfo>) {
  yield* actionBuilder(
    action,
    function* () {
      const res = yield* call(getGeoInfo)
      return { geoInfo: res }
    }
  )
}

export default function* initSaga() {
  yield takeLatest(GetGeoInfo, getGeoInfoSaga)
}
