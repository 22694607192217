import { twMerge } from 'tailwind-merge'

export const classnames = (
  ...args: (string | undefined | false | 0 | null | Record<string, any>)[]
) => {
  const classLists = (args.filter(e => !!e) as (string | Record<string, any>)[])
    .map(e => {
      if (typeof (e) === 'object') {
        return Object.keys(e).filter(field => e[field]).join(' ')
      } else {
        return e as string
      }
    })
  return twMerge(...classLists)
}

export default classnames
