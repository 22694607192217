export const BREAK_XS = 375
export const BREAK_SM = 540
export const BREAK_MD = 720
export const BREAK_LG = 960
export const BREAK_XL = 1440

export const SIDER_WIDTH = 425
export const SIDER_WIDE_WIDTH = 740
export const WIDE_VIEWPORT_WIDTH = SIDER_WIDE_WIDTH * 2

export const SQFT_PER_ACRE = 43560
export const SECONDS_IN_DAY = 24 * 60 * 60
export const MILISECONDS_IN_DAY = 1000 * SECONDS_IN_DAY
export const FEETS_PER_METER = 3.28084
export const DAYS_PER_YEAR = 365

export const MAP_SWITCHING_ZOOM_LEVEL = 14.8
export const PARCEL_MARKER_ZOOM_LEVEL = 15
export const REGION_ZOOM_LEVEL = 16
export const ADDRESS_ZOOM_LEVEL = 17.5

export const LIST_PAGE_SIZE = 30
// export const MAP_PAGE_SIZE = 499
export const MAP_PAGE_SIZE = 299
export const ZONE_PAGE_SIZE = 30

export const ZOOM_PRESETS = [10, 12, 15]

export const PERMIT_TYPES = [
  'Bathroom Remodel',
  'Commercial Renovation',
  'Decks And Porches',
  'Demolition',
  'Docks',
  'Doors And Windows',
  'Electrical Work',
  'Excavation And Grading',
  'Fences',
  'Flatwork Concrete',
  'Foundations',
  'Garage Construction',
  'Home Addition',
  'Hvac',
  'Kitchen Remodel',
  'Landscape',
  'Mechanical Work',
  'Mobile Homes',
  'Multi-room Remodel',
  'New Construction',
  'Patios',
  'Paving, Driveways, And Sidewalks',
  'Plumbing',
  'Pole Barn',
  'Pool And Spa Construction',
  'Retaining Walls',
  'Roofing',
  'Sewer Laterals',
  'Signage',
  'Solar Installation',
  'Wood Fences'
]

export const PERMIT_STATUS = ['Applied', 'Approved', 'Canceled', 'Complete', 'Expired', 'Filed', 'Final', 'Issued', 'Plancheck', 'Reinstated', 'Rejected', 'Revoked', 'Suspended', 'Triage', 'Withdrawn']

export const FEEDBACK_SUBJECTS = ['Question', 'Bug', 'Data Inaccuracy', 'Other']

export const DISCOVER_SOURCES = [
  'Search engine (Google, Yahoo, etc.)',
  'Recommended by friend or colleague',
  'Social media',
  'Blog or publication',
  'MLS',
  'Other'
]

export const TH_TOKEN = 'TH_TOKEN'
export const TH_USER_ID = 'TH_USER_ID'

export const GOOGLE_CLIENT_ID = 'ca-pub-5720980775511339'
