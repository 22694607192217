import qs from 'querystring'
import fetch from 'isomorphic-unfetch'
import { getToken, getUserId } from '#/services/token'

export interface ApiConfig {
  baseUrl?: string
  endpoint: string
  method?: string
  body?: Record<string, unknown>
  path?: any
  query?: any
  auth?: boolean
  token?: string
  withUserId?: boolean
  abortController?: AbortController
  vercelApi?: boolean
}

export default async function invokeApi<T>({
  baseUrl,
  endpoint,
  method = 'get',
  body,
  path,
  query,
  token,
  withUserId = false,
  auth = false,
  abortController,
  vercelApi = false
}: ApiConfig) {
  const authorization = token || (auth ? await getToken() : undefined)

  let url = baseUrl ? `${baseUrl}/${endpoint}` : endpoint
  if (path) {
    Object.keys(path).forEach(key => {
      url = url.replace(`{${key}}`, path[key])
    })
  }

  if (query) {
    url = url + '?' + qs.encode(query)
  }

  if (withUserId) {
    const userId = await getUserId()
    if (userId) {
      if (method.toLowerCase() === 'post') {
        body = {
          userId,
          ...body
        }
      } else if (method.toLowerCase() === 'get') {
        query = {
          userId,
          ...query
        }
      }
    }
  }

  const headers: HeadersInit = { 'Content-Type': 'application/json' }
  if (authorization) {
    headers.Authorization = authorization
  }

  if (vercelApi) {
    headers.tophap = 'reeN4ozie3Gut6RiDO74'
  }

  return fetch(url, {
    headers,
    method,
    body: body && JSON.stringify(body),
    signal: abortController?.signal
  })
    .then(response => {
      const contentType = response.headers.get('content-type')
      if (contentType && contentType.includes('application/json')) {
        return response.json().then(content => ({ content, response }))
      } else if (contentType && (contentType === 'application/pdf' || contentType === 'application/xml')) {
        return response.blob().then(content => ({ content, response }))
      } else {
        return response.text().then(content => ({ content, response }))
      }
    })
    .then(({ content, response }) => {
      if (!response.ok) return Promise.reject(content)
      else return content as T
    })
}
