import {
  CheckCircleOutline as SuccessIcon,
  Close as CloseIcon,
  ErrorOutline as ErrorIcon,
  InfoOutlined as InfoIcon,
  Warning as WarningIcon
} from '@mui/icons-material'
import React from 'react'
import { toast } from 'react-toastify'
import Button from '#/components/Button'
import cn from '#/utils/classnames'
import styles from './styles.module.scss'

interface NotificationProps {
  type: 'default' | 'success' | 'warning' | 'info' | 'error'
  title: React.ReactNode
  message: React.ReactNode
  btn?: React.ReactNode
}

export interface NotificationShowOption extends NotificationProps {
  key?: string
  delay?: number
  autoClose?: number | false
  onClose?: () => void
}

function Notification({ type, title, message, btn }: NotificationProps) {
  return (
    <div className={styles['th-notification']}>
      <div className={styles['th-notification-content']}>
        {type === 'success' && <SuccessIcon className={cn(styles['th-icon'], styles['th-success-icon'])} />}
        {type === 'warning' && (
          <WarningIcon className={cn(styles['th-icon'], styles['th-warning-icon'])} />
        )}
        {type === 'info' && <InfoIcon className={cn(styles['th-icon'], styles['th-info-icon'])} />}
        {type === 'error' && <ErrorIcon className={cn(styles['th-icon'], styles['th-error-icon'])} />}
        <div className='ml-3 mt-0.5 w-0 flex-1'>
          <div className={styles['th-notification-title']}>{title}</div>
          <div className={styles['th-notification-message']}>{message}</div>
        </div>
      </div>
      {btn && <div className={styles['th-notification-action']}>{btn}</div>}
    </div>
  )
}

const notification = {
  show({
    type,
    btn,
    key,
    title,
    message,
    delay,
    autoClose,
    onClose
  }: NotificationShowOption) {
    let t
    if (!type || type === 'default') {
      t = toast
    } else {
      // @ts-ignore
      t = toast[type]
    }

    t(<Notification btn={btn} message={message} title={title} type={type} />, {
      className: styles['th-notification-wrapper'],
      closeButton: (
        <Button className={cn(styles['th-button'], styles['th-close-button'])}>
          <CloseIcon />
        </Button>
      ),
      toastId: key,
      delay,
      autoClose,
      onClose
    })
  },
  close(key: string) {
    toast.dismiss(key)
  }
}

export default notification
