import capitalize from 'capitalize'

export const NUMBER_FILTER_SUB_METRICS = [
  { value: 'median', title: 'Median' },
  { value: 'mean', title: 'Mean' },
  { value: 'min', title: 'Min' },
  { value: 'max', title: 'Max' },
  { value: 'variance', title: 'Variance' },
  { value: 'stdev', title: 'Standard Deviation' }
]

export const MORTGAGE_POSITIONS = ['Any', 'First', 'Second', 'Third']
export const MORTGAGE_LOAN_TYPES = ['Purchase', 'Refinance', 'Equity']
export const MORTGAGE_LENDER_TYPES = [
  'Bank',
  'Commercial Corporations',
  'Construction/Development Company',
  'Credit Union',
  'Et Al (And Others)',
  'Federal Savings Bank',
  'First National Wealth Management Corporation',
  'Funding/Finance Company',
  'Government Agency',
  'Holding Company',
  'Insurance Company',
  'Internet Storefront',
  'Lending Institution',
  'Mortgage Company',
  'Mortgage Electronic Registration System',
  'Mortgage Funding Company',
  'Non Lending Institution',
  'Private Party',
  'Reo/Foreclosure Company',
  'Reverse Mortgage Lender',
  'Seller',
  'Subprime Lender',
  'Undisclosed',
  'Unknown'
]

export const SCHOOL_TYPES = ['high', 'middle', 'elementary']

type FilterMeta = {
  group?: TopHap.FilterGroupType
  type?: TopHap.FilterType
  options?: string[]
  prefix?: string
  suffix?: string
  onlyCA?: boolean
  overrideEstAbbrevieation?: boolean
  numberOfDecimals?: number // 0
  title?: string
  comma?: boolean
  tooltip?: string
  initValue?: any
  unpublish?: boolean
}
// array that determines the order of filters in FilterOptionList, if not included it will be displayed in order of FILTER_OPTIONS (alphabetical)
export const FILTER_OPTION_ORDER: TopHap.FilterMetric[] = ['status', 'property_types', 'estimate_price', 'bedrooms', 'bathrooms', 'living_area', 'lot_size', 'garage', 'price_per_sqft', 'year_built', 'stories']
export const FILTER_GROUPS = ['Popular', 'Property', 'Region', 'Value', 'Ownership', 'Transaction', 'Listing', 'Loan', 'Schools', 'Hazards', 'Community', 'OTHER']
export const FILTER_OPTIONS: {
  [key in TopHap.FilterMetric]: FilterMeta
} = {
  accessibility_elevator: {
    type: 'Boolean',
    tooltip: 'Properties with accessibility elevator according to public records',
    group: 'Property'
  },
  age: {
    group: 'Property',
    type: 'NumberRange',
    suffix: 'years',
    tooltip: 'Property age in years.',
    numberOfDecimals: 2
  },
  agent_email: {
    group: 'Transaction',
    type: 'Agent',
    tooltip: 'Email address of any agent involved in the most recent transaction for the property.'
  },
  agent_license: {
    group: 'Transaction',
    type: 'Agent',
    tooltip: "License number of an agent involved in a property's transaction history."
  },
  agent_name: {
    group: 'Transaction',
    type: 'Agent',
    tooltip: "Name of any agent involved in a property's transaction history."
  },
  office_name: {
    type: 'Agent',
    title: "Agent's Office Name",
    tooltip: "The name of any real estate agent's office involved in a property's transaction history.",
    group: 'Transaction'
  },
  bathrooms: {
    group: 'Popular',
    type: 'NumberRange',
    tooltip: 'Number of bathrooms.',
    numberOfDecimals: 2
  },
  bedrooms: {
    group: 'Popular',
    type: 'NumberRange',
    tooltip: 'Number of bedrooms.'
  },
  buyer_name: {
    group: 'Transaction',
    type: 'Name',
    tooltip: 'Name of a buyer of the property.',
    unpublish: true
  },
  census_age_median: {
    group: 'Community',
    type: 'NumberRange',
    title: 'Median Age',
    tooltip: 'Median age of the population in the ZIP code. This data comes from the latest US Census.'
  },
  census_collar_blue: {
    group: 'Community',
    suffix: '%',
    type: 'NumberRange',
    title: 'Blue Collar',
    tooltip: 'Percentage of people with blue collar jobs in the ZIP code. This data comes from the latest US Census.'
  },
  census_collar_white: {
    group: 'Community',
    suffix: '%',
    type: 'NumberRange',
    title: 'White Collar',
    tooltip: 'Percentage of people with white collar jobs in the ZIP code. This data comes from the latest US Census.'
  },
  census_divorced: {
    group: 'Community',
    suffix: '%',
    type: 'NumberRange',
    title: 'Divorced',
    tooltip: 'Percentage of divorced people in the ZIP code. This data comes from the latest US Census.'
  },
  census_educational_climate_index: {
    group: 'Community',
    type: 'NumberRange',
    title: 'Educational Climate Index',
    tooltip: 'Educational climate index of the ZIP code. The educational climate index offers an objective measure of student, staff, and parent perceptions of the quality, character, and health of the learning environment in a school. This data comes frmo the latest US Census.'
  },
  census_employee_salary_average: {
    group: 'Community',
    prefix: '$',
    type: 'NumberRange',
    title: 'Employee Salary (Average)',
    tooltip: 'Average annual employee salary in the ZIP code. This data comes from the latest US Census.'
  },
  census_employee_salary_median: {
    group: 'Community',
    prefix: '$',
    type: 'NumberRange',
    title: 'Employee Salary (Median)',
    tooltip: 'Median annual employee salary in the ZIP code. This data comes from the latest US Census.'
  },
  census_family_size: {
    group: 'Community',
    suffix: 'ppl',
    type: 'NumberRange',
    title: 'Family Size',
    tooltip: 'Average family size in the ZIP code. This data comes from the latest US Census.',
    numberOfDecimals: 1
  },
  census_household_disposable_income_median: {
    group: 'Community',
    prefix: '$',
    type: 'NumberRange',
    title: 'Household Disposable Income (Average)',
    tooltip: 'Average annual household disposable income in the ZIP code. This data comes from the latest US Census.'
  },
  census_household_income_average: {
    group: 'Community',
    prefix: '$',
    type: 'NumberRange',
    title: 'Household Income (Average)',
    tooltip: 'Average household income in the ZIP code. This data comes from the latest US Census.'
  },
  census_household_income_median: {
    group: 'Community',
    prefix: '$',
    type: 'NumberRange',
    title: 'Household Income (Median)',
    tooltip: 'Median household income in the ZIP code. This data comes from the latest US Census.'
  },
  census_household_size: {
    group: 'Community',
    suffix: 'ppl',
    type: 'NumberRange',
    title: 'Household Size',
    tooltip: 'Average household size in the ZIP code. This data comes from the latest US Census.',
    numberOfDecimals: 1
  },
  census_household_total_expenditure_average: {
    group: 'Community',
    prefix: '$',
    type: 'NumberRange',
    title: 'Household Total Expenditure (Average)',
    tooltip: 'Average household spending per year in the ZIP code. This data comes from the latest US Census.'
  },
  census_households: {
    group: 'Community',
    suffix: 'per ZIP',
    type: 'NumberRange',
    title: 'Number of Households',
    tooltip: 'Number of households in the ZIP code. This data comes from the latest US Census.'
  },
  census_marriage_never: {
    group: 'Community',
    suffix: '%',
    type: 'NumberRange',
    title: 'Never Married',
    tooltip: 'Percentage of people who have never marrieid in the ZIP code. This data comes from the latest US Census.'
  },
  census_marriage_now: {
    group: 'Community',
    suffix: '%',
    type: 'NumberRange',
    title: 'Now Married',
    tooltip: 'Percentage of people who are currently marrieid in the ZIP code. This data comes from the latest US Census.'
  },
  census_per_capita_income: {
    group: 'Community',
    prefix: '$',
    type: 'NumberRange',
    title: 'Income Per Capita',
    tooltip: 'Average individual income of people in the ZIP code. This data comes from the latest US Census.'
  },
  census_population: {
    group: 'Community',
    suffix: 'per ZIP',
    type: 'NumberRange',
    title: 'Population',
    tooltip: 'Number of people living in the ZIP code. This data comes from the latest US Census.'
  },
  census_population_daytime: {
    group: 'Community',
    suffix: '%',
    type: 'NumberRange',
    title: 'Daytime Population',
    tooltip: 'Percentage of population present in the ZIP code at daytime. This data comes from the latest US Census.'
  },
  census_population_density: {
    group: 'Community',
    suffix: 'ppl/mi²',
    type: 'NumberRange',
    title: 'Population Density',
    tooltip: 'Number of people living in the ZIP code per square mile. This data comes from the latest US Census.'
  },
  census_population_female: {
    group: 'Community',
    suffix: '%',
    type: 'NumberRange',
    title: 'Female Population',
    tooltip: 'Percentage of female population in the ZIP code. This data comes from the latest US Census.'
  },
  census_population_male: {
    group: 'Community',
    suffix: '%',
    type: 'NumberRange',
    title: 'Male Population',
    tooltip: 'Percentage of male population in the ZIP code. This data comes from the latest US Census.'
  },
  census_population_seasonal: {
    group: 'Community',
    suffix: '%',
    type: 'NumberRange',
    title: 'Seasonal Population',
    tooltip: 'Percentage of seasonal population in the ZIP derived from seasonal vacant home figures (US Census). This data comes from the latest US Census.'
  },
  census_separated: {
    group: 'Community',
    suffix: '%',
    type: 'NumberRange',
    title: 'Separated',
    tooltip: 'Percentage of people who are currently separated in the ZIP code. This data comes from the latest US Census.'
  },
  census_travel_time_to_work: {
    group: 'Community',
    suffix: 'mins',
    type: 'NumberRange',
    title: 'Travel time to work',
    tooltip: 'Average commute time of workers living in the ZIP code. This data comes from the latest US Census.'
  },
  census_widowed: {
    group: 'Community',
    suffix: '%',
    type: 'NumberRange',
    title: 'Widowed',
    tooltip: 'Percentage of widowed people in the ZIP code. This data comes from the latest US Census.'
  },
  // climate_drought_risk: {
  //   group: 'Hazards',
  //   type: 'NumberRange',
  //   title: 'Drought Risk'
  // },
  // climate_fema_flood_risk: {
  //   group: 'Hazards',
  //   type: 'NumberRange',
  //   title: 'FEMA Flood Risk'
  // },
  // climate_flood_risk: {
  //   group: 'Hazards',
  //   type: 'NumberRange',
  //   title: 'Flood Risk'
  // },
  // climate_heat_risk: {
  //   group: 'Hazards',
  //   type: 'NumberRange',
  //   title: 'Heat Risk'
  // },
  // climate_storm_risk: {
  //   group: 'Region',
  //   type: 'NumberRange',
  //   title: 'Storm Risk'
  // },
  // climate_total_risk: {
  //   group: 'Hazards',
  //   type: 'NumberRange',
  //   title: 'Climate Risk'
  // },
  // climate_wildfire_risk: {
  //   group: 'Hazards',
  //   type: 'NumberRange',
  //   title: 'Wilfdire Risk'
  // },
  election: {
    group: 'Community',
    suffix: '%',
    type: 'Election',
    title: 'Election 2020',
    tooltip: 'Percentage of votes for either democratic or republican party in the 2020 election.'
  },
  congressional_district: {
    type: 'String',
    tooltip: "A territorial division of a state from which a member of the U.S. House of Representatives is elected. A property's congressional district number can be found inside the public records section of the property detail page."
  },
  description: {
    group: 'Listing',
    type: 'String',
    title: 'Keyword',
    tooltip: "Keyword from listing's description."
  },
  elevation: {
    group: 'Region',
    type: 'NumberWithSubMetric',
    suffix: 'ft',
    tooltip: 'Lot elevation above sea level measured in feet.'
  },
  estimate_accuracy: {
    group: 'Value',
    onlyCA: true,
    type: 'NumberRange',
    suffix: '%',
    tooltip: "TopHap AVM's Estimate accuracy for a property (as a percentage).",
    unpublish: true
  },
  estimate_date: {
    group: 'Value',
    overrideEstAbbrevieation: true,
    title: 'Estimate Date',
    type: 'Date',
    tooltip: 'Date of the most recent TopHap AVM estimate.'
  },
  estimate_ppsf: {
    group: 'Value',
    type: 'NumberRange',
    prefix: '$',
    suffix: '/ft²',
    title: 'Estimate $/ft²',
    tooltip: 'TopHap Estimate price per ft².'
  },
  estimate_price: {
    group: 'Popular',
    type: 'Price',
    tooltip: "TopHap’s proprietary automated valuation model's (AVM) estimate price for the property."
  },
  estimate_list_price_ratio: {
    group: 'Value',
    title: 'Est. Price to List Price Ratio',
    type: 'NumberRange',
    suffix: '%',
    tooltip: 'The ratio of the current estimate price to the last list price.'
  },
  estimate_sold_price_ratio: {
    group: 'Value',
    title: 'Estimate Price to Sold Price Ratio',
    type: 'NumberRange',
    tooltip: 'The ratio of the current estimate price to the last sold price.',
    suffix: '%',
    numberOfDecimals: 1
  },
  estimate_rent_accuracy: {
    group: 'Value',
    onlyCA: true,
    type: 'NumberRange',
    suffix: '%',
    tooltip: "TopHap AVM's rental estimate accuracy for a property (as a percentage).",
    unpublish: true
  },
  estimate_rent_ppsf: {
    group: 'Value',
    onlyCA: true,
    type: 'NumberRange',
    suffix: 'ft²',
    title: 'Estimate Rent $/ft²',
    tooltip: 'Estimated rental price per ft².'
  },
  estimate_rent_price: {
    group: 'Value',
    onlyCA: true,
    type: 'Price',
    tooltip: 'Estimated rental price.',
    unpublish: true
  },
  fips: {
    type: 'String',
    title: 'FIPS',
    tooltip: 'Federal Information Processing Standards (FIPS) code.',
    unpublish: true
  },
  // flattest_area_acres: {
  //   group: 'Property',
  //   title: "Lot's Flat Area",
  //   type: 'NumberRange',
  //   numberOfDecimals: 2,
  //   suffix: 'ac',
  //   tooltip: 'Largest contiguous area of a lot in acres that is flat'
  // },
  // flattest_parcel_ratio: {
  //   group: 'Property',
  //   numberOfDecimals: 2,
  //   suffix: '%',
  //   title: "Lot's Flat Area Percentage",
  //   tooltip: "Percentage of a lot's area that is contiguously flat",
  //   type: 'NumberRange'
  // },
  foreclosure: {
    type: 'Boolean',
    tooltip: 'Whether or not a property is a foreclosure.',
    unpublish: true
  },
  garage: {
    group: 'Popular',
    onlyCA: true,
    type: 'NumberRange',
    tooltip: 'Number of parking spaces declared by listing agent.'
  },
  tour: {
    group: 'Listing',
    type: 'Boolean',
    tooltip: '3D or video tour of the property.'
  },
  hazard_co2: {
    group: 'Hazards',
    type: 'NumberRange',
    title: 'Carbon Monoxide Index',
    tooltip: 'Carbon Monoxide is a colorless, odorless, highly poisonous gas formed by the incomplete combustion of carbon or a carbonaceous material, such as gasoline. The National Average is 100.'
  },
  hazard_earthquake: {
    group: 'Hazards',
    type: 'NumberRange',
    title: 'Earthquake Index',
    tooltip: 'The total earthquake risk based on analysis of historical earthquake magnitudes and fault locations. The National Average is 100.'
  },
  hazard_hail: {
    group: 'Hazards',
    type: 'NumberRange',
    title: 'Hail Index',
    tooltip: 'The total risk of damaging hailstorms. Based on analysis of historical hail frequency and severity. The National Average is 100.'
  },
  hazard_hurricane: {
    group: 'Hazards',
    type: 'NumberRange',
    title: 'Hurricane Index',
    tooltip: 'The total hurricane risk. Based on analysis of historical hurricane tracks and intensity. The National Average is 100.'
  },
  hazard_lead: {
    group: 'Hazards',
    type: 'NumberRange',
    title: 'Lead Index',
    tooltip: "A metallic element used in products like solder and paint. Lead's presence in the air may be particularly harmful to the health of infants and children. The National Average is 100."
  },
  hazard_no2: {
    group: 'Hazards',
    type: 'NumberRange',
    title: 'NO₂ Index',
    tooltip: 'Poisonous brown gases, often found in auto exhaust fumes. It is a primary contributor to smog. The National Average is 100.'
  },
  hazard_ozone: {
    group: 'Hazards',
    type: 'NumberRange',
    title: 'Ozone Index',
    tooltip: 'An invisible gas that irritates and impairs breathing. The National Average is 100.'
  },
  hazard_particules: {
    group: 'Hazards',
    type: 'NumberRange',
    title: 'Particulate Matter Index',
    tooltip: 'Fine particles of pollutants which tend to reduce visibility and invade the lungs deeply. The National Average is 100.'
  },
  hazard_pollution: {
    group: 'Hazards',
    type: 'NumberRange',
    title: 'Air Pollution Index',
    tooltip: 'A score that represents the relative air quality by combining indices of Ozone, Carbon Monoxide, Lead, Nitrogen Oxide, and Particulate Matter compared to the national average of 100. A score of 200 indicates twice the presence of air pollutants than the national average, while 50 indicates half the presence. The different types of pollution are given equal weight in this score.'
  },
  hazard_tornado: {
    group: 'Hazards',
    type: 'NumberRange',
    title: 'Tornado Index',
    tooltip: 'The total tornado risk. Based on analysis of historical tornado tracks and intensity. The National Average is 100.'
  },
  hazard_weather: {
    group: 'Hazards',
    type: 'NumberRange',
    title: 'Weather Risk',
    tooltip: 'The total risk from various storm types (hurricanes, tornadoes, damaging hailstorms and damaging wind storms). The National Average is 100.'
  },
  hazard_wind: {
    group: 'Hazards',
    type: 'NumberRange',
    title: 'Wind Index',
    tooltip: 'The total risk of severe winds. Based on analysis of the frequency and severity of historical damaging windstorms, typically associated with thunderstorms. The National Average is 100.'
  },
  hoa_fee: {
    group: 'Property',
    onlyCA: true,
    title: 'HOA Fee',
    type: 'NumberRange',
    prefix: '$',
    tooltip: 'Monthly dues paid to a homeowner association (HOA).'
  },
  legal_description: {
    group: 'Property',
    type: 'String',
    tooltip: 'Geographical description of real estate that identifies its precise location, boundaries and any easements for the purpose of a legal transaction.'
  },
  lists: {
    type: 'Lists',
    tooltip: 'Properties that exist in your lists on TopHap.'
  },
  list_date: {
    group: 'Listing',
    type: 'Date',
    tooltip: 'Listing date.'
  },
  list_price: {
    group: 'Listing',
    type: 'Price',
    tooltip: 'Listing price.'
  },
  list_price_diff: {
    group: 'Listing',
    type: 'Boolean',
    title: 'Price Change',
    tooltip: 'Whether the property had a price increase or reduction.'
  },
  list_price_per_sqft: {
    group: 'Listing',
    title: 'List Price/ft²',
    type: 'NumberRange',
    prefix: '$',
    suffix: '/ft²',
    tooltip: 'Listing price per ft² of the living area.'
  },
  list_vs_sold: {
    group: 'Transaction',
    onlyCA: true,
    type: 'NumberRange',
    title: 'Sold Price to List Price Ratio',
    tooltip: 'The ratio of the last sold price to the most recent list price (prior to the sale). If the property is sold over asking price, the ratio is more than 100%.'
  },
  listing_id: {
    group: 'Listing',
    title: 'Listing ID',
    type: 'String',
    tooltip: 'Listing ID in the MLS.'
  },
  living_area: {
    group: 'Popular',
    type: 'NumberRange',
    suffix: 'ft²',
    tooltip: 'The size of a home measured in square feet of living space, which does not include a garage.'
  },
  location: {
    title: 'Coordinates',
    type: 'Location',
    tooltip: 'Input two coordinates to define a square-shaped area.',
    unpublish: true
  },
  lot_size: {
    group: 'Popular',
    type: 'LotSize',
    tooltip: 'Lot size measured in acres or square feet (1 acre equals 43560 ft²).',
    numberOfDecimals: 2
  },
  mortgage_total_loan_amount: {
    group: 'Loan',
    title: 'Total Loan Amount',
    type: 'NumberRange',
    tooltip: 'Total dollar amount of the loan issued to the property owner.',
    unpublish: true
  },
  mortgage_arms_length: {
    group: 'Transaction',
    title: "Arm's Length",
    type: 'Boolean',
    tooltip: 'A transaction between independent parties in which both parties are acting in their own self-interest.'
  },
  mortgage_investment_property: {
    group: 'Transaction',
    title: 'Investment Property',
    type: 'Boolean',
    tooltip: "Properties declared as 'investment property' at the time of purchase by the buyer.",
    unpublish: true
  },
  mortgage_title_company: {
    group: 'Transaction',
    title: 'Title Company',
    tooltip: "Name of the title company used in a property's transaction history.",
    type: 'Name'
  },
  nri_avalanche: {
    group: 'Hazards',
    title: 'NRI Avalanche',
    type: 'NumberRange',
    tooltip: 'A mass of snow in swift motion traveling down a mountainside. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).',
    numberOfDecimals: 2
  },
  nri_coastal_flooding: {
    group: 'Hazards',
    type: 'NumberRange',
    title: 'NRI Coastal Flooding',
    tooltip: 'When water inundates or covers normally dry coastal land as a result of high or rising tides or storm surges. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).',
    numberOfDecimals: 2
  },
  nri_cold_wave: {
    group: 'Hazards',
    title: 'NRI Cold Wave',
    type: 'NumberRange',
    tooltip: 'A rapid fall in temperature within 24 hours and extreme low temperatures for an extended period. The temperatures classified as a cold wave are dependent on the location and defined by the local National Weather Service (NWS) weather forecast office. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).',
    numberOfDecimals: 2
  },
  nri_drought: {
    group: 'Hazards',
    title: 'NRI Drought',
    type: 'NumberRange',
    tooltip: 'A deficiency of precipitation over an extended period of time resulting in a water shortage. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).',
    numberOfDecimals: 2
  },
  nri_earthquake: {
    group: 'Hazards',
    title: 'NRI Earthquake',
    type: 'NumberRange',
    tooltip: "A shaking of the earth's surface by energy waves emitted by slowly moving tectonic plates overcoming friction with one another underneath the earth's surface. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).",
    numberOfDecimals: 2
  },
  nri_hail: {
    group: 'Hazards',
    title: 'NRI Hail',
    type: 'NumberRange',
    tooltip: "A form of precipitation that occurs during thunderstorms when raindrops, in extremely cold areas of the atmosphere, freeze into balls of ice before falling towards the earth's surface. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).",
    numberOfDecimals: 2
  },
  nri_heat_wave: {
    group: 'Hazards',
    title: 'NRI Heat Wave',
    type: 'NumberRange',
    tooltip: 'A period of abnormally and uncomfortably hot and unusually humid weather typically lasting two or more days with temperatures outside the historical averages for a given area. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).',
    numberOfDecimals: 2
  },
  nri_hurricane: {
    group: 'Hazards',
    title: 'NRI Hurricane',
    type: 'NumberRange',
    tooltip: 'A tropical cyclone or localized, low-pressure weather system that has organized thunderstorms but no front (a boundary separating two air masses of different densities) and maximum sustained winds of at least 74 mph. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).',
    numberOfDecimals: 2
  },
  nri_ice_storm: {
    group: 'Hazards',
    title: 'NRI Ice Storm',
    type: 'NumberRange',
    tooltip: 'A freezing rain situation (rain that freezes on surface contact) with significant ice accumulations of 0.25 inches or greater. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).',
    numberOfDecimals: 2
  },
  nri_land_slide: {
    group: 'Hazards',
    title: 'NRI Land Slide',
    type: 'NumberRange',
    tooltip: 'The movement of a mass of rock, debris, or earth down a slope. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).',
    numberOfDecimals: 2
  },
  nri_lightning: {
    group: 'Hazards',
    title: 'NRI Lightning',
    type: 'NumberRange',
    tooltip: 'A visible electrical discharge or spark of electricity in the atmosphere between clouds, the air and/or the ground often produced by a thunderstorm. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).',
    numberOfDecimals: 2
  },
  nri_national: {
    group: 'Hazards',
    title: 'NRI National',
    type: 'NumberRange',
    tooltip: 'The National Risk Index is defined as the potential for negative impacts as a result of a natural hazard. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).',
    numberOfDecimals: 2
  },
  nri_riverine_flooding: {
    group: 'Hazards',
    title: 'NRI Riverine Flooding',
    type: 'NumberRange',
    tooltip: 'When streams and rivers exceed the capacity of their natural or constructed channels to accommodate water flow and water overflows the banks, spilling out into adjacent low-lying, dry land. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).',
    numberOfDecimals: 2
  },
  nri_strong_wind: {
    group: 'Hazards',
    title: 'NRI Stong Wind',
    type: 'NumberRange',
    tooltip: 'Strong Wind consists of damaging winds, often originating from thunderstorms, that are classified as exceeding 58 mph. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).',
    numberOfDecimals: 2
  },
  nri_tornado: {
    group: 'Hazards',
    title: 'NRI Tornado',
    type: 'NumberRange',
    tooltip: 'A narrow, violently rotating column of air that extends from the base of a thunderstorm to the ground and is visible only if it forms a condensation funnel made up of water droplets, dust, and debris. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).',
    numberOfDecimals: 2
  },
  nri_tsunami: {
    group: 'Hazards',
    title: 'NRI Tsunami',
    type: 'NumberRange',
    tooltip: 'A wave or series of waves generated by an Earthquake, Landslide, volcanic eruption, or even a large meteor hitting the ocean and causing a rise or mounding of water at the ocean surface. A Tsunami can travel across open ocean at about 500 mph and slow down to about 30 mph as it approaches land, causing it to grow significantly in height. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).',
    numberOfDecimals: 2
  },
  nri_volcano: {
    group: 'Hazards',
    title: 'NRI Volcano',
    type: 'NumberRange',
    tooltip: "Volcanic Activity occurs via vents that act as a conduit between the Earth's surface and inner layers, and erupt gas, molten rock, and volcanic ash when gas pressure and buoyancy drive molten rock upward and through zones of weakness in the Earth's crust. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).",
    numberOfDecimals: 2
  },
  nri_wildfire: {
    group: 'Hazards',
    title: 'NRI Wildfire',
    type: 'NumberRange',
    tooltip: 'An unplanned fire burning in natural or wildland areas such as forests, shrub lands, grasslands, or prairies. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).',
    numberOfDecimals: 2
  },
  nri_winter_weather: {
    group: 'Hazards',
    title: 'NRI Winter Weather',
    type: 'NumberRange',
    tooltip: 'Winter Weather consists of winter storm events in which the main types of precipitation are snow, sleet, or freezing rain. The score is constrained to range of 0 (lowest possible value) to 100 (highest possible value).',
    numberOfDecimals: 2
  },
  noise: {
    group: 'Region',
    onlyCA: true,
    suffix: 'dB',
    type: 'NumberRange',
    tooltip: 'Road and aviation noise in decibels (dB).'
  },
  open_house_date: {
    group: 'Listing',
    type: 'Date',
    tooltip: 'Date of past or upcoming open house events.'
  },
  list_price_change_date: {
    group: 'Listing',
    title: 'Price Change Date',
    type: 'Date',
    tooltip: 'Date the list price has been changed.'
  },
  owner_occupied: {
    group: 'Ownership',
    type: 'Boolean',
    tooltip: 'Owner-occupancy status of the property. Non-owner occupied properties can be rented, vacant, or used as secondary home.'
  },
  owner_out_of_city: {
    group: 'Ownership',
    type: 'Boolean',
    tooltip: "Owner's mailing address is in a city different from the subject property."
  },
  owner_out_of_county: {
    group: 'Ownership',
    type: 'Boolean',
    tooltip: "Owner's mailing address is in a county different from the subject property."
  },
  owner_out_of_state: {
    group: 'Ownership',
    type: 'Boolean',
    tooltip: "Owner's mailing address is in a state different from the subject property."
  },
  ownership_days: {
    group: 'Ownership',
    type: 'OwnershipDays',
    tooltip: 'Number of days since last deed transfer.'
  },
  permits_total_count: {
    group: 'Property',
    type: 'NumberRange',
    title: 'Total Permits Count',
    tooltip: 'Total number of permit records for the property.'
  },
  permits_total_value: {
    group: 'Property',
    prefix: '$',
    type: 'NumberRange',
    title: 'Total Permits Value',
    tooltip: 'Total value of permits for the property.'
  },
  permits: {
    group: 'Property',
    type: 'Permits',
    tooltip: 'Permit records for the property by value, type, description, status and date.'
  },
  pool: {
    group: 'Property',
    type: 'Boolean',
    title: 'Pool',
    tooltip: 'Whether the property has a pool.'
  },
  precipitation: {
    group: 'Region',
    type: 'NumberRange',
    suffix: 'inches',
    tooltip: 'Combined average annual rainfall and snowfall in inches.'
  },
  previous_sale_date: {
    group: 'Transaction',
    title: 'Previous Sold Date',
    type: 'Date',
    tooltip: 'Date of any previous sale of the property.'
  },
  previous_sale_price: {
    group: 'Transaction',
    title: 'Previous Sold Price',
    type: 'Price',
    tooltip: 'Price of the property at any previous sale.'
  },
  price: {
    type: 'Price',
    tooltip: 'Most recent list or sold price for the property.'
  },
  price_per_sqft: {
    group: 'Popular',
    title: 'Price/ft²',
    type: 'NumberRange',
    prefix: '$',
    suffix: '/ft²',
    tooltip: 'List or sold price per ft².'
  },
  profit: {
    suffix: '%',
    type: 'NumberRange',
    tooltip: 'The difference between the last sold price and the previous sold price as a percentage.',
    numberOfDecimals: 2,
    unpublish: true
  },
  property_id: {
    type: 'StringArray',
    title: 'Property ID',
    tooltip: 'Has a valid property id for the property within the area',
    unpublish: true
  },
  property_types: {
    group: 'Popular',
    type: 'PropertyTypes',
    tooltip: 'The type of real estate.'
  },
  radius: {
    type: 'Radius',
    tooltip: 'Set a radius (in miles) around specified location. The location coordinates could be found in public records of a property on TopHap or in public maps.'
  },
  rent_yield: {
    suffix: '%',
    title: 'Gross Rental Yield',
    type: 'NumberRange',
    tooltip: 'Estimated annual gross rent income divided by estimate property price.',
    onlyCA: true
  },
  reo: {
    type: 'Boolean',
    title: 'REO',
    tooltip: "Whether a property is 'Real Estate Owned' or not at the time of the last listing event. REO properties failed to sell at a foreclosure auction, so they are typicaly owned by a lender."
  },
  sale_price: {
    group: 'Transaction',
    title: 'Sold Price',
    type: 'Price',
    tooltip: 'Most recent price the property was sold for.'
  },
  sale_price_per_sqft: {
    group: 'Transaction',
    title: 'Sold Price/ft²',
    type: 'NumberRange',
    prefix: '$',
    suffix: '/ft²',
    tooltip: 'Most recent $/ft² the property was sold for.'
  },
  school_rating: {
    group: 'Schools',
    type: 'SchoolRating',
    tooltip: 'School letter rating from A+ to F, A+ being the best.'
  },
  school_enrollment: {
    group: 'Schools',
    suffix: 'ppl',
    type: 'NumberRange',
    tooltip: 'Number of students enrolled in a school within school attendance area.'
  },
  school_student_teacher_ratio: {
    group: 'Schools',
    tooltip: 'Average number of students per teacher.',
    title: 'Students per teacher',
    type: 'NumberRange',
    numberOfDecimals: 1
  },
  seller_name: {
    group: 'Transaction',
    type: 'Name',
    tooltip: 'Name of a seller of the property.',
    unpublish: true
  },
  short_sale: {
    type: 'Boolean',
    tooltip: 'A short sale, also known as a pre-foreclosure sale, is when an owner sells a property for less than the balance remaining on the mortgage.'
  },
  slope: {
    group: 'Property',
    type: 'NumberWithSubMetric',
    title: 'Lot Slope',
    suffix: '%',
    tooltip: 'The average slope percentage of the lot. This measures how flat or steep a lot is and can be a good indicator of the buildability or cost of building of a lot as well as potential attributes such as view or usable yard area.'
  },
  sold_date: {
    group: 'Transaction',
    type: 'Date',
    tooltip: 'Most recent date that the property was sold.',
    numberOfDecimals: 2
  },
  status: {
    group: 'Popular',
    title: 'Property Status',
    type: 'Status',
    tooltip: 'Current property status.'
  },
  stories: {
    group: 'Popular',
    type: 'NumberRange',
    tooltip: 'Number of levels, floors, or stories for the property.'
  },
  tax_assessed_value: {
    group: 'Ownership',
    prefix: '$',
    type: 'NumberRange',
    tooltip: 'Tax assessed value is the dollar value assigned to a property for tax purposes.',
    numberOfDecimals: 2
  },
  tax_billed_amount: {
    group: 'Ownership',
    prefix: '$',
    type: 'NumberRange',
    tooltip: 'Amount of property taxes billed for the most recent tax year.',
    numberOfDecimals: 2
  },
  tax_date: {
    group: 'Ownership',
    type: 'Date',
    tooltip: 'Date of the most recent tax bill.'
  },
  tax_rate: {
    group: 'Ownership',
    suffix: '%',
    title: 'Tax % of Assessed Value',
    type: 'NumberRange',
    tooltip: 'Taxes billed as a percentage of total assessed value.',
    numberOfDecimals: 2
  },
  temperature: {
    group: 'Region',
    suffix: '°F',
    type: 'Temperature',
    tooltip: 'Seasonal temperature (°F) sampled over multiple years.',
    numberOfDecimals: 1
  },
  usable_area: {
    group: 'Property',
    title: 'Lot Usable Space',
    type: 'NumberRange',
    suffix: '%',
    tooltip: 'Percentage of the lot not currently utilized by the footprint of the property. Lot Usable Space is calculated by Living area / # Stories / Lot Size.',
    numberOfDecimals: 2
  },
  walkability: {
    group: 'Region',
    type: 'NumberRange',
    tooltip: 'Walkability is scored according to the National Walkability Index. Walkability depends upon characteristics of the built environment that influence the likelihood of walking being used as a mode of travel.'
  },
  year_built: {
    group: 'Popular',
    type: 'NumberRange',
    tooltip: 'The year the property was built.',
    comma: false
  },
  rooms_attic_area: {
    type: 'NumberRange',
    suffix: 'ft²',
    tooltip: 'Area of the attic declared in public records.',
    group: 'Property'
  },
  rooms_basement_area: {
    type: 'NumberRange',
    suffix: 'ft²',
    tooltip: 'Area of the basement declared in public records.',
    group: 'Property'
  },
  apn: {
    type: 'String',
    title: 'APN',
    tooltip: "Assessor's Parcel Number (APN) is a unique number assigned to real property by the tax assessor of the property's jurisdiction.",
    group: 'Property'
  },
  zoned_code: {
    title: 'Zoning Code',
    type: 'StringArray',
    tooltip: 'An alpha-numeric code that identifies what can be built on a particular lot.',
    group: 'Property'
  },
  zip: {
    title: 'ZIP',
    type: 'String',
    tooltip: '5 digit postal code used by the United States Postal Service.'
  },
  mortgage_loan_amount: {
    group: 'Loan',
    title: 'Loan Amount',
    type: 'NumberRange',
    tooltip: 'Amount of issued loan.'
  },
  mortgage_loan_type: {
    group: 'Loan',
    title: 'Loan Type',
    type: 'MultiSelect',
    options: MORTGAGE_LOAN_TYPES,
    tooltip: 'Type of issued loan.'
  },
  mortgage_recording_date: {
    group: 'Loan',
    title: 'Loan Recording Date',
    type: 'Date',
    tooltip: 'Date the loan was recorded'
  },
  mortgage_term_date: {
    group: 'Loan',
    title: 'Loan Termination Date',
    type: 'Date',
    tooltip: 'Expected date current loan will terminate.'
  },
  mortgage_term: {
    group: 'Loan',
    title: 'Loan Term',
    type: 'NumberRange',
    suffix: 'years',
    tooltip: 'Duration of the current loan.'
  },
  mortgage_lender_name: {
    group: 'Loan',
    title: 'Lender Name',
    type: 'Name',
    tooltip: "Name of the lender that issued a property's current loan."
  },
  mortgage_lender_type: {
    group: 'Loan',
    title: 'Lender Type',
    type: 'MultiSelect',
    options: MORTGAGE_LENDER_TYPES,
    tooltip: "Type of a lender that issued a property's current loan."
  },
  mortgage_position_count: {
    group: 'Loan',
    title: '# of Loan Positions',
    type: 'NumberRange',
    tooltip: 'Number of currently active loans for a property.'
  },
  // unemployment: {
  //   group: 'Community',
  //   title: 'Unemployment Count',
  //   tooltip: 'Number of unemployed people in a county',
  //   type: 'NumberRange'
  // },
  // unemployment_ratio: {
  //   group: 'Community',
  //   suffix: '%',
  //   title: 'Unemployment Rate',
  //   tooltip: 'The number of unemployed people as a percentage of the labor force in a county.',
  //   type: 'NumberRange',
  //   numberOfDecimals: 1
  // },
  zone_ids: {
    type: 'Zone',
    title: 'Location',
    tooltip: "Any zone - state, county, city, town, neighborhood, etc. When using multiptle zone filters, switch condition between filter groups to 'or' ('and' is default) to search for properties in multiple areas.",
    unpublish: true
  }
}

export const getOptionTitle = (metric: TopHap.FilterMetric) => {
  const option = FILTER_OPTIONS[metric]
  let optionTitle = `${option.title || capitalize.words(metric.replace(/_/g, ' '))}${option.onlyCA ? ' *' : ''}`
  if (!option.overrideEstAbbrevieation) {
    optionTitle = optionTitle.replace('Estimate', 'Est.')
  }
  return optionTitle
}
