import React from 'react'
import Link, { NavLink } from '#/components/Link'
import cn from '#/utils/classnames'
import styles from './styles.module.css'

export interface DropMenuProps {
  mode: 'hover' | 'click'
  title: React.ReactNode
  classes?: {
    root?: string
    trigger?: string
    content?: string
    expanded?: string
  }
  children?: React.ReactNode
}

interface DropMenuItemProps {
  children?: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  [ele: string]: any
}

interface DropMenuLinkItemProps extends DropMenuItemProps {
  href: string
}

interface LinkMenuItemProps {
  className?: string
  children: React.ReactNode
  href: string
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export function DropMenu({
  mode,
  title,
  classes,
  children
}: DropMenuProps) {
  const [expanded, setExpanded] = React.useState(false)
  const refMenu = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (mode === 'click') {
      document.addEventListener('click', handleClickOutside)
    }

    return () => {
      if (mode === 'click') {
        document.removeEventListener('click', handleClickOutside)
      }
    }
  }, [mode])

  function handleClickOutside(e: MouseEvent) {
    if (refMenu.current && !refMenu.current.contains(e.target as Node)) {
      setExpanded(false)
    }
  }

  function onClick() {
    if (mode === 'click') {
      setExpanded(!expanded)
    }
  }

  return (
    <div
      className={cn(
        styles['th-drop-menu'],
        classes?.root,
        { [styles['th-mode-hover']]: mode === 'hover' },
        { [styles['th-expanded']]: expanded },
        expanded && classes?.expanded
      )}
    >
      <div
        ref={refMenu}
        className={cn(styles['th-drop-menu-trigger'], classes?.trigger)}
        onClick={onClick}
      >
        {title}
      </div>
      <ul className={cn(styles['th-drop-menu-content'], classes?.content)}>
        {children}
      </ul>
    </div>
  )
}

DropMenu.defaultProps = { mode: 'hover' }

DropMenu.Item = ({ onClick, children, ...props }: DropMenuItemProps) => (
  <li className={styles['th-drop-menu-item']} {...props} onClick={onClick}>
    {children}
  </li>
)

DropMenu.LinkItem = function ({
  onClick,
  children,
  ...props
}: DropMenuLinkItemProps) {
  return (
    <NavLink
      activeClassName={styles['th-active']}
      className={styles['th-drop-menu-item']}
      onClick={onClick}
      {...props}
    >
      {children}
    </NavLink>
  )
}

export function LinkMenuItem({ className, children, onClick, href }: LinkMenuItemProps) {
  // TODO: check if it is active
  return (
    <Link className={cn(styles['th-menu-item'], className)} href={href} onClick={onClick}>
      {children}
    </Link>
  )
}
