import type { IntercomMethod } from 'react-use-intercom'

/**
 * Safely exposes `window.Intercom` and passes the arguments to the instance
 *
 * @param method method passed to the `window.Intercom` instance
 * @param args arguments passed to the `window.Intercom` instance
 *
 * @see {@link https://developers.intercom.com/installing-intercom/docs/intercom-javascript}
 */
export default function intercomAPI(method: IntercomMethod, ...args: Array<any>) {
  if (typeof window !== 'undefined' && window.Intercom) {
    if (window.Intercom) window.Intercom.apply(null, [method, ...args])
    else console.warn(`${method} Intercom instance is not initalized yet`)
  }
}
