import { createAction } from '@reduxjs/toolkit'
// import type { ManipulateType } from 'dayjs'

export const SetPropertyDetails = createAction<{
  property: TopHap.ExtendedProperty
  media?: TopHap.PropertyMedia,
  comparables?: TopHap.ExtendedProperty[],
  user?: TopHap.UserInfo,
  token?: string,
  mainPriceMetric?: { operator:string, value?:string },
}>('property/set_property_details')
export const SetHistory = createAction<{ history: TopHap.PropertyState['history'] }>('property/set_history')
export const GetHistory = createAction('property/get_history')
export const GetOwnerInfo = createAction('property/get_owner_info')
export const SetOwnerInfo = createAction<{ ownerInfo: TopHap.PropertyState['ownerInfo'] }>('property/set_owner_info')
export const GetPublicRecord = createAction('property/get_public_record')
export const SetPublicRecord = createAction<{ publicRecord: TopHap.PropertyState['publicRecord'] }>('property/set_public_record')
// export const GetPrediction = createAction('property/get_prediction')
// export const GetEstimateHistory = createAction<{
//   dateOption: [number, ManipulateType]
//   update?: boolean
//   mode?: TopHap.Service.EstimateForMarketRequest['mode'] // default: 'without_accuracy'
// }>('property/get_estimate_history')
// export const GetRentEstimateHistory = createAction<{
//   dateOption: [number, ManipulateType]
//   update?: boolean
//   mode?: TopHap.Service.EstimateForMarketRequest['mode'] // default: 'without_accuracy'
// }>('property/get_rent_estimate_history')
// export const GetMarketHistory = createAction<{
//   dateOption: [number, ManipulateType]
//   update?: boolean,
//   params?: {
//     zones?: string[],
//     filters?: {
//       rental?: boolean,
//       and: boolean,
//       filters: TopHap.FilterGroup[]
//     }
//   }
// }>('property/get_market_history')
export const GetMarketChanges = createAction<TopHap.Service.GetMarketChangesRequest>('property/get_market_changes')
export const SetMarketChanges = createAction<{ marketChanges: TopHap.PropertyState['marketChanges'] }>('property/set_market_changes')
export const GetInsightsData = createAction('property/get_insights_data')
export const SetInsightsData = createAction<{ insightsData: TopHap.PropertyState['insightsData'] }>('property/set_insights_data')
// export const GetSchoolDetails = createAction('property/get_school_details')
// export const GetComparables = createAction('property/get_comparables')
// export const GetNeighborhood = createAction('property/get_neighborhood')
// export const GetNearbyStatistics = createAction<Pick<TopHap.Service.GetNearbyStatisticsRequest, 'polygon' | 'filters'>>('properties/nearby_statistics')
// export const GetNearbyStatistics = createAction<{ zoneId: string }>('properties/nearby_statistics')
export const SubmitLead = createAction<TopHap.Service.SubmitLeadRequest>('property/submit_lead')
