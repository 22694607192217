import { connect } from 'react-redux'
import { UpdateStates } from '#/store/actions/ui'
import { SignOut } from '#/store/actions/user'
import { currentRole, isAgent } from '#/store/selectors/user'
import AccountMenu from './AccountMenu'

const mapStateToProps = (state: TopHap.StoreState) => ({
  currentRole: currentRole(state, false),
  isAgent: isAgent(state),
  isMobile: state.global.isMobile.any,
  userProvider: state.user.providerId,
  ownerId: state.user.paymentInfo?.ownerId,
  allowedDirectGuests: state.user.permissions?.allowedDirectGuests
})

const mapDispatchToProps = {
  showShare: () => UpdateStates({ share: true }),
  signOut: SignOut
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu)
