import { useRouter } from 'next/router'
import { useEffect, useRef, useState, useMemo } from 'react'
// import { hash2MapUrl } from '#/utils/url'
import { useIntercom } from 'react-use-intercom'
import SvgArrow from '#/assets/images/icons/arrow-circle-o-right.svg'
import SvgFacebook from '#/assets/images/landing/facebook.svg'
import SvgInstagram from '#/assets/images/landing/instagram.svg'
import SvgLinkedin from '#/assets/images/landing/linkedin.svg'
import SvgTwitter from '#/assets/images/landing/twitter.svg'
import SvgYoutube from '#/assets/images/landing/youtube.svg'
import SvgLogo from '#/assets/images/logos/tophap-logo.svg'
import Avatar from '#/components/Avatar'
import Link from '#/components/Link'
import Popover from '#/components/Popover'
import { MAP_PAGE, WFG_PAGE } from '#/consts/url'
import { logEvent } from '#/services/analytics'
import cn from '#/utils/classnames'
// import { initialState as initPreferences } from '#/store/reducers/preferences'
import { authUrl, urlWithQuery } from '#/utils/url'
import { DropMenu, LinkMenuItem } from './Menu'
import AccountMenu from './Menu/AccountMenu'
import HelpMenu from './Menu/HelpMenu'
import styles from './styles.module.css'
import type { HeaderProps } from './types'

const LINKS: {
  title: string,
  path?: string,
  mobile?: boolean,
  subItems?: {
    title: string,
    path?: string,
    action?: string
    mobile?: boolean
  }[]
}[] = [
  {
    title: 'Products',
    subItems: [
      {
        title: 'Analytics Studio',
        path: '/products/tophap-studio'
      },
      { title: 'Data Fusion', action: 'showFeedback' },
      { title: 'Dynamic Reports', action: 'showFeedback' },
      { title: 'Embedded Experience', action: 'showFeedback' },
      { title: 'TopHap API', action: 'showFeedback' },
      {
        title: 'TopHap Explorer',
        path: 'https://apps.apple.com/us/app/tophap-explorer/id1551845829'
      },
      { title: 'Portfolio Manager', action: 'showFeedback' },
      { title: 'Lot Analytics', action: 'showFeedback' },
      { title: 'Compare Explorer', action: 'showFeedback' }
    ]
  // }, {
  //   title: 'Solutions',
  //   subItems: [
  //     { title: 'Real Estate Research' },
  //     { title: 'Portfolio Monitoring' },
  //     { title: 'TopHap Valuation Tools' },
  //     { title: 'Real Estate Maps' },
  //     { title: 'Geospatial Data Visualization' },
  //     { title: 'Data Widgets' },
  //     { title: 'Data API' },
  //     { title: 'White-label Solutions' },
  //     { title: 'TopHap CMA' },
  //     { title: 'Property and Regional Comparison' }
  //   ]
  // }, {
  //   title: 'Inustries',
  //   subItems: [
  //     { title: 'Real Estate' },
  //     { title: 'Banking' },
  //     { title: 'Mortgage' },
  //     { title: 'Insurance' },
  //     { title: 'Home Services' },
  //     { title: 'Technology Platforms' },
  //     { title: 'Data Collection' },
  //     { title: 'Business Consulting' },
  //     { title: 'Marketing List Brokers' },
  //     { title: 'Government' },
  //     { title: 'Construction' },
  //     { title: 'Manufacturer' },
  //     { title: 'IoT' }
  //   ]
  }, { title: 'Pricing', path: '/pricing', mobile: false }, {
    title: 'Resources',
    subItems: [
      { title: 'Academy', path: '/academy' },
      { title: 'Blog', path: '/blog' },
      { title: 'What\'s new', path: '/whats-new' },
      { title: 'Pricing', path: '/pricing', mobile: true },
      { title: 'FAQ', path: '/faq' },
      { title: 'Sitemap', path: '/sitemap/usa' }
    ]
  }, {
    title: 'Company',
    subItems: [
      { title: 'About Us', path: '/company' },
      { title: 'Contact Us', action: 'showIntercom' },
      { title: 'News', path: '/news' },
      { title: 'Careers', path: '/careers' },
      { title: 'Privacy Policy', path: '/policy' },
      { title: 'Terms of Use', path: '/terms' }
    ]
  }
]

export default function Header({
  isMobile,
  user,
  transparentable,
  showFeedback
}: HeaderProps) {
  const { show } = useIntercom()
  const [menuOpened, showMenu] = useState(false)
  // const [isTop, setTop] = useState(true)
  const isTop = false
  const refMenu = useRef<HTMLDivElement>(null)
  const router = useRouter()

  const showNavigation = useMemo(() => router.pathname !== WFG_PAGE, [router.pathname])

  // useEffect(() => {
  //   if (transparentable) {
  //     window.addEventListener('scroll', onScroll)

  //     return () => {
  //       window.removeEventListener('scroll', onScroll)
  //     }
  //   }
  // }, [transparentable])

  useEffect(() => {
    document.body.classList.toggle('th-disable-scroll', menuOpened)
  }, [menuOpened])

  // function onScroll() {
  //   if (window.pageYOffset < 80) {
  //     setTop(true)
  //   } else {
  //     setTop(false)
  //   }
  // }

  function toggleMenu() {
    showMenu(!menuOpened)
    if (!menuOpened) {
      logEvent('select_content', {
        content_type: 'header',
        item_id: 'main_menu_button'
      })
    }
  }

  function closeMenu(e: any) {
    if (e.target && refMenu.current && refMenu.current.contains(e.target)) {
      return
    }

    showMenu(false)
  }

  // function onDescriptiveMetric(metric: string) {
  //   return function(e: any) {
  //     const hash = {
  //       d: {
  //         ...initPreferences.map.descriptive,
  //         metric
  //       }
  //     }
  //     router.push(MAP_PAGE, hash2MapUrl(hash))

  //     closeMenu(e)
  //   }
  // }

  // function onNewConstruction(ev: React.MouseEvent<HTMLElement, MouseEvent>) {
  //   const currentYear = new Date().getFullYear()
  //   const hash = {
  //     f: { year_built: { min: currentYear - 2 } },
  //     p: {
  //       ...initPreferences.map.properties,
  //       enabled: true
  //     },
  //     sv: true
  //   }
  //   router.push(MAP_PAGE, hash2MapUrl(hash))

  //   closeMenu(ev)
  // }

  // function onRecentlySold(ev: React.MouseEvent<HTMLElement, MouseEvent>) {
  //   const hash = {
  //     f: { status: { close_date: { min: 'now-1y/d' }, values: ['Sold'] } },
  //     p: {
  //       ...initPreferences.map.properties,
  //       enabled: true
  //     },
  //     sv: true
  //   }
  //   router.push(MAP_PAGE, hash2MapUrl(hash))

  //   closeMenu(ev)
  // }

  // function onAllProperties(ev: React.MouseEvent<HTMLElement, MouseEvent>) {
  //   const hash = {
  //     p: {
  //       ...initPreferences.map.properties,
  //       enabled: true
  //     },
  //     sv: true
  //   }
  //   router.push(MAP_PAGE, hash2MapUrl(hash))

  //   closeMenu(ev)
  // }

  function onLogo() {
    logEvent('select_content', { content_type: 'header', item_id: 'logo' })
  }

  function handleMenuItemClick(
    ev: React.MouseEvent | React.TouchEvent,
    item: string
  ) {
    closeMenu(ev)

    logEvent('select_content', { content_type: 'header', item_id: item })
  }

  function renderMenuButton() {
    return (
      <div
        ref={refMenu}
        className={cn(
          'hamburger hamburger--spin lg:hidden w-14 h-14 ml-1 -mr-4 grid place-items-center',
          { 'is-active': menuOpened }
        )}
        onClick={toggleMenu}
      >
        <span className='hamburger-box'>
          <span className='hamburger-inner' />
        </span>
      </div>
    )
  }

  function renderMenu() {
    const menu = (
      <div className={cn(styles['th-header-menu'], 'flex flex-col items-center flex-grow shrink-0 p-6 select-none bg-header sm:rounded overflow-y-scroll')}>
        <div className='w-72 grow p-6'>{renderLinks('click')}</div>
        <div className='mb-1 grid w-72 place-items-center space-y-5 p-6 sm:hidden'>
          {renderAuthActions()}
        </div>

        <hr className='w-11/12 border-white border-opacity-40' />

        <div className='mt-5 text-center'>
          <p className='mb-2.5 text-xs text-white text-opacity-50'>
            ©
            {' '}
            {new Date().getFullYear()}
            {' '}
            TopHap. All rights reserved.
          </p>
          <div>
            <Link
              className='mx-3 text-xs text-white text-opacity-50'
              href='/terms'
            >
              Terms of Use
            </Link>
            <Link
              className='mx-3 text-xs text-white text-opacity-50'
              href='/policy'
            >
              Privacy Policy
            </Link>
          </div>
          <div className='mt-2.5'>
            <a
              className={styles['th-social-icon']}
              href='https://www.youtube.com/channel/UCglZdqeNJxER07DigcKhgzg/'
              rel='noopener noreferrer'
              target='_blank'
              title='Youtube'
            >
              <SvgYoutube />
            </a>
            <a
              className={styles['th-social-icon']}
              href='https://twitter.com/tophapinc'
              rel='noopener noreferrer'
              target='_blank'
              title='Twitter'
            >
              <SvgTwitter />
            </a>
            <a
              className={styles['th-social-icon']}
              href='https://www.facebook.com/tophapinc'
              rel='noopener noreferrer'
              target='_blank'
              title='Facebook'
            >
              <SvgFacebook />
            </a>
            <a
              className={styles['th-social-icon']}
              href='https://www.linkedin.com/company/tophap'
              rel='noopener noreferrer'
              target='_blank'
              title='LinkedIn'
            >
              <SvgLinkedin />
            </a>
            <a
              className={styles['th-social-icon']}
              href='https://www.instagram.com/tophapinc'
              rel='noopener noreferrer'
              target='_blank'
              title='Instagram'
            >
              <SvgInstagram />
            </a>
          </div>
        </div>
      </div>
    )

    return (
      <Popover
        className={cn(
          styles['th-header-menu-container'],
          'fixed inset-x-0 bottom-0 overflow-auto scale-100 sm:inset-x-auto sm:bottom-auto sm:w-80 sm:top-16 sm:right-2 transform sm:scale-90 lg:hidden flex flex-col opacity-0 transition',
          { 'opacity-100 scale-100': menuOpened }
        )}
        expanded={menuOpened}
        onClickOutside={closeMenu}
        onScroll={e => e.stopPropagation()}
      >
        <div className={cn('th-triangle-up', 'hidden ml-auto mr-4 sm:block')} />
        {menu}
      </Popover>
    )
  }

  function renderLinks(preferMode: 'hover' | 'click' = 'hover') {
    const mode = isMobile ? 'click' : preferMode
    if (!showNavigation) return null

    return (
      <>
        <div className='hidden sm:block'>
          {LINKS.map(group => {
            if (group.subItems) {
              return (
                <DropMenu
                  key={group.title}
                  classes={{
                    root: styles['th-menu-item'],
                    content: 'z-50'
                  }}
                  mode={mode}
                  title={group.title}
                >
                  {group.subItems.map(item => {
                    if (item.action === 'showIntercom') {
                      return (
                        <DropMenu.Item key={group.title + '' + item.title} onClick={show}>
                          {item.title}
                        </DropMenu.Item>
                      )
                    }
                    if (item.action === 'showFeedback') {
                      return (
                        <DropMenu.Item
                          key={group.title + '' + item.title}
                          onClick={() => showFeedback({
                            visible: true,
                            product: item.title as TopHap.UIState['feedback']['product']
                          })}
                        >
                          {item.title}
                        </DropMenu.Item>
                      )
                    }
                    return (
                      <LinkMenuItem
                        key={group.title + '' + item.title}
                        href={item.path || '/'}
                        onClick={ev => handleMenuItemClick(ev, 'academy_page_link')}
                      >
                        <DropMenu.Item>
                          {item.title}
                        </DropMenu.Item>
                      </LinkMenuItem>
                    )
                  })}
                </DropMenu>
              )
            }
            if (group.mobile === false) {
              return (
                <LinkMenuItem
                  key={group.title}
                  className={styles['th-menu-item']}
                  href={group.path || '/'}
                  onClick={ev => handleMenuItemClick(ev, group.path || '/')}
                >
                  {group.title}
                </LinkMenuItem>
              )
            }
            return null
          })}
        </div>

        <div className='sm:hidden'>
          {LINKS.map(group => {
            if (group.subItems) {
              return (
                <ul key={group.title}>
                  <li className={cn(styles['th-menu-item'], '!text-[#57ee47]')}>{group.title}</li>
                  {group.subItems.map(item => {
                    if (item.action === 'showIntercom') {
                      return (
                        <li
                          key={group.title + '' + item.title}
                          className={cn(styles['th-menu-item'], 'ml-4')}
                          onClick={show}
                        >
                          {item.title}
                        </li>
                      )
                    }
                    return (
                      <LinkMenuItem
                        key={group.title + '' + item.title}
                        className={cn(styles['th-menu-item'], 'ml-4')}
                        href={item.path || '/'}
                        onClick={ev => handleMenuItemClick(ev, item.path || '/')}
                      >
                        {item.title}
                      </LinkMenuItem>
                    )
                  })}
                </ul>
              )
            }
            if (group.mobile === true) {
              return (
                <LinkMenuItem
                  key={group.title}
                  className={styles['th-menu-item']}
                  href={group.path || '/'}
                  onClick={ev => handleMenuItemClick(ev, group.path || '/')}
                >
                  {group.title}
                </LinkMenuItem>
              )
            }
            return null
          })}

          {/* <LinkMenuItem
          className={styles['th-menu-item']}
          href='/academy'
          onClick={ev => handleMenuItemClick(ev, 'academy_page_link')}
        >
          Academy
        </LinkMenuItem>
        <LinkMenuItem
          className={styles['th-menu-item']}
          href='/faq'
          onClick={ev => handleMenuItemClick(ev, 'faq_page_link')}
        >
          FAQ
        </LinkMenuItem>
        <LinkMenuItem
          className={styles['th-menu-item']}
          href='/news'
          onClick={ev => handleMenuItemClick(ev, 'news_page_link')}
        >
          News
        </LinkMenuItem>
        <LinkMenuItem
          className={styles['th-menu-item']}
          href='/blog'
          onClick={ev => handleMenuItemClick(ev, 'blog_page_link')}
        >
          Blog
        </LinkMenuItem>
        <LinkMenuItem
          className={styles['th-menu-item']}
          href='/pricing'
          onClick={ev => handleMenuItemClick(ev, 'pricing_page_link')}
        >
          Pricing
        </LinkMenuItem>
        <LinkMenuItem
          className={styles['th-menu-item']}
          href='/company'
          onClick={ev => handleMenuItemClick(ev, 'company_page_link')}
        >
          Company
        </LinkMenuItem>
        <LinkMenuItem
          className={styles['th-menu-item']}
          href='/gallery'
          onClick={ev => handleMenuItemClick(ev, 'gallery_page_link')}
        >
          Gallery
        </LinkMenuItem> */}
        </div>
      </>
    )
  }

  function renderAuthActions() {
    const signUpProps = router.pathname === WFG_PAGE ?
      urlWithQuery(router, { auth: 'signup', pn: 'wfg' }) :
      { href: MAP_PAGE }

    if (user.isAnonymous === false) {
      return (
        <Link
          className={cn(styles['th-action-button'], 'w-full sm:w-28')}
          href='/map'
          rel='nofollow'
          onClick={ev => handleMenuItemClick(ev, 'map_page_button')}
        >
          Go to Map
        </Link>
      )
    } else {
      return (
        <>
          <Link
            {...authUrl(router, 'signin')}
            className={cn(styles['th-action-button'], 'w-52 sm:w-28')}
            onClick={ev => handleMenuItemClick(ev, 'signin_button')}
          >
            Sign In
          </Link>

          <Link
            className={cn(styles['th-action-button'], 'w-52 sm:w-36 bg-secondary')}
            {...signUpProps}
            rel='nofollow'
            onClick={ev => handleMenuItemClick(ev, 'signup_button')}
          >
            <span className='mr-1'>{router.pathname === WFG_PAGE ? 'Sign Up' : 'Sign Up Free'}</span>
            <SvgArrow />
          </Link>
        </>
      )
    }
  }

  function renderUserAvatar() {
    if (user.isAnonymous !== false) return null

    return (
      <AccountMenu
        classes={{
          content: 'top-full right-0 mt-1',
          trigger: 'rounded-full m-0 p-0'
        }}
        eventCategory='header'
        title={(
          <Avatar
            className='h-10 w-10'
            name={user.displayName}
            src={user.photoUrl}
          />
        )}
      />
    )
  }

  const transparent = transparentable && !menuOpened && isTop

  return (
    <header
      className={cn(
        'th-header',
        styles['th-header'],
        'fixed inset-x-0 top-0 px-5 flex items-center justify-between',
        transparent ? 'bg-transparent' : 'bg-header'
      )}
    >
      <Link className='mr-6' href='/' shallow onClick={onLogo}>
        <SvgLogo className={cn(styles['th-logo'], 'text-white')} />
      </Link>

      <nav className='hidden lg:block'>{renderLinks()}</nav>
      <div className='grow' />

      <div className='flex items-center space-x-4'>
        <div className='hidden sm:flex sm:items-center sm:space-x-4'>
          {renderAuthActions()}
        </div>
        {renderUserAvatar()}
        <HelpMenu
          classes={{
            content: 'top-full right-0 mt-1',
            trigger: 'rounded-full m-0 p-0 bg-primary h-10 w-10 font-medium text-lg text-white grid place-items-center'
          }}
          eventCategory='header'
          onItem={closeMenu}
        />
      </div>
      {renderMenuButton()}
      {renderMenu()}
    </header>
  )
}
