import { createReducer } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import * as Actions from '#/store/actions/workspace'
import { failure, request, success } from '#/utils/action'
import { setIn } from '#/utils/object'

export const initialState: TopHap.WorkspaceState = {
  favorites: {},
  hidden: {},
  properties: {
    favorites: [],
    hidden: []
  },
  reports: []
}

export const commonSuccess = (
  state: TopHap.WorkspaceState,
  action: AnyAction
) => ({
  ...state,
  ...action.payload
})

export const updateList = (
  state: TopHap.WorkspaceState,
  action: ReturnType<typeof Actions.UpdateList>
) => {
  const { listId, updates } = action.payload
  const list = state[listId]
  const properties = state.properties

  for (const id in updates) {
    if (updates[id].status === 'created') {
      // if it is 'creating', new unlike request is following
      if (list[id]?.status !== 'deleting') {
        list[id] = updates[id] as TopHap.WorkspaceListItem
      }
    } else if (updates[id].status === 'deleted') {
      // if it is 'creating', new like request is following
      if (list[id]?.status !== 'creating') {
        properties[listId] = properties[listId].filter(e => e.id !== list[id]?.propertyId)
        list[id] = undefined
      }
    }
  }
}

export const addToListRequest = (
  state: TopHap.WorkspaceState,
  action: AnyAction
) => {
  const { userId, propertyId, listId } = action.payload
  return setIn(state, `${listId}.${propertyId}-${userId}`, {
    propertyId,
    uid: userId,
    status: 'creating'
  })
}

export const addToListFailure = (
  state: TopHap.WorkspaceState,
  action: AnyAction
) => {
  const { userId, propertyId, listId } = action.payload
  return setIn(state, `${listId}.${propertyId}-${userId}`, undefined)
}

export const removeFromListRequest = (
  state: TopHap.WorkspaceState,
  action: AnyAction
) => {
  const { userId, propertyId, listId } = action.payload
  const newState = setIn(state, `${listId}.${propertyId}-${userId}.status`, 'deleting')
  return setIn(newState, `properties.${listId}`, state.properties[listId as 'favorites' | 'hidden'].filter(e => e.id !== propertyId))
}

export const removeFromListFailure = (
  state: TopHap.WorkspaceState,
  action: AnyAction
) => {
  const { userId, propertyId, listId } = action.payload
  return setIn(state, `${listId}.${propertyId}-${userId}.status`, 'created')
}

export const listPropertiesSuccess = (
  state: TopHap.WorkspaceState,
  action: AnyAction
) => {
  const { listId, properties } = action.payload
  return setIn(state, `properties.${listId}`, properties)
}

export const updateReports = (
  state: TopHap.WorkspaceState,
  action: ReturnType<typeof Actions.UpdateReports>
) => {
  state.reports = action.payload
}

export default createReducer(initialState, {
  [Actions.UpdateStates.type]: commonSuccess,
  [Actions.UpdateList.type]: updateList,
  [Actions.UpdateReports.type]: updateReports,
  [request(Actions.AddToList)]: addToListRequest,
  [failure(Actions.AddToList)]: addToListFailure,
  [request(Actions.RemoveFromList)]: removeFromListRequest,
  [failure(Actions.RemoveFromList)]: removeFromListFailure,
  [success(Actions.ListProperties)]: listPropertiesSuccess
})
