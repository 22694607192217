import cookie from 'js-cookie'
import LinkedInTag from 'react-linkedin-insight'
import { AnyAction } from 'redux'
import { liConversationId, stage } from '#/configs'
import api from '#/configs/api'
import { BREAK_SM, TH_USER_ID } from '#/consts'
import { id2Plan, Plan } from '#/consts/plan'
import {
  CMA_PAGE,
  COMPARE_PAGE,
  MAP_PAGE,
  PROPERTY_DETAIL_PAGE,
  SITEMAP_PAGE
} from '#/consts/url'
import { AddPromotion, CreateSubscription, SignIn, SignInWithFacebook, SignInWithGoogle, SignUp, UpdateSubscription } from '#/store/actions/user'
import { store } from '#/store/setup'
import { success } from '#/utils/action'
import intercomApi from '#/utils/intercomApi'
import invokeApi from '#/utils/invokeApi'

export function logEventToES(
  action: string,
  payload?: Record<string, unknown>
) {
  if (typeof window === 'undefined') return
  if (!store) return

  const state = store.getState()
  const user = state.user.info
  const permissions = state.user.permissions

  invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.logEvent,
    method: 'POST',
    body: {
      data: btoa(
        encodeURIComponent(
          JSON.stringify({
            action,
            payload,
            window: {
              width: window.innerWidth,
              height: window.innerHeight
            },
            url: {
              full: window.location.href,
              scheme: window.location.protocol.replace(':', ''),
              domain: window.location.hostname,
              path: window.location.pathname,
              fragment: window.location.hash ?
                window.location.hash.replace('#', '') :
                undefined,
              query: window.location.search ?
                window.location.search.replace('?', '') :
                undefined
            },
            user: {
              email: user.email || '',
              displayName: user.displayName || '',
              username: user.username || '',
              uid: user.uid || cookie.get(TH_USER_ID),
              partner: permissions ? permissions.partner || '' : ''
            },
            referrer: document.referrer
          })
        )
      )
    }
  })
    // eslint-disable-next-line
    .then(res => { })
    .catch(console.error)
}

export const logEvent: TopHap.Event.LogEvent = (
  type: TopHap.Event.Type,
  params: any
) => {
  datalayer(type, params)
  logEventToES(type, params)

  if (type === 'share') {
    intercomApi('trackEvent', 'share', { type: params.content_type })
  } else if (type === 'select_item') {
    if (params.item_list_id === 'app_mode') {
      intercomApi('trackEvent', 'app_mode', { type: params.selection_detail })
    } else if (params.item_list_id === 'analytics_layer') {
      intercomApi('trackEvent', 'analytics_layer_selected', { layer_name: params.selection_detail })
    }
  } else if (type === 'search') {
    intercomApi('trackEvent', 'search', { type: params.search_category })
  } else if (type === 'begin_checkout') {
    intercomApi('trackEvent', 'begin_checkout', { planInfo: params.items })
  }
}

export const datalayer = (
  type: TopHap.Event.Type,
  params: any
) => {
  if (typeof window === 'undefined') return

  const state = store && store.getState()
  const { info, paymentInfo } = state.user

  if (window.dataLayer) {
    if (info) {
      window.dataLayer.push({
        user_properties: {
          displayName: info.displayName || '',
          email: info.email || '',
          phoneNumber: info.phoneNumber || '',
          username: info.username || '',
          uid: info.uid || cookie.get(TH_USER_ID),
          job_role: info.job || '',
          role: paymentInfo ? paymentInfo.role : 'unknown',
          invite_pending: info.invitations ?
            Object.keys(info.invitations).length :
            0,
          invite_accepted: info.guests ? Object.keys(info.guests).length : 0
        }
      })
    }

    window.dataLayer.push({
      event: type,
      eventModel: params
    })
  }
}

export function getScreenNameInMapPage(state: TopHap.StoreState) {
  if (state.ui.sider.visible) {
    if (state.ui.sider.properties.mode === 'List') {
      return 'map/list'
    } else {
      return 'map/detail'
    }
  } else {
    return 'map/map'
  }
}

function getScreenName(pathname: string) {
  if (pathname === MAP_PAGE) {
    const state: TopHap.StoreState = store.getState()
    if (state.ui.viewport.width > BREAK_SM) {
      return 'map'
    } else {
      return getScreenNameInMapPage(state)
    }
  }
  if (pathname === COMPARE_PAGE) return 'compare'
  if (pathname === CMA_PAGE) return 'cma'
  if (pathname === PROPERTY_DETAIL_PAGE) return 'property_detail'
  if (pathname === SITEMAP_PAGE) return 'sitemap'
  return pathname.slice(1).replace(/\//g, '_')
}

export function trackPageView(pathname: string, url: string) {
  if (typeof window === 'undefined') return

  try {
    if (window.dataLayer) {
      window.dataLayer.push({ config: { page_location: url } })
    }
    intercomApi('trackEvent', 'location_change', { page_location: url })
    logEvent('screen_view', { screen_name: getScreenName(pathname) })
  } catch (err: any) {
    console.error(err)
  }
}

function logCancelSubscription() {
  logEvent('select_content', {
    content_type: 'billing',
    item_id: 'subscription/cancel'
  })
  intercomApi('trackEvent', 'cancel_subscription')
}

function logSignUp(
  method: 'google' | 'facebook' | 'email',
  invited_by?: string
) {
  logEvent('sign_up', { method, invited_by })
  intercomApi('trackEvent', 'sign_up', { method, invited_by })
  if (stage === 'PRODUCTION') LinkedInTag.track(liConversationId)
}

export default function analytics(action: AnyAction) {
  if (typeof window === 'undefined') return

  switch (action.type) {
    case success(SignIn):
      logEvent('login', { method: 'email' })
      break
    case success(SignInWithGoogle):
      if (action.payload.isFirstSignIn) {
        logSignUp('google')
      } else {
        logEvent('login', { method: 'google' })
      }
      break
    case success(SignInWithFacebook):
      if (action.payload.isFirstSignIn) {
        logSignUp('facebook')
      } else {
        logEvent('login', { method: 'facebook' })
      }
      break
    case success(SignUp):
      logSignUp('email', action.payload.invitedBy)
      break
    case success(AddPromotion): {
      const discount: TopHap.Promotion = action.payload.customer.discount
      logEvent('select_promotion', {
        promotion_id: discount.coupon.id,
        promotion_name: discount.coupon.name,
        promotion_percent: discount.coupon.percent_off,
        promotion_amount: discount.coupon.amount_off
      })
      break
    }
    case success(CreateSubscription):
      {
        const paymentInfo: TopHap.Payment = action.payload.paymentInfo
        const plan = id2Plan(action.payload.paymentInfo.plan) as Plan

        logEvent('complete_checkout', {
          transaction_id: paymentInfo.subscription as string,
          currency: 'USD',
          sub_id: plan.id,
          sub_name: plan.name + ' ' + plan.period,
          value: plan.monthlyPrice,
          items: [
            {
              item_id: plan.id,
              item_name: plan.name + ' ' + plan.period,
              quantity: 1,
              price: plan.monthlyPrice
            }
          ]
        })
        intercomApi('trackEvent', 'new_subscription', {
          price: {
            currency: 'usd',
            amount: plan.monthlyPrice
          },
          sub_name: plan.name + ' ' + plan.period
        })
      }
      break
    case success(UpdateSubscription):
      {
        const paymentInfo: TopHap.Payment = action.payload.paymentInfo
        const plan = id2Plan(action.payload.paymentInfo.plan)

        if (plan) {
          logEvent('update_subscription', {
            transaction_id: paymentInfo.subscription as string,
            currency: 'USD',
            sub_id: plan.id,
            sub_name: plan.name + ' ' + plan.period,
            value: plan.monthlyPrice,
            items: [
              {
                item_id: plan.id,
                item_name: plan.name + ' ' + plan.period,
                quantity: 1,
                price: plan.monthlyPrice
              }
            ]
          })
          intercomApi('trackEvent', 'update_subscription', {
            price: {
              currency: 'usd',
              amount: plan.monthlyPrice
            },
            sub_name: plan.name + ' ' + plan.period
          })
        } else {
          logCancelSubscription()
        }
      }
      break
    // case success(UserTypes.CANCEL_SUBSCRIPTION):
    //   logCancelSubscription()
    //   break
    default:
      break
  }
}
