export default function mergeArray(a1: any[], a2: any[], id: string) {
  const len1 = a1.length

  for (let x = 0; x < a2.length; x++) {
    let found = false

    for (let y = 0; y < len1; y++) {
      if (a2[x][id] === a1[y][id]) {
        found = true
        break
      }
    }

    if (!found) {
      a1.push(a2.splice(x--, 1)[0])
    }
  }

  return a1
}

export function mergeObjectArray(a1: any[], a2: any[], key: string) {
  let i = 0,
    j = 0
  const merged = []

  while (i < a1.length || j < a2.length) {
    if (a2[j]) {
      if (a1[i]) {
        if (a1[i][key] === a2[j][key]) {
          merged.push({
            ...a1[i],
            ...a2[j]
          })
          ++i
          ++j
        } else if (a1[i][key] < a2[j][key]) {
          merged.push(a1[i])
          ++i
        } else {
          merged.push(a2[j])
          ++j
        }
      } else {
        return merged.concat(a2.slice(j))
      }
    } else {
      return merged.concat(a1.slice(i))
    }
  }

  return merged
}
