import { CacheProvider, EmotionCache } from '@emotion/react'
import { ThemeProvider } from '@mui/material/styles'
import { setUser as setSentryUser } from '@sentry/nextjs'
import cookie from 'js-cookie'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import React, { useEffect } from 'react'
import LinkedInTag from 'react-linkedin-insight'
import { Provider } from 'react-redux'
import { stage, facebookAppId, host, liPartnerId, googleAnalyticsId, googleTagManagerId, gtmServerContainer, stapeGTMId } from '#/configs'
import { TH_USER_ID } from '#/consts'
import { PDF_PAGES } from '#/consts/url'
import Main from '#/containers/Main'
import { wrapper } from '#/store/setup'
import theme from '#/styles/theme'
import '#/styles/tailwindcss/tailwind.css'
import '#/styles/google/ads.css'
import createEmotionCache from '#/utils/createEmotionCache'

const clientSideEmotionCache = createEmotionCache()

interface TopHapAppProps extends AppProps {
  emotionCache?: EmotionCache
}

const TopHapApp: React.FC<TopHapAppProps> = ({
  router,
  emotionCache = clientSideEmotionCache,
  ...rest
}) => {
  const { store, props } = wrapper.useWrappedStore(rest)
  useEffect(() => {
    initSentry()
  }, [])

  useEffect(() => {
    if (stage === 'PRODUCTION' && !router.pathname.startsWith(PDF_PAGES)) {
      LinkedInTag.init(liPartnerId)
    }
  }, [])

  function initSentry() {
    if (stage === 'DEVELOPMENT') return

    const userContext = { id: cookie.get(TH_USER_ID) }
    setSentryUser(userContext)
  }

  function scripts() {
    return (
      <>
        {/* Work around for the global style update performance issue. */}
        <Script id='head_insert_fix'>
          {`
          var insertBeforeRaw = document.head.insertBefore;
          document.head.insertBefore = function(a,b){if(a!==b) {return insertBeforeRaw.call(this,a,b)} else return null}
       `}
        </Script>
        {/* Global site tag (gtag.js) - Google Analytics */}
        <Script
          id='gtag-js'
          src={`https://${gtmServerContainer}/gtag/js?id=${googleAnalyticsId}`}
        />
        <Script id='google_analytics'>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${googleAnalyticsId}', {
          'send_page_view': false,
        })`}
        </Script>

        {/* Google Tag Manager */}
        <Script id='google_tag_manager'>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://${gtmServerContainer}/${stapeGTMId}.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${googleTagManagerId}');`}
        </Script>

        {/* Google Adsense */}
        <Script
          async
          crossOrigin='anonymous'
          data-ad-client='ca-pub-5720980775511339'
          id='Adsense-id'
          src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
        />

        {stage === 'PRODUCTION' && (
          <>
            {/* Profitwell */}
            <Script
              data-pw-auth='9bc4dde1f26fcc6416e3c73d33defe12'
              id='profitwell-js'
            >
              {`
                (function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)}; a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);})(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
              `}
            </Script>

            {/* Rewardful */}
            <Script id='rewardful-register'>
              {'(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,\'rewardful\');'}
            </Script>
            <Script data-rewardful='3f5ba8' id='rewardful-js' src='https://r.wdfl.co/rw.js' />
          </>
        )}
      </>
    )
  }

  const meta = {
    title: 'Real Estate Analytics Platform - TopHap',
    description:
      // 'The top real estate research platform for real estate analytics at scale. Invest with confidence and uncover hidden opportunities. Get started now!',
      'TopHap is a technology platform for geospatial data visualization and analysis with a focus on residential and commercial real estate. We provide innovative visualization tools, comprehensive data, and industry-specific solutions to individuals and enterprises.',
    image: `${host}/images/tophap.jpg`,
    url: host + router.asPath.split('?')[0]
  }

  const themedMain = (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Main router={router} {...props} />
      </Provider>
    </ThemeProvider>
  )

  if (router.pathname.startsWith(PDF_PAGES)) {
    return (
      <CacheProvider value={emotionCache}>
        {themedMain}
      </CacheProvider>
    )
  }

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title key='title'>{meta.title}</title>
        <meta
          key='description'
          content={meta.description}
          name='description'
        />
        <meta key='robots' content='index, follow' name='robots' />
        <meta key='thumbnail' content={meta.image} name='thumbnail' />
        <meta
          key='viewport'
          content='width=device-width, minimum-scale=1, initial-scale=1, shrink-to-fit=no'
          name='viewport'
        />

        <meta key='fb:app_id' content={facebookAppId} property='fb:app_id' />
        <meta key='og:title' content={meta.title} property='og:title' />
        <meta
          key='og:description'
          content={meta.description}
          property='og:description'
        />
        <meta key='og:type' content='website' property='og:type' />
        <meta key='og:url' content={meta.url} property='og:url' />
        <meta key='og:image' content={meta.image} property='og:image' />
        <meta key='og:site_name' content='TopHap' property='og:site_name' />

        <meta
          key='twitter:creator'
          content='@tophap_inc'
          name='twitter:creator'
        />
        <meta
          key='twitter:card'
          content='summary_large_image'
          name='twitter:card'
        />
        <meta
          key='twitter:description'
          content={meta.description}
          name='twitter:description'
        />
        <meta key='twitter:image' content={meta.image} name='twitter:image' />
        <meta key='twitter:site' content='@tophap_inc' name='twitter:site' />
        <meta key='twitter:title' content={meta.title} name='twitter:title' />

        <link href='/images/icons-512.png' rel='apple-touch-icon' />
        <link key='canonical' href={meta.url} rel='canonical' />
        <link href='/manifest.json' rel='manifest' />
        <link href='/favicon.ico' rel='icon' />

        <link href='https://firestore.googleapis.com' rel='preconnect' />
        <link href='https://api-iam.intercom.io' rel='preconnect' />
        <link href='https://widget.intercom.io' rel='preconnect' />
        <link href='https://js.intercomcdn.com' rel='preconnect' />
      </Head>

      {scripts()}

      <noscript>
        <iframe height='0' src={`https://staging-ss.tophap.com/ns.html?id=${googleTagManagerId}`} style={{ display: 'none', visibility: 'hidden' }} width='0' />
      </noscript>

      {themedMain}
    </CacheProvider>
  )
}

export default TopHapApp
