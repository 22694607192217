import { connect } from 'react-redux'
import { UpdateStates } from '#/store/actions/ui'
import { likesCount } from '#/store/selectors/workspace'
import SideMenu from './SideMenu'

const mapStateToProps = (state: TopHap.StoreState) => ({
  comparablesCount: state.compare.preferences.ids.length,
  cmaCount: state.cma.preferences.data.length,
  expanded: state.ui.isSideMenuExpanded,
  likesCount: likesCount(state, 'favorites'),
  siderMode: state.ui.siderMode,
  sider: state.ui.sider,
  user: state.user.info,
  viewportWidth: state.ui.viewport.width,
  paymentInfo: state.user.paymentInfo
})

const mapDispatchToProps = { updateUI: UpdateStates }

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu)
