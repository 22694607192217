import { createReducer } from '@reduxjs/toolkit'
import { get, set, difference, intersection } from 'lodash-es'
import { AnyAction } from 'redux'
import * as Actions from '#/store/actions/compare'
import { failure, success } from '#/utils/action'
import { setIn } from '#/utils/object'

export const initialState: TopHap.CompareState = {
  preferences: {
    primary: 0,
    excludes: {},
    type: 'Estimate',
    metric: 'Estimate',
    ids: [],
    dateOption: [0, 'y'],

    filter: {
      and: true,
      rental: false,
      filters: [{
        and: true,
        subfilters: []
      }]
    }
  },
  comparables: []
}

export const commonSuccess = (
  state: TopHap.CompareState,
  action: AnyAction
) => ({
  ...state,
  ...action.payload
})

export const setState = (
  state: TopHap.CompareState,
  action: ReturnType<typeof Actions.SetState>
) => {
  const { option, value, update } = action.payload
  return setIn(state, option, value, update)
}

export const setComparablesSuccess = (
  state: TopHap.CompareState,
  action: AnyAction
) => {
  const comparables: TopHap.Comparable[] = action.payload
  state.preferences.ids = comparables.map(e => e.place.id)
  state.comparables = comparables
}

export const addComparablesSuccess = (
  state: TopHap.CompareState,
  action: AnyAction
) => {
  const comparables: TopHap.Comparable[] = [...state.comparables, ...action.payload]
  const ids = intersection(state.preferences.ids, comparables.map(e => e.place.id))
  state.preferences.ids = ids
  state.comparables = ids.map(e =>
    comparables.find(comparable => comparable.place.id === e) as TopHap.Comparable)
}

export const addComparablesFailure = (
  state: TopHap.CompareState,
  action: AnyAction
) => {
  const { ids } = action.payload
  state.preferences.ids = difference(state.preferences.ids, ids)
}

export const removeComparable = (
  state: TopHap.CompareState,
  action: ReturnType<typeof Actions.RemoveComparable>
) => {
  state.comparables = state.comparables.filter(
    e => e.place.id !== action.payload.place.id
  )
}

export const updateFilterOption = (
  state: TopHap.CompareState,
  action: ReturnType<typeof Actions.UpdateFilterOption>
) => {
  const { metric, fields, value, operator } = action.payload
  const { filter } = state.preferences
  const groupIndex = 0
  let index = action.payload.index || 0
  if (metric) {
    filter.filters[groupIndex].subfilters.push({ metric })
    index = filter.filters[groupIndex].subfilters.length - 1
  }

  if ('value' in action.payload) {
    if (fields) {
      if (value && !filter.filters[groupIndex].subfilters[index].value) {
        filter.filters[groupIndex].subfilters[index].value = {}
      }
      if (typeof fields === 'string') {
        set(filter.filters[groupIndex].subfilters[index], ['value', fields], value)
      } else {
        fields.forEach(field => {
          set(filter.filters[groupIndex].subfilters[index], ['value', field], get(value, field))
        })
      }
    } else {
      set(filter.filters[groupIndex].subfilters[index], 'value', value)
    }
  }
  if ('operator' in action.payload) {
    set(filter.filters[groupIndex].subfilters[index], 'operator', operator)
  }
}

export default createReducer(initialState, {
  [Actions.SetState.type]: setState,
  [success(Actions.SetComparables)]: setComparablesSuccess,
  [success(Actions.AddComparables)]: addComparablesSuccess,
  [failure(Actions.AddComparables)]: addComparablesFailure,
  [success(Actions.UpdateComparables)]: setComparablesSuccess,
  [Actions.RemoveComparable.type]: removeComparable,
  [Actions.UpdateFilterOption.type]: updateFilterOption
})
