import { createAction } from '@reduxjs/toolkit'

export const SetState = createAction<{
  option: string
  value: any
  update?: boolean
}>('compare/set_state')
export const AddComparables = createAction<string[]>('compare/add_comparables')
export const SetComparables = createAction<string[]>('compare/set_comparables')
export const UpdateComparables = createAction('compare/update_comparables')
export const RemoveComparable = createAction<TopHap.Comparable>('compare/remove_comparable')
export const DownloadComparables = createAction('compare/download_comparables')
export const UpdateFilterOption = createAction<{
  index?: number
  metric?: TopHap.FilterMetric
  operator?: string
  value?: Partial<TopHap.FilterOption['value']>
  fields?: string | string[]
}>('compare/update_filter_option')
