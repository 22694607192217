import cookie from 'js-cookie'
import { TH_TOKEN, TH_USER_ID } from '#/consts'

export async function getToken() {
  if (typeof window !== 'undefined') {
    const { firebaseApp, getAuth } = await import('#/utils/firebase')
    const auth = getAuth(firebaseApp)
    if (auth.currentUser) return auth.currentUser.getIdToken(true)
  } else {
    return cookie.get(TH_TOKEN)
  }
}

export async function getUserId() {
  const cookieUserId = cookie.get(TH_USER_ID)
  if (cookieUserId) return cookieUserId
  else {
    if (typeof window !== 'undefined') {
      const { firebaseApp, getAuth } = await import('#/utils/firebase')
      const auth = getAuth(firebaseApp)
      if (auth.currentUser) return auth.currentUser.uid
    }
  }
}
