import { useMemo } from 'react'
import { role2Name } from '#/consts/plan'

export const useIntercomSettings = (
  user: TopHap.UserInfo,
  stats?: TopHap.UserStats,
  paymentInfo?: TopHap.Payment,
  permissions?: TopHap.UserPermissions
) =>
  useMemo(() => {
    let intercom: Record<string, unknown> = {}

    if (user.isAnonymous === false) {
      intercom = {
        email: user.email,
        job_role: user.job,
        name: user.displayName,
        user_id: user.uid,
        phone_number: user.phoneNumber,
        unsubscribed_from_emails: user.unsubscribed,
        referred_from: user.referredFrom,
        referred_from_description: user.referredFromDescription
      }

      if (permissions && permissions.partner) {
        intercom.partner = permissions.partner
      }

      if (paymentInfo) {
        intercom.role = role2Name(paymentInfo.role)
        intercom.trial_start_at = paymentInfo.trial_start_at
        intercom.trial_end_at = paymentInfo.trial_end_at
        intercom.tophap_owner_id = paymentInfo.ownerId
      }

      if (user.invitations || user.guests) {
        intercom.tophap_invite_pending_count = user.invitations ?
          Object.keys(user.invitations).length :
          0
        intercom.tophap_invite_accepted_count = user.guests ?
          Object.keys(user.guests).length :
          0
      }

      if (stats) {
        intercom.tophap_stats_searches = stats.search
        intercom.tophap_stats_views = stats.view
        intercom.tophap_stats_time_spent = stats.timeSpent
        intercom.tophap_stats_last_seen_at = stats.lastSeen / 1000
      }

      intercom.tophap_dre = user.portal?.DRE
      intercom.company = user.portal?.company
    }

    return intercom
  }, [user, stats, paymentInfo])
