import { configureStore, Store } from '@reduxjs/toolkit'
import lz from 'lz-string'
import { Context, createWrapper, MakeStore } from 'next-redux-wrapper'
import createSagaMiddleware, { Saga, SagaMiddleware } from 'redux-saga'
import reducer from './reducers'
import { getInitState as globalInitState } from './reducers/global'
import { getInitState as uiInitState } from './reducers/ui'
import { getInitState as userInitState } from './reducers/user'
import globalSaga from './sagas/global'
import initSaga from './sagas/init'

let store: Store<TopHap.StoreState> & {
  sagaMiddleware?: SagaMiddleware
  sagas?: Record<string, Saga>
}

const makeStore: MakeStore<Store<TopHap.StoreState>> = (context: Context) => {
  // construct initial state
  let initialState: TopHap.StoreState | undefined
  if (!initialState) {
    let ua,
      token
    if (typeof window !== 'undefined') {
      ua = navigator.userAgent
    } else if ('req' in context && context.req) {
      ua = context.req.headers['user-agent']
      token = context.req.headers.authorization as string
    }

    if (ua) {
      const global = globalInitState(ua)

      initialState = {
        global,
        ui: uiInitState(global.isMobile),
        user: userInitState(token)
      } as TopHap.StoreState
    }
  }

  const sagaMiddleware = createSagaMiddleware()

  store = configureStore({
    reducer,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({
        thunk: false,
        serializableCheck: false
      }),
      sagaMiddleware
    ],
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production'
  })

  store.sagaMiddleware = sagaMiddleware
  store.sagas = {}

  injectSaga('global', globalSaga)
  injectSaga('init', initSaga)

  // run sagas
  if (typeof window !== 'undefined') {
    import('./initialSagas')
      .then(({ default: initialSagas }) => {
        for (const key in initialSagas) {
          injectSaga(key, initialSagas[key])
        }
      })
  }

  return store
}

export const injectSaga = (key: string, saga: Saga, forSSR = false) => {
  if (typeof window === 'undefined' && !forSSR) return

  if (store.sagas && !store.sagas[key]) {
    store.sagas[key] = saga
    if (store.sagaMiddleware) {
      store.sagaMiddleware.run(saga)
    }
  }
}

export const wrapper = createWrapper<Store<TopHap.StoreState>>(makeStore, {
  serializeState: state => lz.compress(JSON.stringify(state)),
  deserializeState: (state: string) => {
    if (typeof state === 'string') {
      const decompressed = lz.decompress(state)
      if (decompressed) {
        return JSON.parse(decompressed)
      }
      return {}
    } else {
      return state
    }
  }
})
export { store }
