import { get } from 'lodash-es'

export const isInList = (state: TopHap.StoreState, propertyId: string, listId: 'favorites' | 'hidden') => {
  const userId = state.user.uid
  const workspace = get(state.workspace[listId], `${propertyId}-${userId}`)
  return !!workspace && workspace.status !== 'deleting'
}

export const listItems = (state: TopHap.StoreState, listId: 'favorites' | 'hidden') => {
  const userId = state.user.uid

  const data = (Object.values(state.workspace[listId])
    .filter(e => e && e.status !== 'deleting') as TopHap.WorkspaceListItem[])
    .filter(e => e.uid === userId)

  return data
}

export const likesCount = (state: TopHap.StoreState, listId: 'favorites' | 'hidden') => listItems(state, listId).length
