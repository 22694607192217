import { createAction } from '@reduxjs/toolkit'

export const UpdateStates = createAction<Partial<TopHap.WorkspaceState>>('workspace/update_states')
export const AddToList = createAction<{
  propertyId: string
  listId: string
  workspaceId: string
}>('workspace/add_to_list')
export const RemoveFromList = createAction<{
  propertyId: string
  listId: string
  workspaceId: string
}>('workspace/remove_from_list')
export const UpdateList = createAction<{
  listId: 'favorites' | 'hidden'
  updates: Record<
  string,
  TopHap.WorkspaceListItem | Pick<TopHap.WorkspaceListItem, 'status'>
  >
}>('workspace/update_list')
export const ListProperties = createAction<{
  listId: 'favorites' | 'hidden'
  reset?: boolean
}>('workspace/list_properties')
export const ClearList = createAction<{
  listId: string
  workspaceId?: string
}>('workspace/clear_list')
export const RequestReport = createAction<{
  payload: TopHap.Service.RequestReportRequest
  callback?:(err?: any) => void
  workspaceId?: string
}>('workspace/request_report')
export const UpdateReports = createAction<TopHap.WorkspaceReportItem[]>('workspace/update_reports')
