import ColorHash from 'color-hash'
import { useMemo } from 'react'
import cn from '#/utils/classnames'

const colorHash = new ColorHash({ lightness: 0.3, saturation: 0.3 })
interface AvatarProps {
  className?: string
  name?: string
  src?: string
}

export default function Avatar({ className, name, src }: AvatarProps) {
  const background = useMemo(() => colorHash.hex(name as string), [name])

  if (src) {
    return (
      <img
        alt='Avatar'
        className={cn('rounded-full w-10 h-10 object-cover', className)}
        src={src}
      />
    )
  } else {
    return (
      <div
        className={cn(
          'text-white rounded-full w-10 h-10 text-xl flex items-center justify-center',
          className
        )}
        style={{ background }}
      >
        {name ?
          name
            .split(' ')
            .slice(0, 2)
            .map(e => e[0])
            .join('') :
          ''}
      </div>
    )
  }
}
