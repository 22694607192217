import { createAction } from '@reduxjs/toolkit'

export const SetState = createAction<{
  option: string
  value: any
  update?: boolean
}>('cma/set_state')
export const AddComparables = createAction<string[]>('cma/add_comparables')
export const RemoveComparable = createAction<TopHap.BaseComparable>('cma/remove_comparable')
export const AddSubjectProperty = createAction<{
  property: string | TopHap.Property
  keep?: boolean
}>('cma/add_subject_property')
export const UpdateSubjectProperty = createAction<Partial<TopHap.Property>>('cma/update_subject_property')
