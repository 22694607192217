import type { AnyAction } from 'redux'
import { put } from 'redux-saga/effects'
import { cancelled } from 'typed-redux-saga'
import { failureCreator, requestCreator, successCreator } from '#/utils/action'

export function* actionBuilder(
  action: AnyAction,
  handler: (controller?: AbortController) => Generator<unknown>,
  errorPayload: Record<string, unknown> | ((err: any) => Record<string, unknown>) | null = {},
  requestPayload: Record<string, unknown> | null = {}
) {
  const abortController = typeof AbortController === 'undefined' ? undefined : new AbortController()
  try {
    yield put(requestCreator(action.type, requestPayload))
    const payload = yield* handler(abortController)
    yield put(successCreator(action.type, payload))
  } catch (err) {
    yield put(failureCreator(
      action.type,
      typeof (errorPayload) === 'function' ?
        errorPayload(err) :
        { err, ...errorPayload }
    ))
  } finally {
    if (yield* cancelled()) {
      abortController?.abort()
    }
  }
}
