import { UrlObject } from 'url'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import Button from '#/components/Button'
import cn from '#/utils/classnames'

export interface LinkProps {
  href: string | UrlObject
  as?: string | UrlObject | undefined
  prefetch?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  [eleName: string]: any
}

interface NavLinkProps extends LinkProps {
  activeClassName: string
  pathname?: string
}

const Link = NextLink
export default Link

export function LinkButton({
  href,
  as,
  shallow,
  ...props
}: LinkProps & { children: React.ReactNode }) {
  return (
    <NextLink as={as} href={href} shallow={shallow}>
      <Button {...props} />
    </NextLink>
  )
}

export function NavLink({
  activeClassName,
  className,
  href,
  pathname,
  ...props
}: NavLinkProps) {
  const router = useRouter()
  return (
    <Link
      {...props}
      className={cn(className, { [activeClassName]: router.pathname === (pathname || href) })}
      href={href}
    />
  )
}
