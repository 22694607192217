import { useIntercom } from 'react-use-intercom'
import SvgLogo from '#/assets/images/logos/logo-hexa.svg'
import SvgFacebook from '#/assets/images/social/facebook.svg'
import SvgInstagram from '#/assets/images/social/instagram.svg'
import SvgLinkedin from '#/assets/images/social/linkedin.svg'
import SvgTwitter from '#/assets/images/social/twitter.svg'
import SvgYoutube from '#/assets/images/social/youtube.svg'
import Link from '#/components/Link'
import { ShowFeedback } from '#/store/actions/ui'
import cn from '#/utils/classnames'
import styles from './styles.module.css'

const Footer: React.FC<{ showFeedback: typeof ShowFeedback }> = ({ showFeedback }) => {
  const { show } = useIntercom()
  const sectionTitleStyles = 'text-sm font-semibold tracking-[0.7px] text-primary uppercase'

  return (
    <footer aria-labelledby='footerHeading' className={cn(styles['th-footer'], 'container th-footer')}>
      <div className='max-w-8xl mx-auto py-12 lg:py-16'>
        <div className='xl:grid xl:grid-cols-4 xl:gap-8'>
          <div className='space-y-8 xl:col-span-1'>
            <Link className='flex' href='/'>
              <SvgLogo className='h-14 w-14' />
            </Link>
            <p className='text-sm text-gray-500'>
              Real Estate Intelligence Platform
            </p>
            <div className='flex space-x-6'>
              <a
                className='text-gray-400 hover:text-gray-500'
                href='https://www.facebook.com/tophapinc'
                rel='noopener noreferrer'
                target='_blank'
                title='Facebook'
              >
                <span className='sr-only'>Facebook</span>
                <SvgFacebook />
              </a>

              <a
                className='text-gray-400 hover:text-gray-500'
                href='https://www.instagram.com/tophapinc'
                rel='noopener noreferrer'
                target='_blank'
                title='Instagram'
              >
                <span className='sr-only'>Instagram</span>
                <SvgInstagram />
              </a>

              <a
                className='text-gray-400 hover:text-gray-500'
                href='https://twitter.com/tophapinc'
                rel='noopener noreferrer'
                target='_blank'
                title='Twitter'
              >
                <span className='sr-only'>Twitter</span>

                <SvgTwitter />
              </a>

              <a
                className='text-gray-400 hover:text-gray-500'
                href='https://www.linkedin.com/company/tophap'
                rel='noopener noreferrer'
                target='_blank'
                title='LinkedIn'
              >
                <span className='sr-only'>LinkedIn</span>
                <SvgLinkedin />
              </a>

              <a
                className='text-gray-400 hover:text-gray-500'
                href='https://www.youtube.com/channel/UCglZdqeNJxER07DigcKhgzg/'
                rel='noopener noreferrer'
                target='_blank'
                title='Youtube'
              >
                <span className='sr-only'>Youtube</span>
                <SvgYoutube />
              </a>
            </div>
          </div>
          <div className='mt-12 grid grid-cols-2 gap-8 md:col-span-3 md:grid-cols-3 xl:col-span-3 xl:mt-0'>
            <div>
              <span className={sectionTitleStyles}>Products</span>
              <ul className='mt-4 space-y-4'>
                <li>
                  <Link href='/products/tophap-studio' shallow>
                    Analytics Studio
                  </Link>
                </li>
                <li>
                  <button
                    onClick={() => showFeedback({
                      visible: true,
                      product: 'Data Fusion'
                    })}
                  >
                    Data Fusion
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => showFeedback({
                      visible: true,
                      product: 'Reports'
                    })}
                  >
                    Dynamic Reports
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => showFeedback({
                      visible: true,
                      product: 'Embedded-experience'
                    })}
                  >
                    Embedded Experience
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => showFeedback({
                      visible: true,
                      product: 'API'
                    })}
                  >
                    TopHap API
                  </button>
                </li>
                <li>
                  <Link href='https://apps.apple.com/us/app/tophap-explorer/id1551845829' rel='nofollow' target='_blank'>
                    TopHap Explorer
                  </Link>
                </li>
                <li>
                  <button
                    onClick={() => showFeedback({
                      visible: true,
                      product: 'Portfolio Manager'
                    })}
                  >
                    Portfolio Manager
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => showFeedback({
                      visible: true,
                      product: 'Lot Analytics'
                    })}
                  >
                    Lot Analytics
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => showFeedback({
                      visible: true,
                      product: 'Compare Explorer'
                    })}
                  >
                    Compare Explorer
                  </button>
                </li>
              </ul>
            </div>
            {/* <div>
              <span className={sectionTitleStyles}>Solutions</span>
              <ul className='mt-4 space-y-4'>
                <li>
                  <Link href='/' shallow>
                    Real Estate Research
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Portfolio Monitoring
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    TopHap Valuation Tools
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Real Estate Maps
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Geospatial Data Visualization
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Data Widgets
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Data API
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    White-label Solutions
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    TopHap CMA
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Property and Regional Comparison
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <span className={sectionTitleStyles}>Industries</span>
              <ul className='mt-4 space-y-4'>
                <li>
                  <Link href='/' shallow>
                    Real Estate
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Banking
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Mortgage
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Insurance
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Home Services
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Technology Platforms
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Data Collection
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Business Consulting
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Marketing List Brokers
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Financial Instituions
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Government
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Construction
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    Manufacturer
                  </Link>
                </li>
                <li>
                  <Link href='/' rel='nofollow'>
                    IoT
                  </Link>
                </li>
              </ul>
            </div> */}
            <div>
              <span className={sectionTitleStyles}>Resources</span>
              <ul className='mt-4 space-y-4'>
                <li>
                  <Link href='/academy'>Academy</Link>
                </li>
                <li>
                  <Link href='/blog'>Blog</Link>
                </li>
                <li>
                  <Link href='/whats-new'>What&apos;s New?</Link>
                </li>
                <li>
                  <Link href='/pricing'>Pricing</Link>
                </li>
                <li>
                  <Link href='/faq'>FAQ</Link>
                </li>
                <li>
                  <Link href='/sitemap/usa'>Sitemap</Link>
                </li>
                <li>
                  <div onClick={show}>Chat with Us</div>
                </li>
              </ul>
            </div>
            <div>
              <span className={sectionTitleStyles}>Company</span>
              <ul className='mt-4 space-y-4'>
                <li>
                  <Link href='/company'>About Us</Link>
                </li>
                <li>
                  <div onClick={show}>Contact Us</div>
                </li>
                <li>
                  <Link href='/news'>News</Link>
                </li>
                <li>
                  <Link href='/careers'>Careers</Link>
                </li>
                <li>
                  <Link href='/policy'>Privacy Policy</Link>
                </li>

                <li>
                  <Link href='/terms'>Terms of Use</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='mt-12 border-t border-gray-200 pt-8'>
          <p className='flex flex-col text-sm text-gray-400 sm:block xl:text-center'>
            &copy;
            {' '}
            {new Date().getFullYear()}
            {' '}
            TopHap. All rights reserved.
            <span className='ml-0 whitespace-nowrap sm:ml-5'>
              CA DRE #02068772
            </span>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
