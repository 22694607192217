import { createAction } from '@reduxjs/toolkit'

export const ResetProperties = createAction('properties/reset_properties')
export const GetProperties = createAction<{ resetMode?: 'RESET' | 'SWAP' }>('properties/get_properties')
export const GetMapData = createAction('properties/get_map_data')
export const DownloadProperties = createAction('properties/download_properties')

export const ResetElevations = createAction('properties/reset_elevations')
export const GetElevations = createAction<{ ids: string[] }>('properties/get_elevations')
export const ToggleElevation = createAction<{ id: string }>('properties/toggle_elevation')

export const GetBoundary = createAction<TopHap.Place[]>('properties/get_boundary')
export const SearchZones = createAction<{ isNew?: boolean }>('properties/get_zones')
export const GetPlaces = createAction<string[]>('properties/get_places')

export const GetNeighborhood = createAction<TopHap.Service.GetNeighborhoodRequest>('properties/get_neighborhood')

export const GetMlsInfo = createAction<TopHap.Property>('properties/get_mls_info')

export const UpdateStates = createAction<Partial<TopHap.PropertiesState>>('properties/update_states')
export const SetState = createAction<{
  option: string
  value: any
  update?: boolean
}>('properties/set_state')
