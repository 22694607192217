const apiConfigs = {
  baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL as string,
  baseV5Url: process.env.NEXT_PUBLIC_API_V5_BASE_URL as string,
  pdfBaseUrl: process.env.NEXT_PUBLIC_PDF_API_BASE_URL as string,
  previewBaseUrl: process.env.NEXT_PUBLIC_PREVIEW_API_BASE_URL as string,
  assetsBaseUrl: process.env.NEXT_PUBLIC_ASSETS_URL as string,
  imageProxyBaseUrl: process.env.NEXT_PUBLIC_IMAGE_PROXY_BASE_URL as string,
  widgetBaseUrl: process.env.NEXT_PUBLIC_WIDGET_API_BASE_URL as string,

  // TopHap API developed on Vercel (https://github.com/tophap/api)
  vercelTophapApi: process.env.NEXT_PUBLIC_VERCEL_TOPHAP_API as string,

  // TopHap Postgrest API
  postgrestBaseUrl: process.env.NEXT_PRIVATE_POSTGREST_BASE_URL as string,

  mapbox: 'https://api.mapbox.com',
  sitemapRoot: process.env.NEXT_PUBLIC_SITEMAP_ROOT_URL,
  users: {
    membership: 'users/membership',
    geoInfo: 'users/geoInfo', // GET
    sendFeedback: 'users/feedback',
    sendResume: 'users/resume',
    sendActionEmail: 'users/send_action_email',
    resetPassword: 'users/reset_password',
    confirmUser: 'users/verify_email',
    requestInfo: 'users/request_property_info',
    logEvent: 'users/events',
    createCustomer: 'users/payment/customers',
    getCustomer: 'users/payment/customers', // GET
    syncRemaxPaymentInfo: 'users/remax-sync-firebase-payment-info', // GET
    updateCustomer: 'users/payment/customers', // PUT
    deleteSource: 'users/payment/customers/source', // DELETE
    createSubscription: 'users/payment/subscription',
    updateSubscription: 'users/payment/subscription', // PUT
    deleteSubscription: 'users/payment/subscription', // DELETE
    typeformCancelFlow: 'users/typeform/cancel', // POST
    getInvoices: 'users/payment/invoices',
    getUpcomingInvoice: 'users/payment/invoices/upcoming', // GET
    addPromotion: 'users/payment/promotions',
    getPromotion: 'users/payment/promotions/{code}', // GET
    closeAccount: 'users/close', // DELETE,
    shareLink: 'users/share/links/{type}/{entityId}',
    getShareLink: 'users/share/links/{type}/{entityId}/{uid}/{branded}', // GET
    inviteGuest: 'users/invite',
    acceptInvitation: 'users/invite/accept',
    removeInvitation: 'users/invite', // [DELETE]
    getUserStats: 'users/user_stats',
    getCustomToken: 'users/custom_token' // GET
  },
  properties: {
    search: 'properties/search',
    get: 'properties/{id}', // GET
    mget: 'properties/mget',
    matchAddress: 'properties/match_address',
    exportCSV: 'properties/csv_data',
    getDetail: 'properties/detail',
    getAggregations: 'properties/aggregations',
    getNearbyStatistics: 'properties/nearby_statistics',
    getNeighborhood: 'properties/neighborhood',
    getElevations: 'properties/elevations/{ids}',
    getSchoolDetails: 'properties/school_details',
    getComparables: 'properties/comparables',
    getNames: 'properties/names',
    submitLead: 'properties/lead_submission'
  },
  geo: {
    getParcels: 'geo/parcels',
    getZones: 'geo/zones/{ids}',
    searchZones: 'geo/zones',
    searchAddresses: 'geo/addresses',
    getAddresses: 'geo/addresses/{ids}'
  },
  estimates: {
    forCompare: 'estimates/for_compare',
    forMarket: 'estimates/for_market',
    prediction: 'estimates/prediction',
    predictionBase: 'estimates/prediction_base'
  },
  previews: { cmaReport: 'pdf/compare' },
  workspace: {
    addToList: 'workspaces/{workspaceId}/lists/{listId}',
    removeFromList: 'workspaces/{workspaceId}/lists/{listId}', // DELETE
    clearList: 'workspaces/{workspaceId}/lists/{listId}/items', // DELETE
    requestReport: 'workspaces/{workspaceId}/report/request',
    getReport: 'workspaces/{workspaceId}/report/{reportId}'
  },
  zma: {
    fred: 'zma/fred/{id}',
    rental: 'zma/rental/{id}',
    census: 'zma/census',
    censusMsaList: 'zma/census-inventory',
    saveDocument: 'zma/document/save',
    getDocument: 'zma/report/get',
    listDocuments: 'zma/report/list',
    getDocumentData: 'zma/document/sign'
  }
}

export default apiConfigs
