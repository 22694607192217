import { useEffect } from 'react'
import { IntercomProvider } from 'react-use-intercom'
import { intercomId } from '#/configs'
import { useIntercomSettings } from '#/hooks/useIntercomSettings'
import intercomApi from '#/utils/intercomApi'

type Props = {
  user: TopHap.UserInfo
  stats?: TopHap.UserStats
  paymentInfo?: TopHap.Payment
  permissions?: TopHap.UserPermissions
  children?: React.ReactNode
  isBot?: boolean
}

const Provider: React.FC<Props> = ({ children, user, stats, paymentInfo, permissions, isBot }) => {
  const intercomSettings = useIntercomSettings(user, stats, paymentInfo, permissions)
  useEffect(() => {
    intercomApi('update', intercomSettings)
  }, [intercomSettings])

  const shouldInitialize = () => !isBot && typeof window !== undefined

  return (
    <IntercomProvider
      appId={intercomId}
      autoBoot
      autoBootProps={intercomSettings}
      shouldInitialize={shouldInitialize()}
    >
      {children}
    </IntercomProvider>
  )
}

export default Provider
