import { get } from 'lodash-es'
import { AnyAction } from 'redux'
import { put, takeEvery } from 'redux-saga/effects'
import { stage } from '#/configs'
import analytics, { logEvent } from '#/services/analytics'
import { UpdateAsyncActionStatus } from '#/store/actions/global'
import { originalType } from '#/utils/action'
import notification, { NotificationShowOption } from '#/utils/notification'

function errorMessage(err: any, path?: string) {
  let message

  if (path) return get(err, path)

  if (err.message === 'Internal Server Error!') {
    if (err?.err?.raw?.message) {
      // stripe error
      message = err.err.raw.message
    } else {
      message = err.data?.message || err.err?.message || err.err
    }
  } else message = err.message || err

  return typeof message === 'string' ? message : 'Error!'
}

function* updateAsyncActionStatusSaga(action: AnyAction) {
  analytics(action)

  if (action.type.endsWith('/request')) {
    yield put(
      UpdateAsyncActionStatus({ [originalType(action.type)]: 'request' })
    )
  } else if (action.type.endsWith('/success')) {
    yield put(
      UpdateAsyncActionStatus({ [originalType(action.type)]: 'success' })
    )
  } else if (action.type.endsWith('/failure')) {
    const { err, showAlert, errMsgPath } = action.payload

    if (err) {
      if (stage === 'PRODUCTION' ? showAlert === true : showAlert !== false) {
        notification.show({
          type: err.level || 'error',
          title: err.title || 'Error...',
          delay: err.delay,
          autoClose: err.autoClose,
          message: errorMessage(err, errMsgPath)
        } as NotificationShowOption)
      }

      if (stage === 'PRODUCTION' && showAlert !== false) {
        logEvent('exception', {
          description: errorMessage(err, errMsgPath),
          fatal: false,
          stack: JSON.stringify(err.stack),
          type:
            err.message === 'Internal Server Error!' ? 'server_error' : 'error',
          action: originalType(action.type)
        })
      }
    }
    yield put(
      UpdateAsyncActionStatus({
        [originalType(action.type)]: 'failure',
        err
      })
    )
  }
}

export default function* globalSaga() {
  yield takeEvery('*', updateAsyncActionStatusSaga)
}
