import { createAction } from '@reduxjs/toolkit'
import type { AuthCredential } from 'firebase/auth'

export const UpdateStates = createAction<Partial<TopHap.UserState>>('user/update_states')
export const SignIn = createAction<{
  email: string
  password: string
}>('user/sign_in')
export const SignInWithGoogle = createAction<{ token: string }>('user/sign_in_with_google')
export const SignInWithFacebook = createAction<{ token: string }>('user/sign_in_with_facebook')
export const SignInWithCustomToken = createAction<{ token: string }>('user/sign_in_with_custom_token')
export const SignUp = createAction<TopHap.Service.SignUpRequest>('user/sign_up')
export const ConfirmUser = createAction<TopHap.Service.ConfirmUserRequest>('user/confirm_user')
export const ResendConfirm = createAction<Omit<TopHap.Service.ResendConfirmRequest, 'returnUrl'>>('user/resend_confirm')
export const SignOut = createAction('user/sign_out')
export const UpdateProfile = createAction<
Partial<TopHap.UserInfo> & { credential?: AuthCredential }
>('user/update_profile')
export const ForgotPassword = createAction<{ email: string }>('user/forgot_password')
export const ResetPassword = createAction<TopHap.Service.ResetPasswordRequest>('user/reset_password')
export const UpdatePassword = createAction<{
  oldPassword: string
  newPassword: string
}>('user/update_password')
export const GetGeoInfo = createAction('user/get_geo_info')
export const SendFeedback = createAction<TopHap.Service.SendFeedbackRequest>('user/send_feedback')
export const SendResume = createAction<TopHap.Service.SendResumeRequest>('user/send_resume')
export const RequestInfo = createAction<TopHap.Service.RequestInfoRequest>('user/request_info')
export const AddRecentSearch = createAction<TopHap.Place>('user/add_recent_search')
export const ClearRecentSearches = createAction('user/clear_recent_searches')
export const GetRecentSearches = createAction('user/get_recent_searches')
export const AddRecentView = createAction<{ id: string }>('user/add_recent_view')
export const CreateCustomer = createAction<Partial<TopHap.Customer> & { partner?: TopHap.Partner }>('user/create_customer')
export const UpdateCustomer = createAction<Partial<TopHap.Customer>>('user/update_customer')
export const GetCustomer = createAction('user/get_customer')
export const DeleteSource = createAction<{ source: string }>('user/delete_source')
export const CreateSubscription = createAction<{
  planId: string
  promotion?: string
}>('user/create_subscription')
export const UpdateSubscription = createAction<{
  planId?: string
  promotion?: string
}>('user/update_subscription')
export const DeleteSubscription = createAction('user/delete_subscription')
export const GetInvoices = createAction<{
  after?: string
  limit?: number
}>('user/get_invoices')
export const GetUpcomingInvoice = createAction('user/get_upcoming_invoice')
export const AddPromotion = createAction<{ code: string }>('user/add_promotion')
export const CloseAccount = createAction('user/close_account')
export const RouteChanged = createAction<string>('user/route_changed')
export const ShareLink = createAction<TopHap.Service.ShareLinkRequest>('user/share_link')
export const GetShareLink = createAction<TopHap.Service.GetShareLinkRequest>('user/get_share_link')
export const InviteGuest = createAction<TopHap.Service.InviteGuestRequest>('user/invite_guest')
export const AcceptInvitation = createAction<TopHap.Service.AcceptInvitationRequest>('user/accept_invitation')
export const RemoveInvitation = createAction<TopHap.Invitation>('user/remove_invitation')
export const GetUserStats = createAction('user/get_user_stats')
