import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      light: '#20cb4c',
      main: '#6651f5',
      dark: '#6651f5',
      contrastText: '#ffffff'
    }
  },
  typography: {
    fontFamily: [
      'Inter',
      'sans-serif',
      'Roboto',
      'Arial',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
})

export default theme
