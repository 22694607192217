export const ACADEMY_PAGE = '/academy'
export const BILLING_PAGE = '/settings/account-and-plan'
export const COMPARE_PAGE = '/compare/[[...params]]'
export const CMA_PAGE = '/cma/[[...params]]'
export const WFG_PAGE = '/wfg'
export const FAQ_PAGE = '/faq'
export const GUESTS_PAGE = '/settings/guests'
export const MAP_PAGE = '/map/[[...params]]'
export const PDF_FULL_PROPERTY_PAGE = '/pdf/full_property/[id]'
export const PDF_PROPERTY_PAGE = '/pdf/property/[id]'
export const PDF_CMA_REPORT = '/pdf/cma/[id]'
export const PDF_ZMA_PAGE = '/pdf/zma/[id]'
export const PDF_PAGES = '/pdf'
export const PRICING_PAGE = '/pricing'
export const PROFILE_PAGE = '/settings/profile'
export const PROPERTY_DETAIL_PAGE = '/homes/details/[address]/[id]'
export const APP_DETAIL_PAGE = '/app/details/[address]/[id]'
export const SITEMAP_PAGE = '/sitemap/[...params]'
export const WEBINARS_PAGE = '/webinars'
export const SSO_PAGE = '/sso'
export const WORKSPACE_PAGES = '/workspaces'
export const FAVORITES_PAGE = '/workspaces/favorites'
export const APP_FAVORITES_PAGE = '/app/workspaces/favorites'
export const LISTS_PAGE = '/workspaces/lists'
export const WHATS_NEW_PAGE = '/whats-new'
export const ZMA_PAGE = '/zma'
