import SvgHexLogo from '#/assets/images/logos/logo-hexa.svg'
import SvgLogo from '#/assets/images/logos/tophap-logo.svg'
import cn from '#/utils/classnames'
import styles from './styles.module.scss'

type Props = {
  mode?: 'hexa' | 'full'
  agent?: TopHap.UserInfo
}

const Logo: React.FC<Props> = ({ mode = 'full', agent }) => {
  function renderContent() {
    if (agent) {
      return (
        <img
          alt='TopHap'
          className={cn(styles['th-agent-logo'], { [styles['th-hexa']]: mode === 'hexa' })}
          src={agent.portal?.logoUrl || '/logo-hexa.svg'}
        />
      )
    } else if (mode === 'hexa') {
      return <SvgHexLogo />
    } else {
      return <SvgLogo className='text-white' />
    }
  }

  return (
    <div className={styles['th-logo']}>
      {renderContent()}
    </div>
  )
}

export default Logo
