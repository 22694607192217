import { connect } from 'react-redux'
import IntercomProvider from './IntercomProvider'

const mapStateToProps = (state: TopHap.StoreState) => ({
  user: state.user.info,
  paymentInfo: state.user.paymentInfo,
  permissions: state.user.permissions,
  stats: state.user.stats,
  isBot: state.global.isBot
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(IntercomProvider)
