import { useRouter } from 'next/router'
import React, { useContext } from 'react'
import { ReactReduxContext } from 'react-redux'
import SvgArrow from '#/assets/images/icons/angle-left.svg'
import SvgHelp from '#/assets/images/icons/help.svg'
import SvgLogo from '#/assets/images/logos/tophap-logo.svg'
import Avatar from '#/components/Avatar'
import Button from '#/components/Button'
import AccountMenu from '#/components/Header/Menu/AccountMenu'
import HelpMenu from '#/components/Header/Menu/HelpMenu'
import Link from '#/components/Link'
import { MAP_PAGE } from '#/consts/url'
import { logEvent } from '#/services/analytics'
import { UpdateSider, UpdateStates } from '#/store/actions/ui'
import cn from '#/utils/classnames'
import styles from './styles.module.css'

interface AppHeaderProps {
  isSideMenuExpanded: boolean
  user: TopHap.UserState['info']
  updateUI: typeof UpdateStates
  updateSider: typeof UpdateSider
}

export default function AppHeader({
  isSideMenuExpanded,
  user,
  updateUI,
  updateSider
}: AppHeaderProps) {
  const context = useContext(ReactReduxContext)
  const router = useRouter()

  function toggleSideMenu() {
    updateUI({ isSideMenuExpanded: !isSideMenuExpanded })

    logEvent('select_content', {
      content_type: 'app_header',
      item_id: 'side_menu_button',
      value: !isSideMenuExpanded
    })
  }

  function onBack() {
    const store: TopHap.StoreState = context.store.getState()
    const isMapPage = router.pathname === MAP_PAGE
    if (isMapPage && store.ui.sider.visible) {
      if (
        store.ui.siderMode === 'list' &&
        store.ui.sider.properties.mode === 'Detail'
      ) {
        updateSider({ field: 'properties', payload: { mode: 'List' }, update: true })
      } else {
        updateSider({ field: 'visible', payload: false })
      }
    } else {
      router.back()
    }

    logEvent('select_content', {
      content_type: 'app_header',
      item_id: 'back_button'
    })
  }

  return (
    <header className={cn(styles['th-app-header'], 'fixed inset-x-0 top-0 flex items-center text-white th-app-header bg-header')}>
      <Button className='h-full w-10' onClick={onBack}>
        <SvgArrow />
      </Button>

      <div
        className={cn('th-menu-button', 'hamburger', 'hamburger--spin')}
        onClick={toggleSideMenu}
      >
        <span className='hamburger-box'>
          <span className='hamburger-inner' />
        </span>
      </div>

      <Link href='/'>
        <SvgLogo
          className='ml-5 text-white'
          style={{ width: 130, height: 60 }}
        />
      </Link>

      <div className='ml-auto mr-4 flex space-x-4'>
        {user.isAnonymous === false ? (
          <AccountMenu
            classes={{
              content: 'top-full right-0 mt-1',
              trigger: 'rounded-full m-0 p-0'
            }}
            eventCategory='app_header'
            title={(
              <Avatar
                className='h-10 w-10'
                name={user.displayName}
                src={user.photoUrl}
              />
            )}
          />
        ) : null}

        <HelpMenu
          classes={{
            content: 'top-full right-0 mt-1',
            trigger: 'rounded-full m-0 p-0 bg-primary h-10 w-10 font-medium text-lg text-white grid place-items-center'
          }}
          eventCategory='app_header'
          title={<SvgHelp />}
        />
      </div>
    </header>
  )
}
