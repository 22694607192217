import { CircularProgress } from '@mui/material'
import React, { forwardRef } from 'react'
import TouchRipple from '#/components/TouchRipple'
import cn from '#/utils/classnames'

export interface ButtonProps {
  className?: string
  children: React.ReactNode
  hitSlop?: boolean
  loading?: boolean
  href?: string
  target?: string
  [extra: string]: unknown
}

export default forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, hitSlop, loading, disabled, ...props }, ref) => (
    <button
      ref={ref}
      className={cn(
        'cursor-pointer relative flex items-center justify-center overflow-hidden',
        { 'overflow-visible': hitSlop },
        'th-button',
        className
      )}
      disabled={(disabled || loading) as boolean}
      type='button'
      {...props}
    >
      {hitSlop && <div className='absolute -inset-3' />}
      {loading ? <CircularProgress className='mr-2.5 text-white' color='inherit' size={18} /> : null}
      {children}
      <TouchRipple className='absolute inset-0' />
    </button>
  )
)
