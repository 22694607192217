import qs, { ParsedUrlQuery } from 'querystring'
import get from 'lodash-es/get'
import isEqual from 'lodash-es/isEqual'
import omit from 'lodash-es/omit'
import lz from 'lz-string'
import { NextRouter } from 'next/router'
import api from '#/configs/api'
// import { initialState as initProperties } from '#/store/reducers/properties'
import { initialState as initPreferences } from '#/store/reducers/preferences'
import { initialState as initUI } from '#/store/reducers/ui'
import { setIn } from './object'

export function state2Hash(store: TopHap.StoreState) {
  const { preferences, properties, ui } = store
  const { map } = preferences
  /**
   * m: map
   * - c: map.center
   * - z: map.zoom
   * - p: map.pitch
   * - b: map.bearing
   * - bo: map.bounds
   * mt: mapType
   * l: preferences.places
   * f: preferences.filter
   * fm: preferences.filterMode
   * s: preferences.sort
   * p: preferences.map.properties
   * d: preferences.map.descriptive
   * pf: preferences.map.profitOptions
   * ev: preferences.map.elevations
   * t: preferences.map.timeline
   * z: preferences.map.zones
   * ep: properties.elevations
   * sv: ui.sider.visible
   * sm: ui.siderMode
   * sp: ui.sider.properties
   * le: isLegendExpanded
   * st: additional style payload
   */
  const hash: any = {}

  // map viewport
  hash.m = {
    c: map.viewport.center,
    z: map.viewport.zoom,
    p: map.viewport.pitch,
    b: map.viewport.bearing,
    bo: map.viewport.bounds
  }

  // map type
  if (map.mapType !== initPreferences.map.mapType) {
    hash.mt = map.mapType
  }

  // places
  if (preferences.places.items.length) {
    hash.l = preferences.places.items.map(e => e.id)
  }

  // filter
  if (
    JSON.stringify(preferences.filter) !== JSON.stringify(initPreferences.filter)
  ) {
    hash.f = preferences.filter
  }

  // filterMode
  if (preferences.filterMode !== initPreferences.filterMode) {
    hash.fm = preferences.filterMode
  }

  // sort
  if (
    JSON.stringify(preferences.sort) !== JSON.stringify(initPreferences.sort)
  ) {
    hash.s = preferences.sort
  }

  // properties
  if (
    JSON.stringify(map.properties) !==
    JSON.stringify(initPreferences.map.properties)
  ) {
    hash.p = map.properties
  }

  // descriptive
  if (
    JSON.stringify(map.descriptive) !==
    JSON.stringify(initPreferences.map.descriptive)
  ) {
    hash.d = map.descriptive
  }

  // profitOptions, zones
  if (JSON.stringify(map.zones) !== JSON.stringify(initPreferences.map.zones)) {
    hash.z = map.zones
  }

  // elevations
  if (map.elevations) {
    hash.ev = map.elevations
  }

  // properties elevations
  if (properties.elevations.length) {
    hash.ep = properties.elevations.map(e => e.id)
  }

  // timeline
  if (map.timeline) {
    hash.t = map.timeline
  }

  // ui.sider.visible
  if (ui.sider.visible !== initUI.sider.visible) {
    hash.sv = ui.sider.visible
  }

  // ui.siderMode
  if (ui.siderMode !== initUI.siderMode) {
    hash.sm = ui.siderMode
  }

  // ui.sider.properties
  if (
    JSON.stringify(ui.sider.properties) !==
    JSON.stringify(initUI.sider.properties)
  ) {
    hash.sp = ui.sider.properties
  }

  // ui.isLegendExpanded
  if (ui.isLegendExpanded !== initUI.isLegendExpanded) {
    hash.le = ui.isLegendExpanded
  }

  return hash
}

export function state2HashString(store: TopHap.StoreState) {
  return lz.compressToEncodedURIComponent(JSON.stringify(state2Hash(store)))
}

export function state2MapUrl(store: TopHap.StoreState) {
  return hash2MapUrl(state2Hash(store))
}

export function hashString2Hash(hashString: string) {
  const decompressed = lz.decompressFromEncodedURIComponent(hashString)
  if (!decompressed) return undefined

  return JSON.parse(decompressed)
}

export function hash2State(hash: any, store: TopHap.StoreState) {
  const { preferences, ui } = store
  let newStore = store

  // map
  if (hash.m) {
    const viewport = preferences.map.viewport
    if (
      !isEqual(hash.m.c, viewport.center) ||
      hash.m.z !== viewport.zoom ||
      hash.m.p !== viewport.pitch ||
      hash.m.b !== viewport.bearing ||
      !isEqual(hash.m.bo, viewport.bounds)
    ) {
      let updates: Partial<TopHap.Viewport> = {
        pitch: hash.m.p,
        bearing: hash.m.b,
        center: hash.m.c,
        updatedBy: 'INIT'
      }
      if (hash.m.bo) {
        updates = {
          ...updates,
          bounds: hash.m.bo,
          updatedBy: 'USER'
        }
      } else {
        updates = {
          ...updates,
          zoom: hash.m.z
        }
      }
      newStore = setIn(newStore, 'preferences.map.viewport', updates, true)
    }
  }

  // map type
  const mapType = hash.mt || initPreferences.map.mapType
  if (mapType !== preferences.map.mapType) {
    newStore = setIn(newStore, 'preferences.map.mapType', mapType)
  }

  // places
  // hash.l

  // filter
  const filter = {
    ...initPreferences.filter,
    ...(hash.f || {})
  }
  if (JSON.stringify(filter) !== JSON.stringify(preferences.filter)) {
    newStore = setIn(newStore, 'preferences.filter', filter)
  }

  // filterMode
  const filterMode = hash.fm || initPreferences.filterMode
  if (filterMode !== preferences.filterMode) {
    newStore = setIn(newStore, 'preferences.filterMode', filterMode)
  }

  // sort
  const sort = hash.s || initPreferences.sort
  if (JSON.stringify(sort) !== JSON.stringify(preferences.sort)) {
    newStore = setIn(newStore, 'preferences.sort', sort)
  }

  // properties
  const properties = hash.p || initPreferences.map.properties
  if (
    JSON.stringify(properties) !== JSON.stringify(preferences.map.properties)
  ) {
    newStore = setIn(newStore, 'preferences.map.properties', properties)
  }

  // descriptive
  const descriptive = hash.d || initPreferences.map.descriptive
  if (
    JSON.stringify(descriptive) !== JSON.stringify(preferences.map.descriptive)
  ) {
    newStore = setIn(newStore, 'preferences.map.descriptive', descriptive)
  }

  // elevations
  if (hash.ev && !preferences.map.elevations) {
    newStore = setIn(newStore, 'preferences.map.elevations', true)
  }

  // properties elevations
  // if (hash.ep) {
  //   this.props.getElevations(hash.ep)
  // }

  // timeline
  if (hash.t && !preferences.map.timeline) {
    newStore = setIn(newStore, 'preferences.map.timeline', true)
  }

  // ui.sider.visible
  const isSiderVisible = hash.sv !== undefined ? hash.sv : initUI.sider.visible
  if (isSiderVisible !== ui.sider.visible) {
    newStore = setIn(newStore, 'ui.sider.visible', isSiderVisible)
  }

  // ui.siderMode
  const siderMode = hash.sm || initUI.siderMode
  if (siderMode !== ui.siderMode) {
    newStore = setIn(newStore, 'ui.siderMode', siderMode)
  }

  // ui.sider.properties
  const siderProperties = hash.sp || initUI.sider.properties
  if (JSON.stringify(siderProperties) !== JSON.stringify(ui.sider.properties)) {
    newStore = setIn(newStore, 'ui.sider.properties', siderProperties)
  }

  // ui.isLegendExpanded
  const isLegendExpanded =
    hash.le !== undefined ? hash.le : initUI.isLegendExpanded
  if (isLegendExpanded !== ui.isLegendExpanded) {
    newStore = setIn(newStore, 'ui.isLegendExpanded', isLegendExpanded)
  }

  return newStore
}

export function hash2HashString(hash: Record<string, unknown>) {
  return lz.compressToEncodedURIComponent(JSON.stringify(hash))
}

export function hash2MapUrl(hash: Record<string, unknown> | string) {
  if (typeof hash !== 'string') {
    hash = lz.compressToEncodedURIComponent(JSON.stringify(hash))
  }

  return `/map/${hash}`
}

export function state2CompareUrl(
  preferences: TopHap.CompareState['preferences']
) {
  const { type, metric, primary, dateOption, excludes, ids, filter } =
    preferences
  const idsString = ids.length ? ids.map(e => e).join(',') : '_'

  return `/compare/${idsString}/${lz.compressToEncodedURIComponent(
    JSON.stringify({
      type,
      metric,
      primary,
      dateOption,
      excludes,
      filter
    })
  )}`
}

export function state2CMAUrl(
  preferences: TopHap.CMAState['preferences']
) {
  const { data, excludes, subjectUpdates } =
    preferences

  return `/cma/${lz.compressToEncodedURIComponent(
    JSON.stringify({
      data,
      excludes,
      subjectUpdates
    })
  )}`
}

export function previewUrl(
  id: string,
  location: TopHap.Coordinate,
  width = 400,
  height = 300,
  zoom = 16,
  bo?: [TopHap.Coordinate, TopHap.Coordinate]
) {
  const hash = {
    l: [id],
    m: {
      c: location,
      z: zoom,
      p: 0,
      b: 0,
      bo
    },
    p: {
      ...initPreferences.map.properties,
      enabled: true
    }
  }

  return (
    `${api.previewBaseUrl}/map/${width}/${height}/` +
    lz.compressToEncodedURIComponent(JSON.stringify(hash))
  )
}

export function urlWithQuery(
  router: NextRouter,
  query: ParsedUrlQuery,
  keepOld = false,
  removeAuth = false
) {
  const components = router.pathname.split('/')
  let q = keepOld ?
    {
      ...omit(
        router.query,
        components
          .map(e => get(e.match(/(\[)+(\.)*([^\]]+)(\])+/), '3') as unknown as string)
          .filter(e => e)
      ),
      ...query
    } :
    query
  if (removeAuth) {
    q = omit(q, ['auth', 'onAuthClose'])
  }
  let queryString = qs.encode(q)
  if (queryString) queryString = '?' + queryString

  const paths = router.asPath.split('#')[0].split('?')
  return { href: paths[0] + queryString }
}

export function authUrl(router: NextRouter, auth: string, keepOld = true) {
  return {
    ...urlWithQuery(router, { auth }, keepOld),
    rel: 'nofollow'
  }
}
