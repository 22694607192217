import { connect } from 'react-redux'
import { UpdateStates } from '#/store/actions/ui'
import Authentication from './Authentication'

const mapStateToProps = (state: TopHap.StoreState) =>
  ({ authenticated: state.user.info.isAnonymous === false })

const mapDispatchToProps = {
  showCTAPopup: (visible: boolean) =>
    UpdateStates({ ctaPopup: { visible } })
}

export default connect(mapStateToProps, mapDispatchToProps)(Authentication)
