import { createReducer } from '@reduxjs/toolkit'
import isbot from 'isbot'
import detectMobile from 'ismobilejs'
import { parse } from 'next-useragent'
import { UpdateStates, UpdateAsyncActionStatus } from '#/store/actions/global'

export const initialState: TopHap.GlobalState = {
  isMobile: {
    any: false,
    phone: false,
    tablet: false
  },
  isApple: false,
  status: {}
}

export function getInitState(ua: string): TopHap.GlobalState {
  const isMobile = detectMobile(ua)
  const parsedUserAgent = parse(ua)
  const isApple = parsedUserAgent.isMac || parsedUserAgent.isIos
  return {
    ...initialState,
    isBot: isbot(ua),
    isMobile: {
      any: isMobile.any,
      phone: isMobile.phone,
      tablet: isMobile.tablet
    },
    isApple
  }
}

export const updateStates = (
  state: TopHap.GlobalState,
  action: ReturnType<typeof UpdateStates>
) => ({
  ...state,
  ...action.payload
})

const updateAsyncActionStatus =
  (state: TopHap.GlobalState, action: ReturnType<typeof UpdateAsyncActionStatus>) => {
    state.status = {
      ...state.status,
      ...action.payload
    }
  }

export default createReducer(initialState, builder => {
  builder
    .addCase(UpdateAsyncActionStatus, updateAsyncActionStatus)
    .addCase(UpdateStates, updateStates)
})
