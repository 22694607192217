import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Modal from '#/components/Modal'
import { urlWithQuery } from '#/utils/url'
import styles from './styles.module.scss'

const SignIn = dynamic(() => import('./SignIn'))
const SignUp = dynamic(() => import('./SignUp'))
const ForgotPassword = dynamic(() => import('./ForgotPassword'))
const Confirm = dynamic(() => import('./Confirm'))
const SignUpSuccess = dynamic(() => import('./SignUpSuccess'))
const AcceptInvitation = dynamic(() => import('./AcceptInvitation'))

interface AuthenticationProps {
  authenticated: boolean
  showCTAPopup: (visible: boolean) => void
}

export default function Authentication({
  authenticated,
  showCTAPopup
}: AuthenticationProps) {
  const router = useRouter()

  const type = router.query.auth
  if (!type) return null

  function onClose(keepOldQuery = false) {
    // CONSIDER: remove when it is unnecessary completely
    if (router.query.onAuthClose === 'ctapopup') {
      showCTAPopup(true)
    }

    const url = urlWithQuery(router, {}, keepOldQuery, true)
    router.replace(url.href)
  }

  let _component = null
  if (type === 'verifications') _component = <Confirm />
  else if (type === 'forgotpassword') _component = <ForgotPassword />
  else if (type === 'acceptinvite') _component = <AcceptInvitation />
  else if (authenticated) {
    if (type === 'signupsuccess') _component = <SignUpSuccess />
    else if (type === 'signup' && router.query.invitedby) _component = <AcceptInvitation />
  } else if (type === 'signin') _component = <SignIn />
  else if (type === 'signup') _component = <SignUp />

  if (!_component) {
    if (type) onClose(true)
    return null
  }

  return (
    <Modal
      className={styles['th-authentication-modal']}
      disableBackdropClick={type !== 'verifications'}
      visible={_component != null}
      onClose={() => onClose(false)}
    >
      <div className={styles['th-authentication']}>{_component}</div>
    </Modal>
  )
}
