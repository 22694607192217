import { createReducer } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import * as Actions from '#/store/actions/properties'
import { request, success } from '#/utils/action'
import mergeArray from '#/utils/merge_array'
import { setIn } from '#/utils/object'

export const initialState: TopHap.PropertiesState = {
  map: { items: [] },
  list: {
    total: 0,
    items: [],
    cursor: undefined
  },
  elevations: [],
  analytics: {
    min: 0,
    max: 0
  },
  zones: { items: [] },
  boundary: [],
  neighborhood: {
    age: [],
    bathrooms: [],
    bedrooms: [],
    living_area: [],
    lot_size_acres: []
  },
  neighborhoodDom: {
    items: [],
    dom: {
      from: 'now-1y/d',
      interval: ''
    }
  },
  mls: {},
  hovered: undefined
}

export const commonSuccess = (
  state: TopHap.PropertiesState,
  action: AnyAction
) => ({
  ...state,
  ...action.payload
})

export const setState = (
  state: TopHap.PropertiesState,
  action: ReturnType<typeof Actions.SetState>
) => {
  const { option, value, update } = action.payload
  return setIn(state, option, value, update)
}

export const resetProperties = (state: TopHap.PropertiesState) => {
  state.list = initialState.list
}

export const getPropertiesRequest = (
  state: TopHap.PropertiesState,
  action: AnyAction
) => {
  if (action.payload.reset) {
    state.list = initialState.list
  }
}

export const getPropertiesSuccess = (
  state: TopHap.PropertiesState,
  action: AnyAction
) => {
  state.list = {
    items: action.payload.isNew ?
      [...action.payload.items] :
      [...state.list.items, ...action.payload.items],
    cursor: action.payload.cursor,
    total: action.payload.total
  }
}

export const resetElevations = (state: TopHap.PropertiesState) => {
  state.elevations = []
}

export const getElevationsSuccess = (
  state: TopHap.PropertiesState,
  action: AnyAction
) => {
  if (action.payload) {
    state.elevations = [...state.elevations, action.payload]
  } else {
    state.elevations = state.elevations.filter(e => e.id !== action.payload.id)
  }
}

export const toggleElevationSuccess = (
  state: TopHap.PropertiesState,
  action: AnyAction
) => {
  if (action.payload.item) {
    state.elevations = [...state.elevations, action.payload.item]
  } else {
    state.elevations = state.elevations.filter(e => e.id !== action.payload.id)
  }
}

export const searchZonesSuccess = (
  state: TopHap.PropertiesState,
  action: AnyAction
) => {
  let newItems
  if (action.payload.isNew) {
    newItems = [...action.payload.items]
  } else {
    newItems = [...state.zones.items]
    mergeArray(newItems, action.payload.items, 'id')
  }

  if (action.payload.clearOld) {
    const bounds = action.payload.bounds
    newItems = newItems.filter(e => {
      const lat = e.location[1]
      const lng = e.location[0]

      if (
        lat - bounds[0][1] >= -0.2 &&
        lat - bounds[1][1] <= 0.2 &&
        lng - bounds[0][0] >= -0.2 &&
        lng - bounds[1][0] <= 0.2
      ) {
        return true
      }

      return false
    })
  }

  state.zones.items = newItems
}

export const getMlsInfoSuccess = (
  state: TopHap.PropertiesState,
  action: AnyAction
) => {
  state.mls[action.payload.mls] = action.payload.info
}

export default createReducer(initialState, {
  [Actions.SetState.type]: setState,
  [Actions.UpdateStates.type]: commonSuccess,
  [Actions.ResetProperties.type]: resetProperties,
  [request(Actions.GetProperties)]: getPropertiesRequest,
  [success(Actions.GetProperties)]: getPropertiesSuccess,
  [success(Actions.GetMapData)]: commonSuccess,
  [Actions.ResetElevations.type]: resetElevations,
  [success(Actions.GetElevations)]: commonSuccess,
  [success(Actions.ToggleElevation)]: toggleElevationSuccess,
  [success(Actions.GetBoundary)]: commonSuccess,
  [success(Actions.SearchZones)]: searchZonesSuccess,
  [success(Actions.GetNeighborhood)]: commonSuccess,
  [success(Actions.GetMlsInfo)]: getMlsInfoSuccess
})
