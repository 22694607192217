import { createAction } from '@reduxjs/toolkit'

export const UpdateSider = createAction<{
  field: keyof TopHap.UIState['sider'] | 'all'
  payload: any
  update?: boolean
}>('ui/update_sider')
export const UpdateStates = createAction<Partial<TopHap.UIState>>('ui/update_states')
export const UpdateViewport = createAction<TopHap.UIState['viewport']>('ui/update_viewport')
export const ShowFeedback = createAction<boolean | TopHap.UIState['feedback']>('ui/show_feedback')
