import dayjs from 'dayjs'
import {
  AuthCredential, createUserWithEmailAndPassword, EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider, linkWithCredential, reauthenticateWithCredential,
  signInWithCredential as fbSignInWithCredential,
  signInWithCustomToken as fbSignInWithCustomToken,
  signOut as fbSignOut,
  updateEmail,
  updatePassword as fbUpdatePassword,
  updateProfile as fbUpdateProfile,
  User
} from 'firebase/auth'
import {
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  query,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore'
import { host } from '#/configs'
import api from '#/configs/api'
import { partners } from '#/consts/partners'
import { firebaseApp, getAuth } from '#/utils/firebase'
import invokeApi from '#/utils/invokeApi'

export async function validateUsername(username?: string) {
  const db = getFirestore(firebaseApp)

  const docs = await getDocs(query(
    collectionGroup(db, 'public'),
    where('username', '==', username),
    limit(1)
  ))

  return docs.empty
}

export async function _generateUsername(base: string) {
  const normalized = base.replace(/\W/g, '')

  if (!normalized) return null

  const db = getFirestore(firebaseApp)
  const publicUsersRef = collectionGroup(db, 'public')
  let docs = await getDocs(query(
    publicUsersRef,
    where('username', '==', normalized),
    limit(1)
  ))

  if (docs.empty) {
    return normalized
  }

  let username
  do {
    username = `${normalized}${Math.floor(Math.random() * 3000)}`
    // eslint-disable-next-line no-await-in-loop
    docs = await getDocs(query(
      publicUsersRef,
      where('username', '==', username),
      limit(1)
    ))
  } while (!docs.empty)

  return username
}

async function _mergeUsers(user: User, providerId: string, metaData = {}) {
  const db = getFirestore(firebaseApp)
  const userRef = doc(collection(db, 'users'), user.uid)
  const userData = (await getDoc(userRef)).data()
  if (userData?.signUpAt) {
    return {
      isFirstSignIn: false,
      user: userData
    }
  } else {
    let updates = {}
    const providerData = user.providerData.find(e => e?.providerId === providerId)
    if (providerData) {
      const username = await _generateUsername((providerData.email || providerData.uid).split('@')[0])

      updates = {
        displayName: providerData.displayName,
        photoUrl: providerData.photoURL,
        email: providerData.email,
        emailVerified: user.emailVerified,
        phoneNumber: providerData.phoneNumber,
        username,
        isAnonymous: user.isAnonymous,
        ...metaData
      }

      updateDoc(userRef, updates)
    }

    return {
      isFirstSignIn: true,
      user: {
        ...userData,
        ...updates
      }
    }
  }
}

async function _mergeAnonymousIntoExistingUser(
  anonymousUser: any,
  credential: any
) {
  const db = getFirestore(firebaseApp)

  let anonymousUserRef
  if (anonymousUser) {
    anonymousUserRef = doc(collection(db, 'users'), anonymousUser.uid)
  }

  const auth = getAuth(firebaseApp)
  const realUser = (await fbSignInWithCredential(auth, credential))
    .user as any
  const realUserRef = doc(collection(db, 'users'), realUser.uid)

  // TODO: merge workspace

  if (anonymousUserRef) {
    // don't use await for better performance, it will delete db data too by cloud function.
    if (anonymousUser.uid !== realUser.uid) {
      deleteDoc(anonymousUserRef)
    }
  }

  const realUserDoc = await getDoc(realUserRef)
  return realUserDoc.data()
}

export async function signUp({
  name,
  email,
  job,
  jobDescription,
  invitedBy,
  referredFrom,
  referredFromDescription,
  password,
  subscription,
  partner
}: TopHap.Service.SignUpRequest) {
  const auth = getAuth(firebaseApp)
  let currentUser = auth.currentUser
  if (currentUser) {
    // if anonymous user exists, link it
    const credential = EmailAuthProvider.credential(email, password)
    await linkWithCredential(currentUser, credential)
  } else {
    // This should never happen since we already have the anonymous user registered
    currentUser = (await createUserWithEmailAndPassword(auth, email, password)).user
  }

  // while we trust only user in db, we don't need to update user profile.
  await fbUpdateProfile(currentUser, { displayName: name })

  const { user } = await _mergeUsers(
    currentUser,
    EmailAuthProvider.PROVIDER_ID,
    {
      job,
      unsubscribed: !subscription,
      jobDescription,
      referredFrom,
      referredFromDescription,
      portal: { company: partner && !invitedBy ? (partners[partner].company || null) : null },
      signUpAt: dayjs().unix()
    }
  )

  sendActionEmail({
    action: 'verifyEmail',
    email,
    displayName: name
  })

  return user as TopHap.UserInfo
}

export async function signInWithCredential(
  providerId: string,
  credential: any,
  metaData = {}
): Promise<TopHap.Service.SignInWithCredentialResponse> {
  const auth = getAuth(firebaseApp)
  let currentUser = auth.currentUser

  try {
    if (currentUser) {
      await linkWithCredential(currentUser, credential)
    } else {
      // This should never happen since we already have the anonymous user registered
      currentUser = (await fbSignInWithCredential(auth, credential)).user
    }
    const { user } = await _mergeUsers(currentUser, providerId, {
      ...metaData,
      signUpAt: dayjs().unix()
    })
    return {
      user,
      isFirstSignIn: true,
      providerId
    }
  } catch (e: any) {
    if (e.code === 'auth/credential-already-in-use') {
      const user = await _mergeAnonymousIntoExistingUser(
        currentUser,
        credential
      )
      return {
        user,
        isFirstSignIn: false,
        providerId
      }
    } else {
      throw e
    }
  }
}

export async function signInWithCustomToken(token: string) {
  const auth = getAuth(firebaseApp)
  const currentUser = (await fbSignInWithCustomToken(auth, token)).user
  if (!currentUser) throw 'No User'

  const providerId = currentUser.providerData[0] ? currentUser.providerData[0].providerId : ''
  const { isFirstSignIn, user } =
    await _mergeUsers(currentUser, providerId, { signUpAt: dayjs().unix() })
  return {
    user,
    isFirstSignIn,
    providerId
  }
}

export function signIn(email: string, password: string) {
  const auth = getAuth(firebaseApp)
  const currentUser = auth.currentUser
  const credential = EmailAuthProvider.credential(email, password)

  return _mergeAnonymousIntoExistingUser(currentUser, credential)
}

export function signInWithGoogle(token: string, metaData?: any) {
  const credential = GoogleAuthProvider.credential(token)
  return signInWithCredential(GoogleAuthProvider.PROVIDER_ID, credential, metaData)
}

export function signInWithFacebook(token: string, metaData?: any) {
  const credential = FacebookAuthProvider.credential(token)
  return signInWithCredential(FacebookAuthProvider.PROVIDER_ID, credential, metaData)
}

export async function sendActionEmail(payload: Omit<TopHap.Service.SendActionEmailRequest, 'returnUrl'>) {
  return invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.sendActionEmail,
    method: 'POST',
    body: {
      ...payload,
      returnUrl: `${host}/?auth=verifications&`
    }
  })
}

export async function resetPassword(payload: TopHap.Service.ResetPasswordRequest) {
  return invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.resetPassword,
    method: 'POST',
    body: payload
  })
}

export async function confirmUser(payload: TopHap.Service.ConfirmUserRequest) {
  return invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.confirmUser,
    method: 'POST',
    body: payload
  })
}

export async function updatePassword(oldPassword: string, newPassword: string) {
  const auth = getAuth(firebaseApp)
  const user = auth.currentUser
  if (!user) throw 'No User'

  const credential = EmailAuthProvider.credential(user.email as string, oldPassword)

  await reauthenticateWithCredential(user, credential)
  return fbUpdatePassword(user, newPassword)
}

type SignOutParams = {
  ssoRedirect?: boolean
}
export async function signOut(params: SignOutParams = { ssoRedirect: true }) {
  const auth = getAuth(firebaseApp)
  const provider = auth?.currentUser?.providerData?.[0]?.providerId
  await fbSignOut(auth)

  // Redirect to SSO management portal if necessary
  if (params.ssoRedirect && provider === 'oidc.remax') {
    window.location.assign('https://marketplace.remax.com')
  }
}

export async function uploadImage(image: any, key = 'profile_image'): Promise<string> {
  const auth = getAuth(firebaseApp)
  const currentUser = auth.currentUser
  if (!currentUser) return ''

  // import firebase/storage asynchronously
  const { getDownloadURL, getStorage, ref, uploadString } = await import('firebase/storage')
  const storage = getStorage(firebaseApp)
  const imageRef = ref(storage, `users/${currentUser.uid}/${key}`)
  const res = await uploadString(imageRef, image, 'data_url')
  return getDownloadURL(res.ref)
}

export async function updateProfile(
  updates: Partial<TopHap.UserInfo>,
  credential?: AuthCredential
) {
  const auth = getAuth(firebaseApp)
  const currentUser = auth.currentUser
  if (!currentUser) throw 'Unauthorized'

  if (credential && updates.email) {
    await reauthenticateWithCredential(currentUser, credential)
    await updateEmail(currentUser, updates.email)
  }

  const db = getFirestore(firebaseApp)
  const userRef = doc(collection(db, 'users'), currentUser.uid)
  return updateDoc(userRef, updates)
}

export async function createCustomer(payload: any) {
  return invokeApi<TopHap.Customer>({
    baseUrl: api.baseUrl,
    endpoint: api.users.createCustomer,
    method: 'POST',
    body: payload,
    auth: true
  })
}

export async function updateCustomer(payload: any) {
  return invokeApi<TopHap.Customer>({
    baseUrl: api.baseUrl,
    endpoint: api.users.updateCustomer,
    method: 'PUT',
    body: { updates: payload },
    auth: true
  })
}

export async function getCustomer() {
  return invokeApi<TopHap.Customer>({
    baseUrl: api.baseUrl,
    endpoint: api.users.getCustomer,
    method: 'GET',
    auth: true
  })
}

export async function deleteSource(source: any) {
  return invokeApi<TopHap.Customer>({
    baseUrl: api.baseUrl,
    endpoint: api.users.deleteSource,
    method: 'DELETE',
    body: { source },
    auth: true
  })
}

export async function createSubscription(
  plan: string,
  promotion?: string,
  fbp?: string,
  fbc?: string
) {
  return invokeApi<TopHap.Payment>({
    baseUrl: api.baseUrl,
    endpoint: api.users.createSubscription,
    method: 'POST',
    body: {
      plan,
      promotion,
      fbp,
      fbc
    },
    auth: true
  })
}

export async function updateSubscription(plan?: string, promotion?: string) {
  return invokeApi<TopHap.Payment>({
    baseUrl: api.baseUrl,
    endpoint: api.users.updateSubscription,
    method: 'PUT',
    body: { plan, promotion },
    auth: true
  })
}

export async function deleteSubscription() {
  return invokeApi<TopHap.Payment>({
    baseUrl: api.baseUrl,
    endpoint: api.users.deleteSubscription,
    method: 'DELETE',
    auth: true
  })
}

export async function typeformCancelFlow(
  formId: string,
  typeformResponseId: string
): Promise<TopHap.Service.TypeformFlowResponse> {
  return invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.typeformCancelFlow,
    method: 'POST',
    body: { formId, typeformResponseId },
    auth: true
  })
}

export async function getInvoices(
  starting_after?: string,
  limit?: number
): Promise<TopHap.Invoice> {
  return invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.getInvoices,
    method: 'POST',
    body: {
      starting_after,
      limit
    },
    auth: true
  })
}

export async function getUpcomingInvoice() {
  return invokeApi<TopHap.Invoice>({
    baseUrl: api.baseUrl,
    endpoint: api.users.getUpcomingInvoice,
    method: 'GET',
    auth: true
  })
}

export async function addPromotion(code: string) {
  return invokeApi<TopHap.Customer>({
    baseUrl: api.baseUrl,
    endpoint: api.users.addPromotion,
    method: 'POST',
    body: { code },
    auth: true
  })
}

export async function getPromotion(
  code?: string,
  isCoupon?: boolean
): Promise<TopHap.Promotion | null> {
  return invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.getPromotion,
    method: 'GET',
    path: { code },
    query: { isCoupon },
    auth: true
  })
}

export function getGeoInfo() {
  return invokeApi<TopHap.UserState['geoInfo']>({
    baseUrl: api.baseUrl,
    endpoint: api.users.geoInfo,
    method: 'GET'
  })
}

export function sendFeedback(payload: TopHap.Service.SendFeedbackRequest) {
  return invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.sendFeedback,
    method: 'POST',
    body: payload
    // auth: true
  })
}

export function sendResume(payload: TopHap.Service.SendResumeRequest) {
  return invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.sendResume,
    method: 'POST',
    body: payload
    // auth: true
  })
}

export function requestInfo(payload: TopHap.Service.RequestInfoRequest) {
  return invokeApi({
    // baseUrl: api.baseUrl,
    baseUrl: api.baseV5Url,
    endpoint: api.users.requestInfo,
    method: 'POST',
    body: payload
    // auth: true
  })
}

export async function getRecentSearches() {
  const auth = getAuth(firebaseApp)
  const currentUser = auth.currentUser
  if (!currentUser) {
    const err = { message: 'No User', code: 'auth/no-user' }
    throw err
  }

  const db = getFirestore(firebaseApp)

  const addressRef = doc(db, `users/${currentUser.uid}/search/addresses`)
  const addressDoc = await getDoc(addressRef)

  if (addressDoc.exists()) {
    return addressDoc.data()
  }

  return []
}

export async function updateRecentSearch(recent: any) {
  const auth = getAuth(firebaseApp)
  const currentUser = auth.currentUser
  if (!currentUser) {
    const err = { message: 'No User', code: 'auth/no-user' }
    throw err
  }

  const db = getFirestore(firebaseApp)
  const addressRef = doc(db, `users/${currentUser.uid}/search/addresses`)
  return setDoc(
    addressRef,
    { recent },
    { merge: true }
  )
}

export async function updateRecentView(views: any) {
  const auth = getAuth(firebaseApp)
  const currentUser = auth.currentUser
  if (!currentUser) {
    const err = { message: 'No User', code: 'auth/no-user' }
    throw err
  }

  const db = getFirestore(firebaseApp)
  const addressRef = doc(db, `users/${currentUser.uid}/search/addresses`)
  return setDoc(
    addressRef,
    { views },
    { merge: true }
  )
}

export async function clearRecentSearch() {
  const auth = getAuth(firebaseApp)
  const currentUser = auth.currentUser
  if (!currentUser) {
    const err = { message: 'No User', code: 'auth/no-user' }
    throw err
  }

  // import firebase/firebase asynchronously
  const db = getFirestore(firebaseApp)
  const addressRef = doc(db, `users/${currentUser.uid}/search/addresses`)
  return setDoc(addressRef, { recent: [], views: [] })
}

export async function closeAccount() {
  return invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.closeAccount,
    method: 'DELETE',
    auth: true
  })
}

export async function shareLink({
  type,
  entityId,
  url
}: TopHap.Service.ShareLinkRequest): Promise<TopHap.Service.ShareLinkResponse> {
  return invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.shareLink,
    method: 'POST',
    path: { type, entityId },
    body: { url },
    auth: true
  })
}

export async function getShareLink(
  params: TopHap.Service.GetShareLinkRequest
): Promise<TopHap.Service.GetShareLinkResponse> {
  return invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.getShareLink,
    method: 'GET',
    path: params
  })
}

export async function inviteGuest(
  params: TopHap.Service.InviteGuestRequest
): Promise<TopHap.Service.InviteGuestResponse> {
  return invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.inviteGuest,
    method: 'POST',
    body: params,
    auth: true
  })
}

export async function acceptInvitation(
  params: TopHap.Service.AcceptInvitationRequest
) {
  return invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.acceptInvitation,
    method: 'POST',
    body: params,
    auth: true
  })
}

export async function removeInvitation(item: TopHap.Invitation) {
  return invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.removeInvitation,
    method: 'DELETE',
    body: {
      status: item.status,
      email: item.email,
      guestId: item.uid
    },
    auth: true
  })
}

export async function getUserStats(
  ids: string[]
): Promise<TopHap.Service.GetUserStatsResponse> {
  return invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.getUserStats,
    method: 'GET',
    query: { ids },
    auth: true
  })
}

export async function syncRemaxPaymentInfo(): Promise<unknown> {
  return invokeApi({
    baseUrl: api.baseUrl,
    endpoint: api.users.syncRemaxPaymentInfo,
    method: 'GET',
    auth: true
  })
}

export async function getCustomToken(token: string) {
  return invokeApi<{ token: string }>({
    baseUrl: api.baseUrl,
    endpoint: api.users.getCustomToken,
    method: 'GET',
    auth: true,
    token
  })
}
