import { isEmpty } from 'lodash-es'
import { HYDRATE } from 'next-redux-wrapper'
import { AnyAction, combineReducers } from 'redux'
import { setIn } from '#/utils/object'
import { UpdateStore } from '../actions/store'
import cma from './cma'
import compare from './compare'
import global from './global'
import preferences from './preferences'
import properties from './properties'
import property from './property'
import ui from './ui'
import user from './user'
import workspace from './workspace'

const reducers = combineReducers({
  global,
  ui,
  user,
  preferences,
  properties,
  property,
  compare,
  workspace,
  cma
})

export default function reducer(
  state: TopHap.StoreState | undefined,
  action: AnyAction
) {
  if (action.type === HYDRATE) {
    if (typeof window === 'undefined') {
      return { ...state, ...action.payload }
    } else {
      let newState = setIn(state, 'user.initToken', action.payload.user.initToken)
      if (action.payload.property.property) {
        newState = setIn(newState, 'property', action.payload.property)
      }
      if (!isEmpty(action.payload.properties.mls)) {
        newState = setIn(newState, 'properties.mls', action.payload.properties.mls)
      }
      return newState
    }
  } else if (action.type === UpdateStore.type) {
    return {
      ...(state || {}),
      ...action.payload
    }
  } else {
    return reducers(state, action)
  }
}
