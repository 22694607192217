import { connect } from 'react-redux'
import { ShowFeedback } from '#/store/actions/ui'
import Header from './Header'

const mapStateToProps = (state: TopHap.StoreState) => ({
  isMobile: state.global.isMobile.any,
  user: state.user.info
})

const mapDispatchToProps = { showFeedback: ShowFeedback }

export default connect(mapStateToProps, mapDispatchToProps)(Header)
