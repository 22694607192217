import { createReducer } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import * as Actions from '#/store/actions/property'
import { success } from '#/utils/action'

export const initialState: TopHap.PropertyState = {}

export const setPropertyDetails = (
  state: TopHap.PropertyState,
  action: ReturnType<typeof Actions.SetPropertyDetails>
) => action.payload

export const commonSuccess = (
  state: TopHap.PropertyState,
  action: AnyAction
) => ({
  ...state,
  ...action.payload
})

export default createReducer(initialState, {
  [Actions.SetPropertyDetails.type]: setPropertyDetails,
  [Actions.SetInsightsData.type]: commonSuccess,
  [Actions.SetOwnerInfo.type]: commonSuccess,
  [Actions.SetHistory.type]: commonSuccess,
  [Actions.SetPublicRecord.type]: commonSuccess,
  [Actions.SetMarketChanges.type]: commonSuccess,
  [success(Actions.GetHistory)]: commonSuccess,
  [success(Actions.GetOwnerInfo)]: commonSuccess,
  [success(Actions.GetPublicRecord)]: commonSuccess,
  // [success(Actions.GetPrediction)]: commonSuccess,
  // [success(Actions.GetEstimateHistory)]: commonSuccess,
  // [success(Actions.GetRentEstimateHistory)]: commonSuccess,
  [success(Actions.GetMarketChanges)]: commonSuccess,
  // [success(Actions.GetMarketHistory)]: commonSuccess,
  [success(Actions.GetInsightsData)]: commonSuccess
  // [success(Actions.GetSchoolDetails)]: commonSuccess,
  // [success(Actions.GetComparables)]: commonSuccess,
  // [success(Actions.GetNearbyStatistics)]: commonSuccess
})
