import { createReducer } from '@reduxjs/toolkit'
import * as Actions from '#/store/actions/ui'
import { setIn } from '#/utils/object'

export const initialState: TopHap.UIState = {
  siderMode: 'list',
  sider: {
    visible: true,
    size: 'Normal',
    properties: { mode: 'List' }
  },
  isSideMenuExpanded: false,
  isLegendExpanded: true,
  viewport: {
    // iPhoneX resolution
    width: 375,
    height: 812
  },
  ctaPopup: { visible: false },
  feedback: { visible: false },
  userInput: false,
  share: false
}

export function getInitState(isMobile: TopHap.GlobalState['isMobile']) {
  let width,
    sider = initialState.sider
  if (isMobile.phone) {
    width = 375
    sider = setIn(sider, 'visible', false)
  } else if (isMobile.tablet) {
    width = 720
  } else {
    width = 960
  }

  return {
    ...initialState,
    sider,
    viewport: {
      ...initialState.viewport,
      width
    }
  }
}

function updateStates(state: TopHap.UIState, action: ReturnType<typeof Actions.UpdateStates>) {
  return {
    ...state,
    ...action.payload
  }
}

function updateSider(state: TopHap.UIState, action: ReturnType<typeof Actions.UpdateSider>) {
  const { field, update } = action.payload
  let payload = action.payload.payload
  if (field === 'properties') {
    if (payload.mode === 'Detail') {
      payload = setIn(
        payload,
        'fromList',
        state.sider.properties.mode === 'List' && state.sider.visible
      )
    }
  } else if (field === 'all' && payload.properties) {
    if (payload.properties.mode === 'Detail') {
      payload = setIn(
        payload,
        'properties.fromList',
        state.sider.properties.mode === 'List' && state.sider.visible
      )
    }
  }

  if (field === 'all') {
    state.sider = {
      ...state.sider,
      ...payload
    }
  } else {
    state.sider = setIn(state.sider, field, payload, update)
  }
}

function updateViewport(state: TopHap.UIState, action: ReturnType<typeof Actions.UpdateViewport>) {
  state.viewport = {
    ...state.viewport,
    ...action.payload
  }
}

function showFeedback(state: TopHap.UIState, action: ReturnType<typeof Actions.ShowFeedback>) {
  if (typeof action.payload === 'boolean') {
    state.feedback.visible = action.payload
  } else {
    state.feedback = action.payload
  }
}

export default createReducer(initialState, {
  [Actions.UpdateSider.type]: updateSider,
  [Actions.UpdateStates.type]: updateStates,
  [Actions.UpdateViewport.type]: updateViewport,
  [Actions.ShowFeedback.type]: showFeedback
})
