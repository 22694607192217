import { useRouter } from 'next/router'
import { useIntercom } from 'react-use-intercom'
import { mapTourId } from '#/configs'
import { MAP_PAGE } from '#/consts/url'
import { logEvent } from '#/services/analytics'
import { ShowFeedback } from '#/store/actions/ui'
import { DropMenu, DropMenuProps } from '../index'

interface HelpMenuProps {
  eventCategory: 'app_header' | 'header' | 'side_menu'
  isMobile: boolean
  title: React.ReactNode
  classes?: DropMenuProps['classes']
  onItem?: (e: React.MouseEvent) => void
  showFeedback: typeof ShowFeedback
}

export default function HelpMenu({
  classes,
  eventCategory,
  isMobile,
  title,
  onItem,
  showFeedback
}: HelpMenuProps) {
  const router = useRouter()
  const { show, startTour } = useIntercom()

  function onFeedback(ev: React.MouseEvent) {
    showFeedback(true)
    if (onItem) onItem(ev)

    logEvent('select_content', {
      content_type: eventCategory,
      item_id: 'help/feedback'
    })
  }

  function onChatWithUs(ev: React.MouseEvent) {
    show()
    if (onItem) onItem(ev)

    logEvent('select_content', {
      content_type: eventCategory,
      item_id: 'help/chat_with_us'
    })
  }

  function onVideos(ev: React.MouseEvent) {
    window.open('/academy', '_blank')
    if (onItem) onItem(ev)

    logEvent('select_content', {
      content_type: eventCategory,
      item_id: 'help/academy'
    })
  }

  function onWhatsNew(ev: React.MouseEvent) {
    window.open('/whats-new', '_blank')
    if (onItem) onItem(ev)

    logEvent('select_content', {
      content_type: eventCategory,
      item_id: 'help/whats-new'
    })
  }

  function onMapTour(ev: React.MouseEvent) {
    startTour(mapTourId)
    if (onItem) onItem(ev)

    logEvent('select_content', {
      content_type: eventCategory,
      item_id: 'help/map_tour'
    })
  }

  return (
    <DropMenu
      classes={classes}
      mode={isMobile ? 'click' : 'hover'}
      title={title}
    >
      <DropMenu.Item onClick={onChatWithUs}>Chat with Us</DropMenu.Item>
      <DropMenu.Item onClick={onFeedback}>Submit Feedback</DropMenu.Item>
      <DropMenu.Item onClick={onVideos}>TopHap Academy</DropMenu.Item>
      {router.pathname === MAP_PAGE && (
        <DropMenu.Item onClick={onMapTour}>Product Tour</DropMenu.Item>
      )}
      <DropMenu.Item onClick={onWhatsNew}>What&apos;s New?</DropMenu.Item>
    </DropMenu>
  )
}

HelpMenu.defaultProps = { title: '?' }
