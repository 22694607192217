import { logEvent } from '#/services/analytics'
import { DropMenu } from '../index'
import { AccountMenuProps } from './types'

const AccountMenu: React.FC<AccountMenuProps> = ({
  eventCategory,
  isAgent,
  isMobile,
  ownerId,
  title,
  classes,
  userProvider,
  showShare,
  signOut,
  allowedDirectGuests = false
}) => {
  function onAccount() {
    logEvent('select_content', {
      content_type: eventCategory,
      item_id: 'account/profile'
    })
  }

  function onBilling() {
    logEvent('select_content', {
      content_type: eventCategory,
      item_id: 'account/account-and-plan'
    })
  }

  function onChangePass() {
    logEvent('select_content', {
      content_type: eventCategory,
      item_id: 'account/password'
    })
  }

  function onSignOut() {
    logEvent('select_content', {
      content_type: eventCategory,
      item_id: 'account/sign_out'
    })

    signOut()
  }

  function onGuests() {
    logEvent('select_content', {
      content_type: eventCategory,
      item_id: 'account/guests'
    })
  }

  function onInviteFriend() {
    showShare()

    logEvent('select_content', {
      content_type: eventCategory,
      item_id: 'account/invite_friend'
    })
  }

  function onReports() {
    logEvent('select_content', {
      content_type: eventCategory,
      item_id: 'account/reports'
    })
  }

  return (
    <DropMenu
      classes={classes}
      mode={isMobile ? 'click' : 'hover'}
      title={title}
    >
      <DropMenu.LinkItem href='/settings/profile' onClick={onAccount}>
        Account
      </DropMenu.LinkItem>
      {userProvider === 'password' && (
        <DropMenu.LinkItem
          href='/settings/password'
          label='Change Password'
          onClick={onChangePass}
        >
          Change Password
        </DropMenu.LinkItem>
      )}
      <DropMenu.LinkItem href='/settings/account-and-plan' onClick={onBilling}>
        Billing
      </DropMenu.LinkItem>
      {isAgent && (
        <DropMenu.LinkItem href='/settings/guests' onClick={onGuests}>
          Guests
        </DropMenu.LinkItem>
      )}
      {ownerId && !allowedDirectGuests && (
        <DropMenu.Item label='Invite Friend' onClick={onInviteFriend}>
          Invite Friend
        </DropMenu.Item>
      )}
      <DropMenu.LinkItem href='/settings/reports' onClick={onReports}>
        My Reports
      </DropMenu.LinkItem>
      <hr />
      <DropMenu.Item label='Sign Out' onClick={onSignOut}>
        Sign Out
      </DropMenu.Item>
    </DropMenu>
  )
}

export default AccountMenu
