import { Badge } from '@mui/material'
import get from 'lodash-es/get'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'
import SvgSignUp from '#/assets/images/icons/arrow_downward.svg'
import SvgSignIn from '#/assets/images/icons/arrow_forward.svg'
// import SvgCompare from '#/assets/images/icons/compare.svg'
// import SvgCMA from '#/assets/images/icons/document_report.svg'
import SvgHelp from '#/assets/images/icons/help.svg'
import SvgProperty from '#/assets/images/icons/property.svg'
// import SvgTrends from '#/assets/images/icons/trends.svg'
import SvgLike from '#/assets/images/workspace/heart_outline.svg'
// import AuthLock from '#/components/AuthLock'
import Avatar from '#/components/Avatar'
import Button from '#/components/Button'
import AccountMenu from '#/components/Header/Menu/AccountMenu'
import HelpMenu from '#/components/Header/Menu/HelpMenu'
import Link, { NavLink } from '#/components/Link'
import Logo from '#/components/Logo'
import Overlay from '#/components/Overlay'
import { BREAK_SM } from '#/consts'
import { id2Plan } from '#/consts/plan'
// import { CMA_PAGE, FAVORITES_PAGE, COMPARE_PAGE, MAP_PAGE, PRICING_PAGE } from '#/consts/url'
import { FAVORITES_PAGE, MAP_PAGE, PRICING_PAGE } from '#/consts/url'
import { logEvent } from '#/services/analytics'
import { UpdateStates } from '#/store/actions/ui'
import cn from '#/utils/classnames'
import { authUrl } from '#/utils/url'
import styles from './styles.module.scss'

type ActionProps = {
  disableHideOnClick?: boolean
  icon: React.ReactNode
  selected?: boolean
  title?: React.ReactNode
  onClick?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

interface SideMenuProps {
  className?: string
  comparablesCount: number
  cmaCount: number
  expanded: boolean
  likesCount: number
  paymentInfo?: TopHap.Payment
  siderMode: TopHap.UIState['siderMode']
  sider: TopHap.UIState['sider']
  user: TopHap.UserState['info']
  viewportWidth: number
  updateUI: typeof UpdateStates
}

export default function SideMenu({
  className,
  // comparablesCount,
  // cmaCount,
  expanded,
  likesCount,
  paymentInfo,
  sider,
  siderMode,
  user,
  viewportWidth,
  updateUI
}: SideMenuProps) {
  const router = useRouter()
  const refContainer = React.useRef<HTMLElement>(null)
  const Action = useCallback(({
    disableHideOnClick,
    icon,
    selected,
    title,
    onClick
  }: ActionProps) => {
    function handleClick(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      if (!disableHideOnClick) hideMenu()
      if (onClick) onClick(ev)
    }

    return (
      <Button
        className={cn(
          styles['th-action'],
          { [styles['th-selected']]: selected },
          'overflow-visible'
        )}
        onClick={handleClick}
      >
        <span className={styles['th-icon-wrapper']}>{icon}</span>
        {title && <span className={styles['th-title-wrapper']}>{title}</span>}
      </Button>
    )
  }, [hideMenu])

  function hideMenu() {
    if (viewportWidth > BREAK_SM) return
    updateUI({ isSideMenuExpanded: false })
  }

  function onListings() {
    if (!isMapPage) {
      router.push('/map')
    }
    updateUI({ siderMode: 'list', sider: { ...sider, visible: true } })

    logEvent('select_item', {
      item_list_id: 'app_mode',
      item_list_name: 'App Mode',
      selection_detail: 'listings',
      items: [
        {
          item_id: 'listings',
          item_name: 'Listings'
        }
      ]
    })
  }

  // function onTrends() {
  //   // when sider becomes visible, properties.enabled becomes true from map subscribers
  //   if (!isMapPage) {
  //     router.push('/map')
  //   }
  //   updateUI({ siderMode: 'statistics', sider: { ...sider, visible: true } })

  //   logEvent('select_item', {
  //     item_list_id: 'app_mode',
  //     item_list_name: 'App Mode',
  //     selection_detail: 'insights',
  //     items: [
  //       {
  //         item_id: 'insights',
  //         item_name: 'Insights'
  //       }
  //     ]
  //   })
  // }

  // function onCompare() {
  //   logEvent('select_item', {
  //     item_list_id: 'app_mode',
  //     item_list_name: 'App Mode',
  //     selection_detail: 'compare',
  //     items: [
  //       {
  //         item_id: 'compare',
  //         item_name: 'Compare'
  //       }
  //     ]
  //   })
  // }

  // function onCMA() {
  //   logEvent('select_item', {
  //     item_list_id: 'app_mode',
  //     item_list_name: 'App Mode',
  //     selection_detail: 'cma',
  //     items: [
  //       {
  //         item_id: 'cma',
  //         item_name: 'cma'
  //       }
  //     ]
  //   })
  // }

  const isMapPage = router.pathname === MAP_PAGE
  const isFavoritesPage = router.pathname === FAVORITES_PAGE
  const planId = get(paymentInfo, 'plan')
  const plan = planId ? id2Plan(planId) : undefined

  return (
    <>
      <aside
        ref={refContainer}
        className={cn(styles['th-side-menu'], className, { [styles['th-expanded']]: expanded })}
      >
        <Link className={styles['th-logo-wrapper']} href='/'>
          <Logo mode='hexa' />
        </Link>

        <section className={styles['th-custom-actions']}>
          <Action
            icon={<SvgProperty />}
            selected={isMapPage && siderMode === 'list'}
            title='Properties'
            onClick={onListings}
          />
          {/* <div className='relative'>
            <AuthLock
              className={styles['th-auth-lock']}
              event='side_menu_insights'
              onlyIcon
              requireRole='pro'
              transparent
            />
            <Action
              icon={<SvgTrends />}
              selected={
                isMapPage && siderMode === 'statistics'
              }
              title='Insights'
              onClick={onTrends}
            />
          </div>
          <div className='relative'>
            <AuthLock
              className={styles['th-auth-lock']}
              event='side_menu_compare'
              onlyIcon
              requireRole='pro'
              transparent
            />
            <NavLink
              activeClassName={styles['th-active']}
              className={styles['th-link-action']}
              href='/compare'
              pathname={COMPARE_PAGE}
              rel='nofollow'
            >
              <Action
                icon={(
                  <Badge badgeContent={comparablesCount} color='primary'>
                    <SvgCompare />
                  </Badge>
                )}
                title='Compare'
                onClick={onCompare}
              />
            </NavLink>
          </div>
          <div className='relative'>
            <AuthLock
              className={styles['th-auth-lock']}
              event='side_menu_cma'
              onlyIcon
              requireRole='pro'
              transparent
            />
            <NavLink
              activeClassName={styles['th-active']}
              className={styles['th-link-action']}
              href='/cma'
              pathname={CMA_PAGE}
              rel='nofollow'
            >
              <Action
                icon={(
                  <Badge badgeContent={cmaCount} color='primary'>
                    <SvgCMA />
                  </Badge>
                )}
                title='CMA'
                onClick={onCMA}
              />
            </NavLink>
          </div> */}
        </section>

        <section className={styles['th-global-actions']}>
          <NavLink
            activeClassName={styles['th-active']}
            className={styles['th-link-action']}
            href={FAVORITES_PAGE}
            rel='nofollow'
          >
            <Action
              icon={(
                <Badge badgeContent={likesCount} color='primary'>
                  <SvgLike />
                </Badge>
              )}
              selected={isFavoritesPage}
              title='Favorites'
              onClick={() =>
                logEvent('select_content', {
                  content_type: 'side_menu',
                  item_id: 'favorites'
                })}
            />
          </NavLink>
          <HelpMenu
            classes={{
              root: styles['th-drop-menu'],
              content: styles['th-drop-menu-content']
            }}
            eventCategory='side_menu'
            title={
              <Action disableHideOnClick icon={<SvgHelp />} title='Help' />
            }
          />
          {user.isAnonymous === false ? (
            <AccountMenu
              classes={{
                root: styles['th-drop-menu'],
                content: styles['th-drop-menu-content']
              }}
              eventCategory='side_menu'
              title={(
                <Action
                  disableHideOnClick
                  icon={(
                    <Avatar
                      className={styles['th-avatar']}
                      name={user.displayName}
                      src={user.photoUrl}
                    />
                  )}
                  title={viewportWidth <= BREAK_SM ? 'Account' : ''}
                />
              )}
            />
          ) : (
            <div className={styles['th-auth-actions']}>
              <Link className={styles['th-link-action']} {...authUrl(router, 'signin')}>
                <Action
                  icon={<SvgSignIn />}
                  title='Sign In'
                  onClick={() =>
                    logEvent('select_content', {
                      content_type: 'side_menu',
                      item_id: 'login'
                    })}
                />
              </Link>
              <Link className={styles['th-link-action']} {...authUrl(router, 'signup')}>
                <Action
                  icon={<SvgSignUp />}
                  title='Sign Up'
                  onClick={() =>
                    logEvent('select_content', {
                      content_type: 'side_menu',
                      item_id: 'sign_up'
                    })}
                />
              </Link>
            </div>
          )}
          {(!plan || plan.role !== 'expert') && (
            <Link className={styles['th-link-action']} href={PRICING_PAGE}>
              <Button className={styles['th-upgrade-button']} onClick={hideMenu}>
                {!plan || plan.role === 'free' ? 'Pricing' : 'Upgrade'}
              </Button>
            </Link>
          )}
        </section>
      </aside>
      {expanded && viewportWidth <= BREAK_SM && (
        <Overlay
          containerProps={{
            style: { zIndex: 99 },
            onClick: hideMenu
          }}
        />
      )}
    </>
  )
}
